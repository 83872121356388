import { ProcessingMapper } from '../base/processing-mapper';
import { MediaMapper } from '../content/media';
import { SeoTextMapper } from '../seo/seo-text';
import { ColorMapper } from './color';
import type { Compilation } from './compilation';
import type { ApiCompilation } from './compilation-types';

export class CompilationMapper {
  public static mapMany(items: ApiCompilation[]): Compilation[] {
    return ProcessingMapper.process(items.map(CompilationMapper.map));
  }

  public static map({ id, title, seo_text, slug, background, posters, contents, colors }: ApiCompilation): Compilation {
    return ProcessingMapper.process({
      id,
      title,
      seoText: SeoTextMapper.map(seo_text || {}),
      slug,
      background,
      posters,
      contents: (contents || []).map((item) => MediaMapper.map(item)),
      colors: ColorMapper.map(colors || { main: '', light: '' }),
    });
  }
}
