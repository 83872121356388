import { ProcessingMapper } from '../base/processing-mapper';
import type { MainPage } from '../content/types/main-page';
import { MainPageBlockMapper } from './main-page-block';
import type { ApiMainPage } from './main-page-types';

export class MainPageMapper {
  static map(items: ApiMainPage): MainPage {
    return ProcessingMapper.process(items.map((i) => MainPageBlockMapper.map(i)));
  }
}
