import { ProcessingMapper } from '../base/processing-mapper';
import type { ApiGenre } from './content-types/genre';
import type { Genre } from './types/genre';

export class GenreMapper {
  static mapMany(genres: ApiGenre[]): Genre[] {
    return ProcessingMapper.process(genres.map(GenreMapper.map));
  }

  static map({ id, title, slug, translit, title_en }: ApiGenre): Genre {
    return ProcessingMapper.process({
      id,
      title,
      slug: slug.replaceAll('-', '_'),
      translit,
      filterType: 'genre',
      titleEn: title_en,
    });
  }
}
