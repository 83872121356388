import SmartTvConstantsInstance from '@package/constants/code/constants-config-smart-tv';
import { BaseEndpoint, EndpointQueryMap, ENDPOINTS } from '@package/sdk/src/api/endpoints';
import { ensureStartSlash, isString, replaceURLVariables, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { IndexedDBStorage } from '@package/sdk/src/core/database/indexed-db';
import { onUnexpectedError } from '@package/sdk/src/core/errors/on-unexpected-error';
import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';
import type axios from 'axios';
import {
  AxiosBasicCredentials,
  AxiosError,
  AxiosHeaders,
  AxiosProxyConfig,
  AxiosResponse,
  ParamsSerializerOptions,
  RawAxiosRequestHeaders,
} from 'axios';
import axiosRetry from 'axios-retry';
import * as qs from 'qs';

import { ApiError } from './api-error';
import type { LoginResponse } from './auth/auth-types';
import type { DeviceService } from './device/device-service';
import type { EnvironmentService } from './environment/environment-service';
import { globalSettings } from './global-settings';
import { AlertMessageTypes, AlertService } from './notifications/alert-service';
import type { IStorageService } from './storage/storage-service';
import { StorageKeys } from './storage/storage-types';

export enum HTTPRequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

interface RequestOptions<T, P, K extends keyof EndpointQueryMap> {
  proxy?: AxiosProxyConfig;
  baseURL?: string;
  url: BaseEndpoint;
  data?: T;
  method?: 'GET' | 'POST' | 'DELETE' | 'OPTIONS' | 'PUT' | 'PATCH' | 'HEAD';
  params?: P;
  query?: EndpointQueryMap[K];
  cache?: boolean;
  headers?: RawAxiosRequestHeaders | AxiosHeaders;
  paramsSerializer?: ParamsSerializerOptions;
  auth?: AxiosBasicCredentials;
}

interface RequestConfig {
  withToken?: boolean;
  withSignature?: boolean;
  canAbort?: boolean;
  skipTokenValidation?: boolean;
}

interface SignatureParams<T> {
  body: T;
  path: string;
  login?: string;
  method?: string;
  contentType?: string;
}

export class RequestService {
  private readonly errorInterceptors = new Set<(error: Error) => void>();
  // @ts-expect-error
  private axios: axios.AxiosInstance;

  private cache: IndexedDBStorage | undefined;

  constructor(
    private readonly deviceService: DeviceService,
    private readonly storageService: IStorageService,
    private readonly environmentService: EnvironmentService,
    private readonly alertService: AlertService,
  ) {
    this.registerListeners();
  }

  private abortControllers: AbortController[] = [];
  public refreshRequestPromise: Promise<AxiosResponse<LoginResponse>> | undefined;

  private get refreshToken() {
    return this.storageService.getItem<string>(StorageKeys.RefreshToken);
  }

  private get accessToken() {
    return this.storageService.getItem<string>(StorageKeys.AccessToken);
  }

  private getApiBaseURL(): string {
    const isRelease = this.environmentService.getVariable('isRelease');

    if (isRelease) {
      return this.environmentService.getVariable('apiBaseURL');
    }

    const isDebugProdApiEnabled = this.storageService.getItem(StorageKeys.isProdApiEnabledDebug, false);

    if (isDebugProdApiEnabled) {
      return this.environmentService.getVariable('debugApiBaseProdURL');
    }

    return this.environmentService.getVariable('apiBaseURL');
  }

  public abort(message = 'Cancelled by user') {
    this.abortControllers.forEach((controller) => controller.abort(message));
    this.abortControllers = [];
  }

  public async request<R, K extends keyof EndpointQueryMap = any>(
    options: RequestOptions<object, Record<string, string>, K>,
    config: RequestConfig = {},
  ): Promise<AxiosResponse<R>> {
    const { withSignature = false, withToken = false, canAbort = true } = config;
    const {
      params,
      url: endpoint,
      query,
      baseURL,
      method,
      data,
      auth,
      cache,
      proxy,
      headers: requestHeaders,
    } = options;

    const { path, cacheStrategy, cacheTimeMilliseconds } = endpoint;
    const controller = new AbortController();

    if (!method) {
      throw new UnexpectedComponentStateError('method');
    }

    const url = ensureStartSlash(
      (() => {
        let _path = path;

        if (query) {
          const queryStr = decodeURIComponent(qs.stringify(query, { arrayFormat: 'brackets' }));

          _path = _path + '?' + queryStr;
        }

        if (params) {
          return replaceURLVariables(_path, params);
        }

        return _path;
      })(),
    );

    const isCacheableRequest =
      (cacheStrategy === 'default' || cacheStrategy === 'max-age') && method === HTTPRequestMethod.Get;

    const normalizedCacheTimeMilliseconds = (cacheStrategy === 'default' ? 86400000 * 7 : cacheTimeMilliseconds) || 0;

    if (canAbort) {
      this.abortControllers.push(controller);
    }

    const Authorization = `Bearer ${this.accessToken}`;

    if (this.cache && isCacheableRequest) {
      const cached = await this.cache.read<{
        data: R;
        headers: Record<string, string>;
      }>(url);

      if (cached) {
        const isCacheOutdated = Date.now() >= cached.expires;

        if (!isCacheOutdated) {
          const { data, headers } = cached.value;

          return {
            data,
            headers,
          } as AxiosResponse<R>;
        }

        this.cache.delete(url);
      }
    }

    const headers = {
      VisitorId: this.deviceService.getVisitorId(),
      'Accept-Language': 'ru-RU',
      ...requestHeaders,
      ...(withToken && { Authorization }),
      ...(withSignature && this.getSignature({ body: data, path: url })),
    };

    const normalizedOptions = {
      baseURL: isString(baseURL) ? baseURL : this.getApiBaseURL(),
      url,
      headers,
      signal: controller.signal,
      method,
      data,
      auth,
      cache,
      proxy,
    };

    const doRequest = async () => {
      try {
        this.refreshRequestPromise = undefined;
        return await this.axios.request({ ...normalizedOptions });
      } catch (error) {
        if (error instanceof Error) {
          const axiosError = error as AxiosError;

          if (globalSettings.axios.isCancel(error)) {
            return Promise.reject(error);
          }

          if (!withToken) {
            throw new ApiError(axiosError as AxiosError, url);
          }

          if (axiosError.response?.status === HTTPStatusCode.Unauthorized) {
            await this.updateTokens();

            const { headers } = normalizedOptions;

            return await this.axios.request({
              ...normalizedOptions,
              headers: {
                ...headers,
                ...(withToken && { Authorization: `Bearer ${this.accessToken}` }),
              } as RawAxiosRequestHeaders,
              signal: controller.signal,
            });
          }

          throw new ApiError(axiosError, url);
        }

        onUnexpectedError(error);

        return Promise.reject(error);
      }
    };

    try {
      const result = await doRequest();

      if (this.cache && isCacheableRequest) {
        this.cache.write(
          url,
          {
            data: result.data,
            headers: result.headers,
          },
          { expires: normalizedCacheTimeMilliseconds },
        );
      }

      return result;
    } catch (error) {
      this.errorInterceptors.forEach((callback) => callback.apply(this, [error as AxiosError]));

      throw error;
    }
  }

  public getSignature<T>({
    body,
    path,
    login = 'tv',
    method = 'POST',
    contentType = 'application/json',
  }: SignatureParams<T>) {
    const API_SECRET_KEY = this.environmentService.getVariable<string>('apiSecretKey');
    const contentMD5 = globalSettings.cryptoJs.enc.Base64.stringify(globalSettings.cryptoJs.MD5(JSON.stringify(body)));
    const date = new Date().toUTCString();

    const message = [method, contentType, contentMD5, path, date].join(',');
    const encrypted = globalSettings.cryptoJs
      .HmacSHA1(message, API_SECRET_KEY)
      .toString(globalSettings.cryptoJs.enc.Base64);
    const authorization = `APIAuth ${login}:${encrypted}`;

    return {
      authorization,
      'Http-Date': date,
      'Content-MD5': contentMD5,
      'Content-Type': contentType,
    };
  }

  public async updateTokens(token?: string): Promise<LoginResponse | undefined> {
    try {
      if (!(token ?? this.refreshToken)) {
        return undefined;
      }

      const body = {
        refresh_token: token ?? this.refreshToken,
      };

      if (this.refreshRequestPromise) {
        const { data } = await this.refreshRequestPromise;

        return data;
      }

      this.refreshRequestPromise = this.request<LoginResponse, 'SESSIONS_REFRESH'>(
        {
          method: HTTPRequestMethod.Post,
          url: ENDPOINTS.SESSIONS_REFRESH,
          data: body,
        },
        { withSignature: true },
      );

      const { data } = await this.refreshRequestPromise;

      this.storageService.setItem(StorageKeys.AccessToken, data.auth.token);
      this.storageService.setItem(StorageKeys.RefreshToken, data.auth.refresh_token);

      return data;
    } catch (error) {
      this.storageService.setItem(StorageKeys.User, '');
      this.storageService.setItem(StorageKeys.Profile, '');
      this.storageService.setItem(StorageKeys.AccessToken, '');
      this.storageService.setItem(StorageKeys.RefreshToken, '');
    }

    return undefined;
  }

  public init() {
    this.axios = globalSettings.axios.create({
      timeout: SmartTvConstantsInstance.getProperty('requestTimeoutMs'),
    });

    axiosRetry(this.axios, { retries: 3 });

    const isModern = this.deviceService.isModernBuild();

    if (isModern) {
      this.cache = new IndexedDBStorage({
        databaseName: 'smarttv-cache',
        timeoutForReading: 1500,
        tableName: 'http-resources',
      });
    }
  }

  public clearCache() {
    this.cache?.clear();
  }

  private registerListeners() {
    const onError = (error: Error) => {
      if (error instanceof ApiError) {
        return this.alertService.addAlert({
          type: AlertMessageTypes.Warning,
          message: error.devMessage,
          timeoutMs: 3000,
          hideIcon: false,
        });
      }

      onUnexpectedError(error);
    };

    const isDevMode = this.environmentService.getVariable<boolean>('isDev');

    if (isDevMode) {
      this.errorInterceptors.add(onError);
    }
  }
}
