import { isFunction, isUndefined } from '../std/types';
import { UnexpectedPropertyConditionError } from './../errors/unexpected-property-condition-error';

export function formatTextWithSlice(text?: string, maxLength = 15) {
  if (!text) {
    return '';
  }

  if (text.length >= maxLength) {
    return text.slice(0, maxLength) + '...';
  }

  return text;
}

export function parseRgbaStringToArray(rgbaString: string) {
  if (!rgbaString.startsWith('rgba(')) {
    throw new Error('rgba color should starts with rgba');
  }

  const firstRgbaDigitIndex = 5;
  const colorComponentsArray = rgbaString.slice(firstRgbaDigitIndex, -1).replaceAll(' ', '').split(',');

  if (colorComponentsArray.length !== 4) {
    throw new UnexpectedPropertyConditionError('length', colorComponentsArray.length, '4');
  }

  return colorComponentsArray.map(Number);
}

export function replaceURLVariables(url: string, params?: Record<string, string>): string {
  if (isUndefined(params)) {
    return url;
  }

  let normalizedURL = url;

  const entries = Object.entries(params);

  for (const [keyToReplace, value] of entries) {
    try {
      if (normalizedURL) {
        normalizedURL = normalizedURL.replaceAll(`{ ${keyToReplace} }`, value?.toString());
      }

      if (normalizedURL) {
        normalizedURL = normalizedURL.replaceAll(`{${keyToReplace}}`, value?.toString());
      }
    } catch (error) {
      return normalizedURL;
    }
  }

  return normalizedURL;
}

/**
 * Добавляет начальный '/', если его нет.
 *
 * @example ensureStartSlash('abc') -> '/abc'
 * @example ensureStartSlash('/abc') -> '/abc'
 * @param {string} path
 * @returns {string}
 */
export function ensureStartSlash(path: string) {
  if (path.startsWith('/')) {
    return path;
  }

  return `/${path}`;
}

export function ensureEndSlash(path: string) {
  if (path.endsWith('/')) {
    return path;
  }

  return `${path}/`;
}

/**
 * Добавляет начальный '/' и конечные '/', если его нет.
 *
 * @example ensureStartSlash('abc') -> '/abc'
 * @example ensureStartSlash('/abc') -> '/abc'
 * @param {string} path
 * @returns {string}
 */
export function ensureStartAndEndSlash(path: string) {
  if (path.startsWith('/') && path.endsWith('/')) {
    return path;
  }

  if (path.startsWith('/') && !path.endsWith('/')) {
    return `${path}/`;
  }

  if (!path.startsWith('/') && path.endsWith('/')) {
    return `/${path}`;
  }

  return `/${path}/`;
}

export function safeReplaceAll(str: string, substring: string | RegExp, newSubstring: string) {
  if (!isFunction(str.replaceAll)) {
    // If a regex pattern
    if (Object.prototype.toString.call(substring).toLowerCase() === '[object regexp]') {
      return str.replace(substring as RegExp, newSubstring);
    }

    // Если `searchValue` является строкой
    return str.replace(new RegExp(substring as string, 'g'), newSubstring);
  }

  return str.replaceAll(substring, newSubstring);
}
