import { ProcessingMapper } from '../base/processing-mapper';
import { GenreBeltItemMapper } from '../content/genre-belt-item';
import type { MainPageBlock } from '../content/types/main-page';
import { ContentMomentMapper } from '../moments/content-moment';
import type { ApiMainPageBlock } from './main-page-types';

export class MainPageBlockMapper {
  static map({
    id,
    title,
    position,
    display_type,
    offer_ids,
    belt_items,
    playlist_id,
    playlist_slug,
    texts,
    content_moments_list,
  }: ApiMainPageBlock): MainPageBlock {
    return ProcessingMapper.process({
      id,
      title,
      position,
      displayType: display_type,
      beltItems: belt_items ? GenreBeltItemMapper.mapMany(belt_items) : [],
      offerId: offer_ids?.[0] || '',
      playlistSlug: playlist_slug,
      playlistId: playlist_id,
      texts: {
        subtitle: texts?.kinom_playlist_subtitle,
      },
      contentMomentsList: content_moments_list ? ContentMomentMapper.mapMany(content_moments_list) : [],
    });
  }
}
