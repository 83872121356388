<template>
  <header :class="$style.header">
    <p v-if="restOfTime">
      {{
        $t('media.restOfTime', {
          remain: pluralize(restOfTime, 'pluralizedUnit.remain'),
          restOfTime,
          pluralizedText: pluralize(restOfTime, 'durationUnit.minute'),
        })
      }}
    </p>
    <p v-if="percent" :class="$style.approach">
      {{ $t('media.relevant', { percent }) }}
    </p>

    <p :class="$style.subtitle">
      {{ subtitle }}
      <span v-if="limit" :class="$style.limit">
        {{ limit }}
      </span>
    </p>

    <h1 :class="$style.title">{{ title }}</h1>
  </header>
</template>

<script>
import { pluralize } from '@SMART/index';

export default {
  props: {
    disabled: { type: Boolean },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    limit: { type: String, default: '' },
    percent: { type: String, default: '' },
    restOfTime: { default: 0 },
  },
  setup(props, { emit }) {
    return { pluralize };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/colors';
@import '@/styles/layers';

.header {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-wrapper);
  min-height: adjustPx(135px);
}

.approach {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  color: var(--color-text-link);
  margin-right: adjustPx(16px);
  margin-bottom: adjustPx(8px);

  @include f-caption;
}

.title {
  line-height: adjustPx(67px);

  @include f-title-1;
}

.subtitle {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  color: var(--color-text-secondary);
  margin-bottom: adjustPx(8px);

  @include f-caption;
}

.limit {
  display: inline-flex;
  align-items: center;
  width: adjustPx(47px);
  height: adjustPx(26px);
  border-radius: adjustPx(8px);
  border: adjustPx(2px) solid var(--color-text-secondary);
  font-size: adjustPx(18px);
  justify-content: center;
  box-sizing: border-box;
  margin-left: adjustPx(12px);
}
</style>
