<template>
  <UIModal ref="el">
    <CatalogFilters
      v-if="showFiltersMenu"
      :parent-focus-key="focusKey"
      @hide="$emit('on-show-all-filters', false)"
      @country="setCountriesFilter"
      @period="setPeriodsFilter"
      @genre="setGenresFilter"
      @clear="clearFilters"
    />
    <CustomFilter
      v-if="showCountries"
      :selected-items="selectedCountries"
      id-key="code"
      :parent-focus-key="focusKey"
      :title="$t('pages.catalog.country')"
      :items="countries"
      @hide="$emit('on-show-all-filters', false)"
      @back="filtersMenuGoBack"
      @toggle="toggleCountry"
    />
    <CustomFilter
      v-if="showPeriods"
      :selected-items="selectedPeriods"
      id-key="id"
      :parent-focus-key="focusKey"
      :title="$t('pages.catalog.periods')"
      :items="periods"
      @hide="$emit('on-show-all-filters', false)"
      @back="filtersMenuGoBack"
      @toggle="togglePeriod"
    />
    <CustomFilter
      v-if="showGenres"
      :selected-items="selectedGenres"
      id-key="id"
      :parent-focus-key="focusKey"
      :title="$t('pages.catalog.genres')"
      :items="genres"
      @hide="$emit('on-show-all-filters', false)"
      @back="filtersMenuGoBack"
      @toggle="toggleGenre"
    />
  </UIModal>
</template>

<script>
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { customEventsService, storeToRefs, useContentStore } from '@SMART/index';
import { computed, defineComponent, onMounted, onUnmounted, provide, ref } from '@vue/composition-api';

import UIModal from '@/components/modal/UIModal.vue';
import CatalogFilters from '@/pages/catalog/components/CatalogFilters.vue';
import CustomFilter from '@/pages/catalog/components/CustomFilters.vue';

export default defineComponent({
  props: {
    filters: {
      type: Object,
      required: false,
    },
  },
  emits: ['on-show-all-filters', 'update:filters', 'close'],
  setup(props, { emit }) {
    const contentStore = useContentStore();
    const { genres, countries, periods } = storeToRefs(contentStore);
    const { el, focusKey, focusSelf } = useNavigatable({
      focusKey: 'CATALOG_FILTERS_MODAL',
      isFocusBoundary: true,
      focusBoundaryDirections: ['up', 'down'],
    });
    provide('parentFocusKey', focusKey.value);

    onMounted(() => {
      focusSelf();
      customEventsService.setOnPressBackCallback(() => {
        if (showFiltersMenu.value) {
          emit('on-show-all-filters', false);
        } else {
          filtersMenuGoBack();
        }
      });
    });

    onUnmounted(() => {
      customEventsService.setOnPressBackCallback();
    });

    /** Filters */
    const showFiltersMenu = ref(true);

    const filters = computed({
      get: () => props.filters,
      set: (value) => value && emit('update:filters', value),
    });
    /** Periods filter */

    const selectedCountries = computed(() => filters.value.countries || []);
    const selectedGenres = computed(() => filters.value.genres || []);
    const selectedPeriods = computed(() => filters.value.periods || []);

    const showPeriods = ref(false);

    const setPeriodsFilter = () => {
      showPeriods.value = true;
      showFiltersMenu.value = false;
    };

    const togglePeriod = async (period) => {
      let periods = [];

      if (selectedPeriods.value.find((c) => c.id === period.id)) {
        periods = selectedPeriods.value.filter((c) => c.id !== period.id);
      } else {
        periods = [...selectedPeriods.value, period];
      }

      filters.value = {
        ...filters.value,
        periods,
      };
    };

    /** Country Filter */
    const showCountries = ref(false);

    const setCountriesFilter = () => {
      showCountries.value = true;
      showFiltersMenu.value = false;
    };

    const toggleCountry = async (country) => {
      let countries = [];

      if (selectedCountries.value.find((c) => c.code === country.code)) {
        countries = selectedCountries.value.filter((c) => c.code !== country.code);
      } else {
        countries = [...selectedCountries.value, country];
      }

      filters.value = {
        ...filters.value,
        countries,
      };
    };

    /** Genre Filter */
    const showGenres = ref(false);
    const setGenresFilter = () => {
      showGenres.value = true;
      showFiltersMenu.value = false;
    };

    const clearFilters = async () => {
      filters.value = {};
    };

    const toggleGenre = async (genre) => {
      let genres = [];

      if (selectedGenres.value.find((c) => c.id === genre.id)) {
        genres = selectedGenres.value.filter((c) => c.id !== genre.id);
      } else {
        genres = [...selectedGenres.value, genre];
      }

      filters.value = {
        ...filters.value,
        genres,
      };
    };

    const filtersMenuGoBack = () => {
      showPeriods.value = false;
      showCountries.value = false;
      showGenres.value = false;
      showFiltersMenu.value = true;
    };

    return {
      genres,
      countries,
      periods,
      el,
      toggleGenre,
      clearFilters,
      setGenresFilter,
      showGenres,
      selectedGenres,
      focusKey,
      toggleCountry,
      setCountriesFilter,
      showCountries,
      selectedCountries,
      togglePeriod,
      setPeriodsFilter,
      showPeriods,
      selectedPeriods,
      filtersMenuGoBack,
      showFiltersMenu,
    };
  },
  components: { CustomFilter, UIModal, CatalogFilters },
});
</script>
