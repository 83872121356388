<template>
  <MyCollectionPage v-if="!$route.params.id" />
  <MyChannelPage v-else />
</template>

<script>
import MyCollectionPage from '@/pages/collection/components/MyCollectionPage.vue';
import MyChannelPage from '@/pages/my-channel/MyChannelPage.vue';

export default {
  components: {
    MyCollectionPage,
    MyChannelPage,
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.collection {
  display: flex;
  flex-flow: column;
  width: 100%;

  &Header {
    @include f-title-2;
  }
}

.tabs {
  display: flex;
  flex-flow: row nowrap;
  margin-top: adjustPx(36px);
  height: adjustPx(83px);

  :first-child {
    margin-right: adjustPx(10px);
  }
}

.content {
  top: adjustPx(282px);
  left: adjustPx(138px);
  display: flex;
  margin-top: adjustPx(24px);
  width: 100%;

  &Fixed {
    position: fixed;
    margin-top: 0;
  }

  &Empty {
    top: adjustPx(271px);
  }
}

.hidden {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.skeletonButtons {
  position: fixed;
  top: adjustPx(170px);
  margin: 0;
  height: adjustPx(83px);
}
</style>
