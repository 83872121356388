<template>
  <div ref="el" :class="$style.wrapper">
    <div :class="$style.controls">
      <NavigatableItem
        :class="$style.control"
        :active-class="$style.active"
        variation="default"
        has-global-access
        autofocus
        :tag="UIButton"
        :on-click="changeVideoPlayState"
      >
        <IconPause v-if="isPlaying" :class="$style.icon" />
        <IconPlay v-else :class="$style.icon" />
      </NavigatableItem>

      <NavigatableItem
        v-if="isDvr"
        :class="[$style.control, $style.controlLive]"
        :active-class="$style.active"
        variation="default"
        has-global-access
        :tag="UIButton"
        :on-click="onSeekToLive"
      >
        <IconLiveOn v-if="isCurrentTimeLive" :class="$style.icon" />
        <IconLiveOff v-else :class="$style.icon" />

        <span :class="$style.controlLiveText">
          {{ liveText }}
        </span>
      </NavigatableItem>

      <div :class="$style.controlsList">
        <NavigatableItem
          v-if="isSerial && nextAvailableEpisode"
          :class="$style.control"
          :active-class="$style.active"
          has-global-access
          :tag="UIButton"
          :on-click="handlePlayNextAvailableEpisode"
        >
          <IconAudioNext :class="$style.icon" />

          <span :class="$style.controlText">Следующая</span>
        </NavigatableItem>

        <NavigatableItem
          v-if="isSerial"
          :class="$style.control"
          has-global-access
          :active-class="$style.active"
          :tag="UIButton"
          :on-click="handleShowSeriesMenu"
        >
          <IconEpisodes :class="$style.icon" />

          <span :class="$style.controlText">Серии</span>
        </NavigatableItem>
      </div>
    </div>
  </div>
</template>

<script>
import { MediaContentType } from '@package/sdk/src/api';
import { indexOutOfRange } from '@package/sdk/src/core';
import IconAudioNext from '@SMART/assets/icons/51x51/audio-next.svg';
import IconEpisodes from '@SMART/assets/icons/51x51/episodes.svg';
import IconPause from '@SMART/assets/icons/51x51/pause.svg';
import IconPlay from '@SMART/assets/icons/51x51/play.svg';
import { useContentAvailability } from '@SMART/index';
import { defineComponent } from '@vue/composition-api';

import IconLiveOff from '@/assets/icons/live-off.svg';
import IconLiveOn from '@/assets/icons/live-on.svg';
import UIButton from '@/components/button/UIButton.vue';

export default defineComponent({
  components: {
    IconPlay,
    IconPause,
    IconAudioNext,
    IconEpisodes,
    IconLiveOn,
    IconLiveOff,
  },
  props: {
    media: {
      type: Object,
      default: () => ({}),
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    isDvr: {
      type: Boolean,
      default: false,
    },
    isCurrentTimeLive: {
      type: Boolean,
      default: false,
    },
    onSeekToLive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {
      UIButton,
    };
  },
  computed: {
    isSerial: function () {
      return this.media?.contentType === MediaContentType.Serial;
    },
    liveText: function () {
      return this.isCurrentTimeLive ? 'Прямой эфир' : 'Вернуться в эфир';
    },
    nextAvailableEpisode: function () {
      const route = this.$route;
      const seasons = this.media.seasons;
      const episodeId = route.query.episodeId;
      let currentSeasonNumber = 1;
      let currentEpisodeNumber = 1;

      for (let i = 0; i < seasons.length; i++) {
        const index = seasons[i].episodes.findIndex((episode) => episode.id === episodeId);

        if (index !== -1) {
          currentSeasonNumber = i + 1;
          currentEpisodeNumber = index + 1;
          break;
        }
      }

      return this.getNextAvailableEpisode({ seasons, currentSeasonNumber, currentEpisodeNumber });
    },
  },
  methods: {
    handleShowSeriesMenu: function () {
      this.$emit('showSeriesMenu');
    },
    handlePlayNextAvailableEpisode: function () {
      const next = this.nextAvailableEpisode;

      if (!next) {
        return;
      }

      this.$emit('playNextAvailableEpisode', { id: next.episode.id, type: next.episode.contentType });
    },
    changeVideoPlayState: function () {
      this.$emit(this.isPlaying ? 'pause' : 'play');
    },
    getNextAvailableEpisode: function (options) {
      const { isAvailable } = useContentAvailability();
      const { currentSeasonNumber, currentEpisodeNumber, seasons } = options;

      const startSeasonIndex = seasons?.findIndex((season) => currentSeasonNumber === season.number);

      const startEpisodeIndex = seasons[startSeasonIndex]?.episodes.findIndex(
        (episode) => currentEpisodeNumber === episode.number,
      );

      if (indexOutOfRange(startSeasonIndex) || indexOutOfRange(startEpisodeIndex)) {
        return null;
      }

      let episodeIndex = startEpisodeIndex + 1;

      function getNextAvailable(startIndex, endIndex) {
        for (let i = startIndex; i < endIndex; i++) {
          for (let j = episodeIndex; j < seasons[i].episodes.length; j++) {
            if (j === startEpisodeIndex && i === startSeasonIndex) {
              return;
            }

            const currentSeason = seasons[i];
            const currentEpisode = currentSeason.episodes[j];

            const matchedEpisode = isAvailable(currentEpisode);

            if (matchedEpisode) {
              const episode = seasons[i].episodes[j];
              const season = seasons[i];

              return {
                episode,
                season,
              };
            }
          }

          episodeIndex = 0;
        }

        if (startIndex !== 0) {
          return getNextAvailable(0, startSeasonIndex);
        }
      }

      return getNextAvailable(startSeasonIndex, seasons.length);
    },
  },
});
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/fonts';

.wrapper {
  display: flex;
  padding-top: adjustPx(16px);
}

.controls {
  display: flex;

  &List {
    display: flex;
    margin-left: adjustPx(40px);

    .control:not(:first-child) {
      margin-left: adjustPx(16px);
    }
  }
}

.control {
  display: flex;
  align-items: center;
  padding: adjustPx(26px);
  border-radius: adjustPx(18px);
  border: none;
  background-color: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary);
  outline: none;

  @include f-label-2;

  &Text {
    margin-left: adjustPx(12px);
  }

  &Live {
    margin-left: adjustPx(16px);

    &Text {
      margin-left: adjustPx(12px);
    }
  }
}

.active {
  background: var(--color-notheme-bg-accent);
  color: var(--color-notheme-text-accent);
}

.icon {
  width: adjustPx(48px);
  height: adjustPx(48px);
}
</style>
