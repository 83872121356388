import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticEventSender } from '../index';
import type { GAValue } from './kmza';

type AnalyticRegistrationType = 'social_media_login' | 'login';

export enum AnalyticAuthType {
  Authorization = 'authorization',
  Registration = 'registration',
}

interface AuthAnalyticOptions {
  authMethod?: AnalyticRegistrationType;
  oauth?: 'vk_id' | 'yandex';
  auth?: AnalyticAuthType;
  login?: string;
}

export type AnalyticAuthPageFrom = 'promocode_button' | 'sign_in' | 'after_offer' | 'action_button' | 'free_tv';

export function useAuthPageAnalytics(sender: AnalyticEventSender) {
  const prepareAuthPayload = (options?: AuthAnalyticOptions) => {
    const values: GAValue[] = [];

    if (options) {
      const { authMethod, oauth } = options;

      values.push({
        label: AnalyticPayloadLabelName.Auth,
        value: authMethod,
      });

      if (oauth) {
        values.push({
          label: AnalyticPayloadLabelName.SocialMedia,
          value: oauth,
        });
      }
    }

    return values;
  };

  const onShowRegPasswordPage = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowRegPasswordPage,
      page: AnalyticPageName.Account,
    });
  };

  const onShowLoginPasswordPage = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowLoginPasswordPage,
      page: AnalyticPageName.Account,
    });
  };

  const onGotoAuthPage = (page: AnalyticPageName, from: AnalyticAuthPageFrom) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoAuthPage,
      page,
      values: [
        {
          label: AnalyticPayloadLabelName.From,
          value: from,
        },
      ],
    });
  };

  const onShowAuthPage = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowAuthPage,
      page: AnalyticPageName.Account,
    });
  };

  const onSuccessRegistration = (options?: AuthAnalyticOptions) => {
    const values = prepareAuthPayload(options);

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoAuthSuccessRegistration,
      page: AnalyticPageName.Account,
      values,
    });
  };

  const onSuccessAuthorization = (options?: AuthAnalyticOptions) => {
    const values = prepareAuthPayload(options);

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoAuthSuccessAuthorization,
      page: AnalyticPageName.Account,
      values,
    });
  };

  const onClickAuthLoginEntering = ({ login, auth }: AuthAnalyticOptions) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAuthLoginEntering,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.Login,
          value: login,
        },
        {
          label: AnalyticPayloadLabelName.Auth,
          value: auth,
        },
      ],
    });
  };

  const onClickAuthLoginSocials = ({ oauth }: AuthAnalyticOptions) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAuthLoginSocials,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.SocialMedia,
          value: oauth,
        },
      ],
    });
  };

  const onClickAuthContinue = (login: string, promocode?: string) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAuthContinue,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.Login,
          value: login,
        },
        {
          label: AnalyticPayloadLabelName.Promocode,
          value: promocode,
        },
      ],
    });
  };

  const onClickAuthPasswordEntering = ({ auth }: AuthAnalyticOptions) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAuthPasswordEntering,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.Auth,
          value: auth,
        },
      ],
    });
  };

  const onClickAuthPasswordForgot = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAuthPasswordForgot,
      page: AnalyticPageName.Account,
    });
  };

  const onSubscribeCardBound = (promocode?: string) => {
    const values: GAValue[] = [];

    if (promocode) {
      values.push({
        label: AnalyticPayloadLabelName.Promocode,
        value: promocode,
      });
    }

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoSubscriberCardBound,
      page: AnalyticPageName.Account,
      values,
    });
  };

  return {
    onClickAuthContinue,
    onClickAuthLoginEntering,
    onClickAuthPasswordEntering,
    onClickAuthPasswordForgot,
    onClickAuthLoginSocials,
    onSubscribeCardBound,
    onSuccessAuthorization,
    onSuccessRegistration,
    onShowAuthPage,
    onGotoAuthPage,
    onShowRegPasswordPage,
    onShowLoginPasswordPage,
  };
}
