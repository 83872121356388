<template>
  <div role="tooltip" :class="$style.help">
    <IconTriangle
      :class="{
        [$style.icon]: true,
        [$style.bottomIcon]: position === 'bottom',
        [$style.rightIcon]: position === 'right',
      }"
    />
    <div
      :class="{
        [$style.text]: true,
        [$style.bottomText]: position === 'bottom',
        [$style.rightText]: position === 'right',
      }"
    >
      <span :class="$style.message">{{ message }}</span>
    </div>
  </div>
</template>

<script>
import IconTriangle from '@SMART/assets/icons/33x33/triangle.svg';

export default {
  components: { IconTriangle },
  props: {
    message: { type: String },
    position: { type: String },
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.help {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-pseudo-elements);
  width: fit-content;

  .icon {
    position: absolute;
    width: adjustPx(23px);
    height: adjustPx(14px);
  }

  .text {
    position: absolute;
    display: table;
    padding: adjustPx(14px) adjustPx(24px);
    border-radius: adjustPx(70px);
    border-color: var(--color-stroke-field-border);
    background-color: var(--color-stroke-field-border);
    color: var(--color-text-primary);
    white-space: nowrap;

    @include f-body;
  }

  .message {
    @include f-body;
  }

  .bottomIcon {
    top: adjustPx(38px);
    left: adjustPx(-40px);
  }

  .bottomText {
    top: adjustPx(48px);
    left: adjustPx(-90px);
  }

  .rightIcon {
    top: adjustPx(12px);
    transform: rotate(-90deg);
  }

  .rightText {
    top: adjustPx(-15px);
    left: adjustPx(15px);
  }
}
</style>
