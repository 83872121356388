import { ProcessingMapper } from '../base/processing-mapper';
import { CountryMapper } from '../content/country';
import type { ApiChannelProgram } from './channel-types';
import type { ChannelProgram } from './types/channel';

export class ChannelProgramMapper {
  static mapMany(items: ApiChannelProgram[]): ChannelProgram[] {
    return ProcessingMapper.process(items.map(ChannelProgramMapper.map));
  }

  static map({
    cast,
    genre,
    director,
    sequence_number,
    season_number,
    episode_number,
    episode_title,
    episode_synopsis,
    age_limit,
    countries,
    start_time,
    content_id,
    content_type,
    content_slug,
    serial_id,
    ...rest
  }: ApiChannelProgram): ChannelProgram {
    return ProcessingMapper.process({
      ...rest,
      cast: cast || [],
      genre: genre || [],
      director: director || [],
      sequenceNumber: sequence_number,
      seasonNumber: season_number,
      episodeNumber: episode_number,
      episodeTitle: episode_title,
      episodeSynopsis: episode_synopsis,
      ageLimit: age_limit,
      countries: countries ? CountryMapper.mapMany(countries) : [],
      startTime: start_time,
      contentId: content_id,
      contentType: content_type,
      contentSlug: content_slug,
      serialId: serial_id,
    });
  }
}
