import type { Channel } from '@package/sdk/src/api/channels/types/channel';

import { defineStore } from './utils/define-store';
import { getStoreProperty, VuexStoreNamespace } from './utils/namespaces';

export type TvChannelSelectedItem = {
  focusKey: string;
};

export interface TvChannelState {
  _channels: Channel[];
  _selectedChannelItem: TvChannelSelectedItem;
}

export interface TvChannelGetters {
  channels: Channel[];
  selectedChannelItem: TvChannelSelectedItem;
}

export interface TvChannelActionsWithoutStoreArgument {
  setChannels(channels: Channel[]): void;

  reset(): void;

  updateSelectedChannelItem(item: TvChannelSelectedItem): void;
}

export const useTvChannelsStore = defineStore<
  TvChannelState,
  TvChannelGetters,
  TvChannelActionsWithoutStoreArgument,
  VuexStoreNamespace.TvChannels
>(VuexStoreNamespace.TvChannels, {
  state: (): TvChannelState => ({
    _channels: [],
    _selectedChannelItem: {
      focusKey: '',
    },
  }),
  getters: {
    channels: (state) => state._channels,
    selectedChannelItem: (state) => state._selectedChannelItem,
  },
  actions: {
    setChannels(store, value: Channel[]) {
      const channelsProp = getStoreProperty(VuexStoreNamespace.TvChannels, '$patch');

      store.commit(channelsProp, { _channels: value }, { root: true });
    },
    reset(store) {
      store.commit(getStoreProperty(VuexStoreNamespace.TvChannels, '$patch'), { _channels: [] }, { root: true });
    },
    updateSelectedChannelItem(store, item: TvChannelSelectedItem) {
      store.commit(getStoreProperty(VuexStoreNamespace.TvChannels, '$patch'), { _selectedChannelItem: item });
    },
  },
});
