<template>
  <UIModal>
    <div
      v-for="alert in alertService.alerts.value"
      :key="alert.message"
      :class="{
        [$style.message]: true,
        [$style.messageError]: alert.type === AlertMessageTypes.Error,
        [$style.messageSuccess]: alert.type === AlertMessageTypes.Success,
        [$style.messageWarning]: alert.type === AlertMessageTypes.Warning,
      }"
    >
      <slot name="icon">
        <span v-if="!alert.hideIcon" :class="$style.icon">
          <AlertCircleIcon v-if="alert.type === AlertMessageTypes.Error" />
          <AlertCircleIcon v-if="alert.type === AlertMessageTypes.Warning" />
          <SaveIcon v-if="alert.type === AlertMessageTypes.Success" />
        </span>
      </slot>
      <slot name="text">
        <span>{{ alert.message }}</span>
      </slot>
    </div>
  </UIModal>
</template>

<script>
import AlertCircleIcon from '@SMART/assets/icons/51x51/alert-circle.svg';
import SaveIcon from '@SMART/assets/icons/51x51/save.svg';
import { AlertMessageTypes, alertService } from '@SMART/index';

import UIModal from '@/components/modal/UIModal.vue';

export default {
  components: { UIModal, SaveIcon, AlertCircleIcon },
  setup() {
    return { alertService, AlertMessageTypes };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.message {
  position: fixed;
  top: adjustPx(90px);
  right: 0;
  z-index: map-get($map: $layers, $key: --z-index-alert);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  min-width: adjustPx(800px);
  min-height: adjustPx(120px);
  padding-right: adjustPx(28px);
  padding-left: adjustPx(28px);
  word-break: break-all;
  border-radius: adjustPx(8px) 0 0 adjustPx(8px);

  @include f-subtitle;

  &Error {
    border: 1px solid var(--color-bg-negative);
    background: var(--color-bg-secondary-60);
    color: var(--color-bg-negative);
  }

  &Success {
    border: 1px solid var(--color-bg-accent);
    background: var(--color-bg-secondary-60);
    color: var(--color-bg-accent);
  }

  &Warning {
    border: 1px solid var(--color-text-negative);
    background: var(--color-bg-secondary-60);
    color: var(--color-bg-negative);
  }
}

.icon {
  margin-right: 5px;
}
</style>
