import { ProcessingMapper } from '../base/processing-mapper';
import type { ApiParentControl } from './parent-control-types';
import type { ParentControl } from './types/profile';

export class ParentControlMapper {
  static map({ age_limit, ...rest }: ApiParentControl): ParentControl {
    return ProcessingMapper.process({
      ...rest,
      ageLimit: age_limit,
    });
  }
}
