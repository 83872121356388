import { environmentService } from '../services';

export function getEmailSuggestions() {
  const emails = ['@mail.ru', '@yandex.ru', '.com'];

  const isRelease = environmentService.getVariable('isRelease');

  if (!isRelease) {
    emails.unshift('@test.ru');
  }

  if (isRelease) {
    emails.unshift('@gmail.com');
  }

  return emails;
}
