import type { ContentMoment } from '../../api/content/types/content-moment';
import { MediaContentType } from '../../api/content/types/content-type';
import type { Media } from '../../api/content/types/media';
import { isDefined } from '../../core/std/types';
import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticDirectionValue, AnalyticEventSender } from '../index';
import { getAnalyticContentTypeValue } from './get-kmza-content-type-value';
import type { GAValue } from './kmza';

export enum ItemPageFrom {
  Dsml = 'dsml',
  Playlist = 'playlist',
  Promo = 'promo',
  ContinueWatch = 'continue_watch',
  Compilation = 'compilation',
  Search = 'search',
  Catalog = 'catalog',
  Collections = 'collections',
  MyChannel = 'mychannel',
  TvChannel = 'tvchannel',
  Similar = 'similar',
}

export interface ItemPageOptions {
  event?: AnalyticEventNameNew;
  name?: AnalyticEventNameNew;
  title: string;
  contentType: MediaContentType;
  searchText?: string;
  numberInResults?: number;
  isRandomItem?: boolean;
  page?: AnalyticPageName;
  season?: number;
  episode?: number;
  itemId?: string;
  from?: ItemPageFrom;
  playlistTitle?: string;
  kinomTitle?: string;
  episodeId?: string;
  kinomId?: string;
  position?: number;
  currentItemTitle?: string;
  currentItemType?: MediaContentType;
}

interface ItemPageOptionsForCompilations {
  titlesSelectionPosition?: number;
  titlesSelectionName?: string;
  page: AnalyticPageName;
  itemId?: string;
  episodeId?: string;
  kinomId?: string;
  itemTitle?: string;
  itemType?: MediaContentType;
  itemCompilationPosition?: number;
}

interface Options {
  title: string;
  contentType: string;
  page?: string;
  itemId?: string;
  episodeId?: string;
  direction?: AnalyticDirectionValue;
}

const getPayload = ({
  title,
  contentType,
  season,
  episode,
  from,
  playlistTitle,
  isRandomItem,
  position,
  currentItemTitle,
  currentItemType,
}: ItemPageOptions) => {
  let payload: GAValue[] = [
    {
      label: AnalyticPayloadLabelName.ItemTitle,
      value: title,
    },
    {
      label: AnalyticPayloadLabelName.ItemType,
      value: getAnalyticContentTypeValue(contentType),
    },
  ];

  if (contentType !== MediaContentType.MOVIE) {
    payload = [
      ...payload,
      {
        label: AnalyticPayloadLabelName.Season,
        value: String(season || 0),
      },
      {
        label: AnalyticPayloadLabelName.Episode,
        value: String(episode || 0),
      },
    ];
  }

  if (isDefined(position)) {
    payload.push({
      label: AnalyticPayloadLabelName.Position,
      value: position,
    });
  }

  if (isRandomItem) {
    payload.push({
      label: AnalyticPayloadLabelName.RandomItem,
      value: true,
    });
  }

  if (from) {
    payload.push({
      label: AnalyticPayloadLabelName.From,
      value: from,
    });
  }

  if (playlistTitle) {
    payload.push({
      label: AnalyticPayloadLabelName.PlaylistTitle,
      value: playlistTitle,
    });
  }

  if (currentItemTitle) {
    payload.push({
      label: AnalyticPayloadLabelName.CurrentItemTitle,
      value: currentItemTitle,
    });
  }

  if (currentItemType) {
    payload.push({
      label: AnalyticPayloadLabelName.CurrentItemType,
      value: currentItemType,
    });
  }

  return payload;
};

const getPreparedOptions = ({ itemId, episodeId, kinomId }: ItemPageOptions | ItemPageOptionsForCompilations) => {
  return {
    itemId,
    ...(episodeId && { episodeId }),
    ...(kinomId && { kinomId }),
  };
};

export function useContentPageAnalytics(sender: AnalyticEventSender) {
  const onClickItemAdditionalMetadata = (content: Media) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickItemAdditionalMetadata,
      page: AnalyticPageName.Item,
      values: [
        {
          label: AnalyticPayloadLabelName.ItemTitle,
          value: content.title,
        },
        {
          label: AnalyticPayloadLabelName.ItemType,
          value: getAnalyticContentTypeValue(content.contentType),
        },
      ],
    });
  };

  const onAutoItemSimilarBeltDisplayed = (options: ItemPageOptions) => {
    const payload = getPayload(options);
    const preparedOptions = getPreparedOptions(options);

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoItemSimilarBeltDisplayed,
      page: AnalyticPageName.Item,
      values: payload,
      options: preparedOptions,
    });
  };

  const onGotoItemPageFromSearch = ({
    title,
    contentType,
    searchText,
    numberInResults,
    page,
    itemId,
  }: ItemPageOptions) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoItemPage,
      page,
      values: [
        {
          label: AnalyticPayloadLabelName.ItemTitle,
          value: title,
        },
        {
          label: AnalyticPayloadLabelName.ItemType,
          value: contentType,
        },
        {
          label: AnalyticPayloadLabelName.Request,
          value: searchText,
        },
        {
          label: AnalyticPayloadLabelName.NumberInResults,
          value: numberInResults,
        },
        {
          label: AnalyticPayloadLabelName.From,
          value: ItemPageFrom.Search,
        },
      ],
      options: {
        itemId,
      },
    });
  };

  const onGotoItemPage = (options: ItemPageOptions) => {
    const payload = getPayload(options);
    const preparedOptions = getPreparedOptions(options);

    const values: GAValue[] = [...payload];

    if (options.kinomTitle) {
      values.push({
        label: AnalyticPayloadLabelName.KinomTitle,
        value: options.kinomTitle,
      });
    }

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoItemPage,
      page: options.page,
      values,
      options: preparedOptions,
    });
  };

  const onGotoItemPageFromPromoSlider = (
    { id, contentId, contentTitle, contentType, seasonNumber, episodeNumber }: Partial<ContentMoment>,
    position?: number,
  ) => {
    let payload = [
      {
        label: AnalyticPayloadLabelName.ItemTitle,
        value: contentTitle,
      },
      {
        label: AnalyticPayloadLabelName.ItemType,
        value: getAnalyticContentTypeValue(contentType as MediaContentType),
      },
      {
        label: AnalyticPayloadLabelName.From,
        value: ItemPageFrom.Promo,
      },
      {
        label: AnalyticPayloadLabelName.Position,
        value: position,
      },
    ];

    if (contentType !== MediaContentType.MOVIE) {
      payload = [
        ...payload,
        {
          label: AnalyticPayloadLabelName.Season,
          value: String(seasonNumber || 0),
        },
        {
          label: AnalyticPayloadLabelName.Episode,
          value: String(episodeNumber || 0),
        },
      ];
    }

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoItemPage,
      page: AnalyticPageName.Main,
      values: payload,
      options: {
        itemId: contentId,
        kinomId: id,
      },
    });
  };

  const onGotoItemPageFromCompilation = ({
    itemTitle,
    itemType,
    itemCompilationPosition,
    page,
    itemId,
    titlesSelectionName,
  }: ItemPageOptionsForCompilations) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoItemPage,
      page,
      values: [
        {
          label: AnalyticPayloadLabelName.ItemTitle,
          value: itemTitle,
        },
        {
          label: AnalyticPayloadLabelName.ItemType,
          value: itemType ? getAnalyticContentTypeValue(itemType) : '',
        },
        {
          label: AnalyticPayloadLabelName.ItemCompilationPosition,
          value: itemCompilationPosition,
        },
        {
          label: AnalyticPayloadLabelName.CompilationName,
          value: titlesSelectionName,
        },
        {
          label: AnalyticPayloadLabelName.From,
          value: ItemPageFrom.Compilation,
        },
      ],
      options: {
        itemId,
      },
    });
  };

  const onClickItemCollectionsSet = (options: ItemPageOptions) => {
    const payload = getPayload(options);
    const preparedOptions = getPreparedOptions(options);

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickItemCollectionsSet,
      page: AnalyticPageName.Item,
      values: payload,
      options: preparedOptions,
    });
  };

  const onClickItemCollectionsRemove = (options: ItemPageOptions) => {
    const payload = getPayload(options);
    const preparedOptions = getPreparedOptions(options);

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickItemCollectionsRemove,
      page: AnalyticPageName.Item,
      values: payload,
      options: preparedOptions,
    });
  };

  const onClickItemKinomBeltScroll = ({ contentType, title, page, itemId, episodeId, direction }: Options) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickItemKinomBeltScroll,
      page,
      values: [
        {
          label: AnalyticPayloadLabelName.ItemType,
          value: contentType,
        },
        {
          label: AnalyticPayloadLabelName.ItemTitle,
          value: title,
        },
        {
          label: AnalyticPayloadLabelName.Direction,
          value: direction,
        },
      ],
      options: {
        itemId,
        ...(episodeId && { episodeId }),
      },
    });
  };

  const onClickItemSimilarBeltScroll = ({ contentType, title, page, itemId, episodeId, direction }: Options) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickItemSimilarBeltScroll,
      page,
      values: [
        {
          label: AnalyticPayloadLabelName.ItemType,
          value: contentType,
        },
        {
          label: AnalyticPayloadLabelName.ItemTitle,
          value: title,
        },
        {
          label: AnalyticPayloadLabelName.Direction,
          value: direction,
        },
      ],
      options: {
        itemId,
        ...(episodeId && { episodeId }),
      },
    });
  };

  const onClickItemSelectEpisode = (options: ItemPageOptions) => {
    const values = getPayload(options);

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickItemSelectEpisode,
      page: AnalyticPageName.Item,
      values,
      options: {
        itemId: options.itemId,
        episodeId: options.episodeId,
      },
    });
  };

  const onShowItemPage = (options: ItemPageOptions) => {
    const values = getPayload(options);

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowItemPage,
      page: AnalyticPageName.Item,
      values,
      options: {
        itemId: options.itemId,
      },
    });
  };

  return {
    onClickItemAdditionalMetadata,
    onClickItemCollectionsSet,
    onClickItemCollectionsRemove,
    onAutoItemSimilarBeltDisplayed,
    onGotoItemPage,
    onGotoItemPageFromPromoSlider,
    onGotoItemPageFromSearch,
    onClickItemKinomBeltScroll,
    onClickItemSimilarBeltScroll,
    onClickItemSelectEpisode,
    onShowItemPage,
    onGotoItemPageFromCompilation,
  };
}
