import { AnalyticEventNameNew } from '@package/sdk/src/analytics/analytic-event-name';
import { timeout } from '@package/sdk/src/core/std/async';

import addScriptTagWithHtml from '../../utils/add-script-tag-with-html';

const getYMScript = (id: string) =>
  `<!-- Yandex.Metrika counter -->
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym({{ YM_ID }}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });
<!-- /Yandex.Metrika counter -->`.replace('{{ YM_ID }}', id);

const YANDEX_METRICA_ID = '95696750';

declare global {
  interface Window {
    ym?(ymId: string, eventName: string, name: AnalyticEventNameNew): void;
  }
}

export default class YandexMetricaService {
  constructor() {}

  public init() {
    this.initYandexMetrica();
  }

  public sendEvent(name: AnalyticEventNameNew) {
    if (!window.ym) {
      return;
    }

    window.ym(YANDEX_METRICA_ID, 'reachGoal', name);
  }

  private async initYandexMetrica() {
    await timeout(1500);

    const scriptHTML = getYMScript(YANDEX_METRICA_ID);
    addScriptTagWithHtml(scriptHTML, 'yandex-metrica');
  }
}
