<template>
  <UIModal ref="el">
    <section :class="$style.termsModal">
      <UIScroller :parent-focus-key="focusKey">
        <h1 :class="$style.termsHeader">{{ $t(termsTitle) }}</h1>
        <div :class="$style.html" v-html="$t(termsText)"></div>
      </UIScroller>
      <NavigatableItem
        :tag="AppButton"
        autofocus
        :prop-parent-focus-key="focusKey"
        :class="$style.termsCloseButton"
        :text="$t('close')"
        :on-click="() => $emit('on-close', false)"
      />
    </section>
  </UIModal>
</template>

<script>
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { defineComponent, onMounted, provide } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import UIModal from '@/components/modal/UIModal.vue';
import UIScroller from '@/components/scroller/UIScroller.vue';

export default defineComponent({
  props: {
    termsTitle: {
      type: String,
    },
    termsText: {
      type: String,
    },
  },
  setup() {
    const { el, focusSelf, focusKey } = useNavigatable({
      focusKey: 'EMAIL_MODAL',
      isFocusBoundary: true,
      trackChildren: true,
    });

    provide('parentFocusKey', focusKey.value);

    onMounted(() => focusSelf());

    return {
      focusKey,
      AppButton,
      el,
    };
  },
  components: { UIModal, UIScroller },
});
</script>

<style module lang="scss">
.html {
  ul,
  p,
  li {
    margin-bottom: adjustPx(20px);
  }

  li {
    margin-left: adjustPx(20px);
  }

  ol {
    counter-reset: item;
  }

  ol > li {
    counter-increment: item;
  }

  ol ol > li {
    display: block;
  }

  ol ol > li:before {
    margin-left: -20px;
    content: counters(item, '.') '. ';
  }
}

.terms {
  &Modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    background: var(--color-bg-primary);
    flex-flow: column;
    justify-content: center;
  }

  &Header {
    margin-bottom: adjustPx(44px);
  }

  &CloseButton {
    margin-top: adjustPx(44px);
  }
}
</style>
