<template>
  <div ref="el" :class="$style.wrapper">
    <div v-if="previewSrc" :class="$style.preview">
      <app-image :key="previewSrc" :src="previewSrc" :width="1280" />
    </div>

    <template v-if="content">
      <TopHeader :title="content.title" :subtitle="subtitle" :limit="limit" :percent="content.contentMatchPercent" />

      <div v-if="$route.name === $RouterPage.MediaCardControls" :class="$style.creators">
        <CastInfo v-if="content.actors.length > 0" :actors="content.actors" />

        <CreatorInfo v-if="content.directors.length > 0" :directors="content.directors" />
      </div>

      <section :class="$style.content">
        <RouterView />
      </section>
    </template>
  </div>
</template>

<script>
import { timeout, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { collectionService, RouterPage, routerService, storeToRefs, useContentStore } from '@SMART/index';
import { computed, onMounted, onUnmounted, provide } from '@vue/composition-api';

import AppImage from '@/components/app-image/AppImage.vue';
import TopHeader from '@/components/top-header/TopHeader.vue';

import CastInfo from './components/CastInfo.vue';
import CreatorInfo from './components/CreatorInfo.vue';

export default {
  components: {
    AppImage,
    TopHeader,
    CastInfo,
    CreatorInfo,
  },
  setup(props, { root: { $route: route } }) {
    const contentStore = useContentStore();
    const { moments, content, subtitle, limit } = storeToRefs(contentStore);

    const { el, focusKey, focusSelf } = useNavigatable({
      focusKey: 'MEDIA_CARD_PAGE',
      forceFocus: true,
    });
    provide('parentFocusKey', focusKey.value);

    const previewSrc = computed(() => content.value?.background);

    const fetchContent = async () => {
      const { id, type } = route.params;

      await collectionService.updateSavedItems();

      if (!id || !type) {
        throw new UnexpectedComponentStateError('params');
      }

      await Promise.all([
        await contentStore.fetchContent({ id, type }),
        await contentStore.fetchMoments(id),
        await contentStore.fetchCollections(id),
      ]);

      if (!content.value) {
        throw new UnexpectedComponentStateError('content');
      }
    };

    const onLoadError = async (error) => {
      if (error) {
        await timeout(250);
        return routerService.replace({ name: RouterPage.MainPage });
      }
    };

    onUnmounted(() => {
      contentStore.resetContentData();
    });

    onMounted(async () => {
      try {
        await fetchContent();

        focusSelf();
      } catch (error) {
        return onLoadError(error);
      }
    });

    return {
      previewSrc,
      el,
      content,
      subtitle,
      limit,
      moments,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/layers';

.wrapper {
  display: flex;
  flex-direction: column;
  margin-left: adjustPx(140px);
  padding-top: adjustPx(60px);

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: map-get($map: $layers, $key: --z-index-pseudo-elements);
    background: var(--color-bg-primary);
    content: '';
  }
}

.preview {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.preview:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('@/assets/background-gradient-bottom-to-top.webp');
  content: '';
}

.creators {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-content);
  margin-top: adjustPx(42px);
}

.content {
  height: inherit;
  padding-top: adjustPx(60px);
}
</style>
