var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.tabs,attrs:{"role":"tablist"}},[_c('UITabs',{scopedSlots:_vm._u([{key:"tabs",fn:function(ref){
var onTabChanged = ref.onTabChanged;
return [_c('NavigatableItem',{attrs:{"tag":_vm.UITab,"disabled":_vm.disabled,"selected":_vm.moviesSelected,"active-class":_vm.$style.active,"on-click":function () {
            onTabChanged(0);
            _vm.onSelect(_vm.MediaContentType.Movie);
          }}},[_c('span',{class:_vm.$style.text},[_vm._v(_vm._s(_vm.$t('pages.catalog.filmsTab')))])]),_c('NavigatableItem',{class:_vm.$style.tab,attrs:{"tag":_vm.UITab,"disabled":_vm.disabled,"selected":_vm.serialsSelected,"active-class":_vm.$style.active,"on-click":function () {
            onTabChanged(1);
            _vm.onSelect(_vm.MediaContentType.Serial);
          }}},[_c('span',{class:_vm.$style.text},[_vm._v(_vm._s(_vm.$t('pages.catalog.serialsTab')))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }