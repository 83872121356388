<template>
  <article v-if="!$route.params.id" ref="el" :class="[$style.collection]">
    <h1 :class="$style.collectionHeader">{{ $t('pages.collection.header') }}</h1>

    <SkeletonButtons v-if="isLoading && !(hasContent || hasMoments)" :class="$style.skeletonButtons" />

    <UITabs variant="secondary" :class="$style.tabs">
      <template v-slot:tabs>
        <NavigatableItem
          variant="secondary"
          :tag="UITab"
          :selected="currentTabIndex === 0"
          :on-click="() => onChangeContent(CollectionContentType.Content, 0)"
          @active="onTabActive(true, 0)"
          @inactive="onTabActive(false, 0)"
        >
          {{ $t('pages.collection.moviesTab') }}
        </NavigatableItem>
        <NavigatableItem
          variant="secondary"
          :tag="UITab"
          :selected="currentTabIndex === 1"
          :on-click="() => onChangeContent(CollectionContentType.ContentMoment, 1)"
          @active="onTabActive(true, 1)"
          @inactive="onTabActive(false, 1)"
        >
          {{ $t('pages.collection.momentsTab') }}
        </NavigatableItem>
      </template>
    </UITabs>

    <UIContent
      :on-load-chunk="onLoadCollectionChunk"
      :content-type="contentType"
      :items-per-row="itemsPerRow"
      :items-per-scroll="itemsPerScroll"
      :first-load-size="firstLoadSize"
      :force-update="shouldUpdateContent"
      :empty-header="$t(emptyHeaderKey)"
      :class="contentClass"
      @select:moment="onSelectItem"
      @activated="onActivatedContent"
    />
  </article>
</template>

<script>
import { CollectionContentType } from '@package/sdk/src/api';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  catalogService,
  collectionService,
  logger,
  momentService,
  RouterPage,
  routerService,
  storeToRefs,
  useCatalogStore,
  useMediaContentActions,
  useSessionStore,
} from '@SMART/index';
import {
  computed,
  onActivated,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  useCssModule,
  watch,
} from '@vue/composition-api';

import UIContent from '@/components/content/UIContent.vue';
import { useUiContent } from '@/components/content/useUiContent';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import SkeletonButtons from '@/components/skeletons/SkeletonButtons.vue';
import UITab from '@/components/tabs/UITab.vue';
import UITabs from '@/components/tabs/UITabs.vue';

export default {
  components: {
    UIContent,
    NavigatableItem,
    SkeletonButtons,
    UITabs,
    UITab,
  },
  setup(props, { parent: { $route: route } }) {
    const { openContentPage } = useMediaContentActions();

    const { el, focusKey, focusSelf } = useNavigatable({ focusKey: 'MY_COLLECTION_PAGE' });
    provide('parentFocusKey', focusKey.value);

    onMounted(() => focusSelf());

    const sessionStore = useSessionStore();
    const catalogStore = useCatalogStore();

    const { isAuth } = storeToRefs(sessionStore);

    const style = useCssModule();

    let contentLoaded = false;

    const shouldUpdateContent = ref(false);
    const moments = ref([]);

    let isContentChanging = true;
    const currentTabIndex = ref(0);

    const activeTabs = [false, false];
    const onTabActive = async (value, index) => {
      activeTabs[index] = value;
    };

    const hasContent = ref(false);

    const hasMoments = ref(false);

    const loadSavedFilmsAndSerialsItems = async () => {
      try {
        const savedFilmsItems = collectionService.savedFilmsItems;
        const savedSerialsItems = collectionService.savedSerialsItems;

        if (!savedFilmsItems.length && !savedSerialsItems.length) {
          return { data: [], lineIndex: 0 };
        }

        const result = await Promise.all([
          ...savedFilmsItems.map((id) => catalogService.fetchMovie(id)),
          ...savedSerialsItems.map((id) => catalogService.fetchSerial(id)),
        ]);

        return result;
      } catch (e) {
        return [];
      }
    };

    const loadSavedMomentsItems = async () => {
      try {
        const savedMomentsItems = collectionService.savedMomentsItems;

        if (!savedMomentsItems.length) {
          return { data: [], lineIndex: 0 };
        }

        const result = await Promise.all(savedMomentsItems.map((id) => momentService.fetchMoment(id)));

        return result;
      } catch (e) {
        return [];
      }
    };

    const loadCollection = async (type, page, size) => {
      const handlers = {
        [CollectionContentType.ContentMoment]: () => collectionService.fetchCollectionMoments({ page, size }),
        [CollectionContentType.Content]: () => collectionService.fetchCollectionContent({ page, size }),
      };

      if (!handlers[type]) {
        return [];
      }

      const data = await handlers[type]();

      if (type === CollectionContentType.ContentMoment && data.length) {
        moments.value = [...moments.value, ...data];
      }

      return data;
    };

    const onSelect = (content, type) => {
      if (type === CollectionContentType.ContentMoment) {
        const PAGE_SIZE = 4;
        const momentIndex = moments.value.findIndex((moment) => moment.id === content.id);

        return routerService.push({
          name: RouterPage.CollectionPage,
          params: {
            page: Math.floor(momentIndex / PAGE_SIZE) + 1,
            size: PAGE_SIZE,
            type: 'saved',
            id: content.id,
          },
        });
      }

      return openContentPage({
        contentType: content.contentType,
        id: content.id,
      });
    };

    const isContentTypeChanging = () => {
      if (isContentChanging) {
        contentLoaded = false;
      }

      return isContentChanging;
    };

    const fetchItems = async (type, filter) => {
      if (isAuth.value) {
        return loadCollection(type, filter.page, filter.size);
      }

      if (type === CollectionContentType.Content) {
        return loadSavedFilmsAndSerialsItems();
      }

      if (type === CollectionContentType.ContentMoment) {
        return loadSavedMomentsItems();
      }

      return [];
    };

    const { contentType, firstLoadSize, itemsPerRow, itemsPerScroll, isLoading, onLoadCollectionChunk, onSelectItem } =
      useUiContent({
        onSelect,
        isContentChanging: isContentTypeChanging,
        onLoaded: () => {
          isContentChanging = false;
          shouldUpdateContent.value = false;
        },
        fetchItems,
        type: CollectionContentType.Content,
      });

    const onActivatedContent = async () => {
      if (contentLoaded) {
        return;
      }

      contentLoaded = true;
    };

    const onChangeContent = async (value, tabIndex) => {
      if (currentTabIndex.value === tabIndex) {
        return;
      }

      catalogService.abort('onChangeContent');
      contentType.value = value;
      isContentChanging = true;

      window.setTimeout(() => {
        shouldUpdateContent.value = true;
      }, 10);

      if (tabIndex !== undefined) {
        currentTabIndex.value = tabIndex;
      }
    };

    const onReloadContent = async () => {
      try {
        await collectionService.updateSavedItems();

        const [items, moments] = await Promise.all([
          collectionService.fetchCollectionContent({ page: 1 }),
          collectionService.fetchCollectionMoments({ page: 1 }),
        ]);

        hasContent.value = Boolean(items.length);
        hasMoments.value = Boolean(moments.length);
      } catch {
        hasContent.value = Boolean(collectionService.savedFilmsItems?.length);
        hasMoments.value = Boolean(collectionService.savedMomentsItems?.length);
      } finally {
        isLoading.value = false;
        shouldUpdateContent.value = true;
      }
    };

    onMounted(async () => {
      catalogStore.updateSelectedItem(null);
      catalogStore.setUpdated(false);
      await onReloadContent();
    });

    onBeforeUnmount(() => {
      catalogStore.updateSelectedItem(null);
    });

    onActivated(async () => {
      if (catalogStore.isUpdated) {
        catalogStore.updateSelectedItem(null);
        catalogStore.setUpdated(false);
        shouldUpdateContent.value = true;
        isContentChanging = true;
        return;
      }

      // focus will be updated in UIContent
      if (catalogStore.selectedItem?.item) {
        return;
      }

      if (!(hasContent.value || hasMoments.value) || catalogStore.isUpdated) {
        await onReloadContent();
      }
    });

    const emptyHeaderKey = computed(() => {
      if (!(hasContent.value || hasMoments.value)) {
        return 'pages.collection.empty';
      }

      if (contentType.value === CollectionContentType.Content) {
        return 'pages.collection.emptyContent';
      }

      return 'pages.collection.emptyMoments';
    });

    const contentClass = computed(() => ({
      [style.content]: true,
      [style.contentFixed]: hasContent.value || hasMoments.value,
      [style.contentEmpty]:
        !(hasContent.value || hasMoments.value) &&
        ((currentTabIndex.value === 0 && !hasContent.value) || (currentTabIndex.value === 1 && !hasMoments.value)),
    }));

    watch(
      () => route.params.id,
      () => {
        isContentChanging = true;
      },
    );

    return {
      isLoading,
      hasContent,
      hasMoments,
      el,
      currentTabIndex,
      onChangeContent,
      CollectionContentType,
      onTabActive,
      UITab,
      contentType,
      onLoadCollectionChunk,
      itemsPerRow,
      itemsPerScroll,
      firstLoadSize,
      shouldUpdateContent,
      emptyHeaderKey,
      contentClass,
      onSelectItem,
      onActivatedContent,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.collection {
  display: flex;
  flex-flow: column;
  margin-left: adjustPx(140px);
  padding-top: adjustPx(60px);
  width: 100%;

  &Header {
    @include f-title-2;
  }
}

.tabs {
  display: flex;
  flex-flow: row nowrap;
  height: adjustPx(83px);
  margin-top: adjustPx(36px);

  :first-child {
    margin-right: adjustPx(10px);
  }
}

.content {
  display: flex;
  width: 100%;
  margin-top: adjustPx(24px);
  overflow: hidden;

  &Fixed {
    top: adjustPx(271px);
    margin-top: 0;
  }

  &Empty {
    top: adjustPx(146px);
  }
}

.skeletonButtons {
  position: fixed;
  top: adjustPx(170px);
  margin: 0;
  height: adjustPx(83px);
}
</style>
