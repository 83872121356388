<template>
  <section :class="$style.terms">
    <h1 :class="$style.termsTitle">{{ $t('pages.auth.signUp.termsTitle') }}</h1>
    <NavigatableItem
      :tag="AppButton"
      :class="$style.termsButton"
      :text="$t('pages.auth.signUp.policyButton')"
      :on-click="() => onSelectTerms(true, 'policy')"
    />
    <NavigatableItem
      :tag="AppButton"
      :class="$style.termsButton"
      :text="$t('pages.auth.signUp.agreementButton')"
      :on-click="() => onSelectTerms(true, 'agreement')"
    />

    <EmailModal v-if="isTermsShown" :terms-text="termsText" :terms-title="termsTitle" @on-close="onSelectTerms" />
  </section>
</template>

<script>
import { customEventsService } from '@SMART/index';
import { computed, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import UIModal from '@/components/modal/UIModal.vue';
import UIScroller from '@/components/scroller/UIScroller.vue';
import EmailModal from '@/pages/auth/components/EmailModal.vue';

export default {
  components: {
    EmailModal,
    UIModal,
    UIScroller,
  },
  setup() {
    const isTermsShown = ref(false);
    const selectedTerm = ref('policy');

    const onSelectTerms = async (show, type) => {
      isTermsShown.value = show;

      if (type) {
        selectedTerm.value = type;
      }

      const onPressBackCallback = async () => {
        isTermsShown.value = false;
      };

      if (show) {
        customEventsService.setOnPressBackCallback(onPressBackCallback, true);
      }
    };

    const termsTitle = computed(() => `pages.auth.signUp.${selectedTerm.value}Title`);

    const termsText = computed(() => `pages.auth.signUp.${selectedTerm.value}Text`);

    return {
      isTermsShown,
      selectedTerm,
      onSelectTerms,
      termsTitle,
      termsText,
      AppButton,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.terms {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: adjustPx(400px);
  padding-left: adjustPx(110px);

  &Body {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }

  &ScrollButtons {
    display: flex;
    flex-flow: column;
    margin-left: adjustPx(61px);
  }

  &ScrollButton {
    display: flex;
    align-items: center;
    padding: 0;
    width: adjustPx(100px);
    height: adjustPx(100px);
    border-radius: adjustPx(50px);
    justify-content: center;
    min-width: adjustPx(100px);
    min-height: adjustPx(100px);
    margin-top: adjustPx(23px);
  }

  &Title {
    @include f-subtitle;
  }

  &Button {
    margin-top: adjustPx(16px);
  }

  &Modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    background: var(--color-bg-primary);
  }

  &Text {
    max-width: adjustPx(1500px);
    max-height: adjustPx(800px);
    overflow: auto;
  }

  &Header {
    margin-bottom: adjustPx(44px);
  }

  &CloseButton {
    margin-top: adjustPx(44px);
  }
}
</style>
