<template>
  <UIModal>
    <div ref="el" :class="$style.modal">
      <article :class="$style.illustration">
        <div :class="[$style.border, $style.borderLeft]"></div>
        <div :class="$style.slides">
          <img :class="$style.image" data-image src="@SMART/assets/images/my-channel-slider-1.webp" alt="" />
          <img
            data-image
            :class="[$style.image, $style.margin]"
            :width="250"
            src="@SMART/assets/images/my-channel-slider-2.webp"
            alt=""
          />
          <img data-image :class="$style.image" src="@SMART/assets/images/my-channel-slider-3.webp" alt="" />
          <img data-image :class="$style.image" src="@SMART/assets/images/my-channel-slider-4.webp" alt="" />
        </div>

        <div :class="$style.frame">
          <div :class="$style.frameSlides">
            <img data-image :class="$style.image" src="@SMART/assets/images/my-channel-slider-2.webp" alt="" />
            <img data-image :class="$style.image" src="@SMART/assets/images/my-channel-slider-3.webp" alt="" />
          </div>
          <KinomIcon :class="$style.frameIcon" />
        </div>
        <div :class="[$style.border, $style.borderRight]"></div>
      </article>
      <article :class="$style.text">
        <h3 :class="$style.title">{{ $t('pages.main.myChannel.title') }}</h3>
        <h4 :class="$style.subtitle">{{ $t('pages.main.myChannel.subtitle') }}</h4>
        <section :class="$style.buttons">
          <NavigatableItem
            :tag="AppButton"
            :class="$style.button"
            :prop-parent-focus-key="focusKey"
            autofocus
            :text="$t('pages.main.myChannel.gotoMyChannel')"
            :active-class="$style.active"
            :on-click="onGotoMyChannel"
          />
          <NavigatableItem
            :tag="AppButton"
            :prop-parent-focus-key="focusKey"
            :text="$t('pages.main.myChannel.cancel')"
            :class="$style.button"
            :active-class="$style.active"
            :on-click="onFinish"
          />
        </section>
      </article>
    </div>
  </UIModal>
</template>

<script>
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import KinomIcon from '@SMART/assets/icons/40x40/kinom.svg';
import { onboardingService, RouterPage, routerService } from '@SMART/index';
import { provide } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import UIModal from '@/components/modal/UIModal.vue';

export default {
  components: { KinomIcon, UIModal },
  setup(props, { emit }) {
    const { el, focusKey } = useNavigatable({
      focusKey: 'MY_CHANNEL_MODAL',
      isFocusBoundary: true,
    });
    provide('parentFocusKey', focusKey.value);

    const onFinish = () => {
      onboardingService.finishMyChannel();
      emit('finish');
    };

    const onGotoMyChannel = () => {
      onboardingService.finishMyChannel();
      routerService.push({ name: RouterPage.MyChannelPage });
    };

    return { AppButton, onGotoMyChannel, onFinish, focusKey, el };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: adjustPx(206px) adjustPx(48px) adjustPx(206px) 0;
  background: var(--color-bg-primary);
}
.illustration {
  display: flex;
  align-items: center;
  width: adjustPx(960px);
  height: adjustPx(668px);
  margin-right: adjustPx(48px);
  overflow: hidden;

  .border {
    position: absolute;
    z-index: 10;
    width: adjustPx(96px);
    height: adjustPx(668px);

    &Left {
      left: 0;
    }

    &Right {
      left: adjustPx(864px);
    }
  }

  .slides {
    position: fixed;
    top: adjustPx(438px);
    left: adjustPx(120px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: adjustPx(768px);

    .image {
      width: adjustPx(336px);
      height: adjustPx(216px);
      margin-right: adjustPx(18px);
      object-fit: cover;
      border-radius: adjustPx(36px);
      opacity: 0.6;
    }

    .margin {
      margin-right: adjustPx(120px);
    }
  }

  .frame {
    position: fixed;
    top: adjustPx(360px);
    left: adjustPx(198px);
    display: flex;
    align-items: center;
    width: adjustPx(540px);
    height: adjustPx(360px);
    border-radius: adjustPx(90px);
    background: rgba(196, 196, 196, 0.01);
    overflow: hidden;

    &Icon {
      position: absolute;
      top: adjustPx(102px);
      left: adjustPx(192px);
      width: adjustPx(156px);
      height: adjustPx(156px);
    }

    &Slides {
      position: absolute;
      left: adjustPx(-200px);
      display: flex;
      align-items: center;
      width: adjustPx(540px);
      height: adjustPx(360px);
      border-radius: adjustPx(90px);

      .image {
        width: adjustPx(390px);
        height: adjustPx(252px);
        margin-right: adjustPx(120px);
        object-fit: cover;
        border-radius: adjustPx(36px);
      }
    }
  }
}

.text {
  position: fixed;
  top: adjustPx(316px);
  left: adjustPx(960px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: adjustPx(864px);
  height: adjustPx(668px);
  background: var(--color-bg-primary);

  .title {
    margin-bottom: adjustPx(32px);

    @include f-title-3-2;
  }

  .subtitle {
    margin-bottom: adjustPx(64px);

    @include f-body-2-2;
  }

  .buttons {
    display: flex;

    .button {
      height: adjustPx(96px);
      border-radius: adjustPx(24px);
      border: none;
      background: var(--color-bg-button);
      margin-right: adjustPx(18px);

      @include f-label-2;
    }

    .active {
      background: var(--color-notheme-bg-accent);
      color: var(--color-notheme-text-accent);
    }
  }
}
</style>
