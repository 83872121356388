<template>
  <section ref="el" :class="$style.wrapper">
    <CatalogFiltersContainer
      :class="$style.filters"
      :disabled="false"
      :filters="filters"
      @update:filters="onChangeFilters"
    />

    <div :class="$style.tabs">
      <ContentSelect
        :class="$style.toggle"
        :disabled="false"
        :content-type="contentType"
        :show-shuffle="false"
        @update:type="onChangeContent"
        @update:shuffle="onChangeShuffle"
      />

      <UIContent
        scroll-block="start"
        :on-load-chunk="onLoadCollectionChunk"
        :content-type="contentType"
        :items-per-row="itemsPerRow"
        :items-per-scroll="itemsPerScroll"
        :first-load-size="firstLoadSize"
        :force-update="shouldUpdateContent"
        :empty-header="$t('pages.catalog.empty')"
        :class="$style.content"
        :split-first-load="3"
        @activated="onActivatedContent"
        @select:moment="onSelectItem"
      />
    </div>
  </section>
</template>

<script>
import { MediaContentType } from '@package/sdk/src/api';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { catalogService, storeToRefs, useContentStore, useMediaContentActions } from '@SMART/index';
import { onMounted, provide, ref } from '@vue/composition-api';

import UIContent from '@/components/content/UIContent.vue';
import { useUiContent } from '@/components/content/useUiContent';

import CatalogFiltersContainer from './components/CatalogFiltersContainer.vue';
import CatalogShuffle from './components/CatalogShuffle.vue';
import ContentSelect from './components/ContentSelect.vue';

export default {
  components: {
    UIContent,
    CatalogFiltersContainer,
    CatalogShuffle,
    ContentSelect,
  },
  setup(props, { root: { $route: route } }) {
    const contentStore = useContentStore();
    const { genres } = storeToRefs(contentStore);

    const { el, focusKey, focusSelf } = useNavigatable({ focusKey: 'CATALOG_PAGE' });
    provide('parentFocusKey', focusKey.value);

    const { openContentPage } = useMediaContentActions();

    let contentLoaded = false;

    const shouldUpdateContent = ref(true);
    const filters = ref({});
    const showShuffle = ref(false);

    let isFilterSet = false;

    const setupFilter = () => {
      if (isFilterSet) {
        return;
      }

      const queryGenre = route.query.genre;

      filters.value = {
        ...filters.value,
        ...(queryGenre && {
          genres: genres.value.filter((genre) =>
            Array.isArray(queryGenre) ? queryGenre.includes(genre.slug) : genre.slug === queryGenre,
          ),
        }),
      };

      isFilterSet = true;
    };

    const onSelect = (content) => {
      openContentPage({
        title: content.title,
        contentType: content.contentType,
        id: content.id,
      });
    };

    const isContentChanging = () => {
      if (shouldUpdateContent.value) {
        contentLoaded = false;
        setupFilter();
      }

      return shouldUpdateContent.value;
    };

    const fetchItems = async (type, params) => {
      if (type === MediaContentType.MOVIE) {
        return await catalogService.fetchMovies({
          ...filters.value,
          ...params,
        });
      }

      if (type === MediaContentType.SERIAL) {
        return await catalogService.fetchSerials({
          ...filters.value,
          ...params,
        });
      }

      return [];
    };

    const { contentType, firstLoadSize, itemsPerRow, itemsPerScroll, isLoading, onLoadCollectionChunk, onSelectItem } =
      useUiContent({
        onSelect,
        isContentChanging,
        onLoaded: () => {
          shouldUpdateContent.value = false;
        },
        fetchItems,
      });

    onMounted(() => {
      contentType.value = MediaContentType.Movie;

      focusSelf();
    });

    const onChangeContent = (value) => {
      shouldUpdateContent.value = false;

      catalogService.abort('onChangeContent');
      contentType.value = value;

      window.setTimeout(async () => {
        shouldUpdateContent.value = true;
      }, 10);
    };

    const onChangeShuffle = (active) => {
      showShuffle.value = active;
      window.setTimeout(async () => {
        shouldUpdateContent.value = true;
      }, 20);
    };

    const onChangeFilters = (value) => {
      filters.value = value;
      shouldUpdateContent.value = false;
      catalogService.abort('onChangeFilters');

      window.setTimeout(() => {
        shouldUpdateContent.value = true;
      }, 10);
    };

    const onActivatedContent = async () => {
      if (contentLoaded) {
        return;
      }

      contentLoaded = true;
    };

    return {
      el,
      contentType,
      firstLoadSize,
      itemsPerRow,
      itemsPerScroll,
      isLoading,
      showShuffle,
      filters,
      genres,
      shouldUpdateContent,
      onLoadCollectionChunk,
      onSelectItem,
      onActivatedContent,
      onChangeFilters,
      onChangeShuffle,
      onChangeContent,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: row;
  margin-left: adjustPx(140px);
  padding-top: adjustPx(60px);
}

.tabs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filters {
  display: flex;
  flex-direction: column;
  width: adjustPx(443px);
  min-width: adjustPx(443px);
  height: calc(100vh - adjustPx(220px));
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  display: flex;
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
}
</style>
