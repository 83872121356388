<template>
  <aside>
    <NavigatableItem
      :tag="AppButton"
      :disabled="disabled"
      :class="[$style.filtersButton, $style.filtersToggle]"
      :active-class="$style.active"
      :text="$t('pages.catalog.filters')"
      :on-click="() => onShowAllFilters(!showAllFilters)"
    >
      <template v-slot:icon>
        <IconFilters :class="$style.icon" />
      </template>
    </NavigatableItem>

    <div :class="$style.filters">
      <ScrollViewport tag="ul" :y="offset" role="list">
        <li v-for="(period, index) in selectedPeriods" :key="period.id">
          <NavigatableItem
            :tag="AppButton"
            :class="$style.filtersButton"
            :active-class="$style.active"
            :text="period.title"
            :on-click="() => removePeriod(period)"
            @active="setActivePeriod(index)"
          >
            <template v-slot:icon>
              <IconClose :class="$style.filtersIcon" />
            </template>
          </NavigatableItem>
        </li>

        <li v-for="(genre, index) in selectedGenres" :key="genre.id">
          <NavigatableItem
            :tag="AppButton"
            :class="$style.filtersButton"
            :active-class="$style.active"
            :text="genre.title"
            :on-click="() => removeGenre(genre)"
            @active="setActiveGenre(index)"
          >
            <template v-slot:icon>
              <IconClose :class="$style.filtersIcon" />
            </template>
          </NavigatableItem>
        </li>

        <li v-for="(country, index) in selectedCountries" :key="country.code">
          <NavigatableItem
            :tag="AppButton"
            :class="$style.filtersButton"
            :active-class="$style.active"
            :text="country.title"
            :on-click="() => removeCountry(country)"
            @active="setActiveCountry(index)"
          >
            <template v-slot:icon>
              <IconClose :class="$style.filtersIcon" />
            </template>
          </NavigatableItem>
        </li>
      </ScrollViewport>
    </div>
    <CatalogFiltersModal
      v-if="showAllFilters"
      :filters="filters"
      @on-show-all-filters="onShowAllFilters"
      @update:filters="(val) => $emit('update:filters', val)"
    />
  </aside>
</template>

<script>
import IconClose from '@SMART/assets/icons/33x33/close.svg';
import IconFilters from '@SMART/assets/icons/40x40/filters.svg';
import { catalogService, storeToRefs, useContentStore } from '@SMART/index';
import { computed, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import UIModal from '@/components/modal/UIModal.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import CatalogFiltersModal from '@/pages/catalog/components/CatalogFiltersModal.vue';

import CatalogFilters from './CatalogFilters.vue';

export default {
  components: {
    CatalogFiltersModal,
    IconClose,
    IconFilters,
    UIModal,
    ScrollViewport,
    CatalogFilters,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const contentStore = useContentStore();
    const { genres, countries, periods } = storeToRefs(contentStore);
    const showAllFilters = ref(false);

    const filters = computed({
      get: () => props.filters,
      set: (value) => value && emit('update:filters', value),
    });

    const removeCountry = async (country) => {
      filters.value = {
        ...filters.value,
        countries: selectedCountries.value.filter((c) => c.code !== country.code),
      };
    };

    const removeGenre = async (genre) => {
      filters.value = {
        ...filters.value,
        genres: selectedGenres.value.filter((c) => c.id !== genre.id),
      };
    };

    const removePeriod = async (period) => {
      filters.value = {
        ...filters.value,
        periods: selectedPeriods.value.filter((c) => c.id !== period.id),
      };
    };

    const activePeriodIndex = ref(0);
    const activeGenreIndex = ref(0);
    const activeCountryIndex = ref(0);
    const ELEMENT_HEIGHT = (1280 / 1920) * 90;

    const selectedCountries = computed(() => props.filters.countries || []);
    const selectedGenres = computed(() => props.filters.genres || []);
    const selectedPeriods = computed(() => props.filters.periods || []);

    const setActivePeriod = (index) => {
      activePeriodIndex.value = index;
      activeGenreIndex.value = 0;
      activeCountryIndex.value = 0;
    };

    const setActiveGenre = (index) => {
      activeCountryIndex.value = index;
      activeGenreIndex.value = 0;
    };

    const setActiveCountry = (index) => {
      activeGenreIndex.value = index;
    };

    const offset = computed(
      () => (activePeriodIndex.value + activeGenreIndex.value + activeCountryIndex.value) * ELEMENT_HEIGHT,
    );

    const onShowAllFilters = async (value) => {
      showAllFilters.value = value;
    };

    return {
      AppButton,
      selectedCountries,
      selectedGenres,
      selectedPeriods,
      removeCountry,
      removePeriod,
      removeGenre,
      offset,
      setActiveCountry,
      setActiveGenre,
      onShowAllFilters,
      showAllFilters,
      setActivePeriod,
      contentStore,
      genres,
      countries,
      periods,
      catalogService,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/colors';

.active {
  background-color: var(--color-bg-accent);
}

.filters {
  display: flex;
  flex-flow: column;
  height: adjustPx(1034px);
  margin-top: adjustPx(16px);
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &Button {
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: adjustPx(380px);
    border-radius: adjustPx(32px);
    overflow: hidden;
    justify-content: flex-start;
    margin-bottom: adjustPx(8px);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &Toggle {
    flex-flow: row;
  }

  &Icon {
    width: adjustPx(33px);
    min-width: adjustPx(33px);
    height: adjustPx(33px);
    min-height: adjustPx(33px);
    margin-right: adjustPx(10px);
    margin-left: adjustPx(17px);
  }

  &Text {
    display: block;
    max-width: adjustPx(250px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include f-subtitle-2;
  }
}

.filters::-webkit-scrollbar {
  display: none;
}

.icon {
  width: adjustPx(40px);
  min-width: adjustPx(40px);
  height: adjustPx(40px);
  min-height: adjustPx(40px);
  margin-right: adjustPx(10px);
}

.filter {
  position: fixed;
  top: adjustPx(40px);
  right: 0;
  display: flex;
  width: adjustPx(567px);
  height: adjustPx(1034px);
  border-radius: adjustPx(41px);
  background: var(--color-stroke-field-border);
  opacity: 0.98;
  flex-flow: column;
}

.button {
  &Content {
    display: flex;
  }
}
</style>
