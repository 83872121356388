<template>
  <div v-if="cast" :class="$style.wrapper">{{ $t('pages.mediaCard.cast') }} {{ cast }}</div>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  props: {
    actors: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const cast = computed(() => {
      if (!props.actors?.length) {
        return '';
      }

      return props.actors
        .slice(0, 3)
        .map((actor) => actor.name)
        .join(', ');
    });

    return {
      cast,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/colors';

.wrapper {
  color: var(--color-text-secondary);
}
</style>
