<template>
  <section :class="$style.keyboard">
    <slot name="input" :type="inputType" :input="text" :placeholder="placeholder">
      <!-- eslint-disable vue/no-mutating-props -->
      <UIInput
        :class="{
          [$style.keyboardInput]: true,
          [$style.secondary]: inputVariant === 'secondary',
          [$style.password]: inputType === 'password' && text.length,
        }"
        :placeholder="placeholder"
        :type="inputType"
        :borderless="!text"
        :input-mask="inputMask"
        :input="text"
        disabled
        @update:input="input = $event"
      />
    </slot>
    <div :class="$style.suggestions">
      <template v-if="suggestions">
        <NavigatableItem
          v-for="suggestion in suggestions"
          :key="suggestion"
          variation="default"
          :tag="UIButton"
          :class="$style.suggestionsItem"
          :active-class="$style.active"
          :on-click="() => onAppend(suggestion)"
          @active="$emit('active')"
        >
          {{ suggestion }}
        </NavigatableItem>
      </template>
    </div>
    <transition name="fade">
      <div v-show="!isKeyboardHidden" :class="$style.keyboardLayout">
        <div :class="$style.symbols">
          <template v-if="isLetters">
            <NavigatableItem
              v-for="key in letterSymbols"
              :key="key"
              variation="default"
              :tag="UIButton"
              :class="$style.symbolsLetter"
              :active-class="$style.active"
              :on-click="() => onInput(key)"
              @active="$emit('active')"
            >
              {{ key }}
            </NavigatableItem>
          </template>
          <template v-else-if="isNumbersSchema">
            <NavigatableItem
              v-for="key in numbers"
              :key="key"
              variation="default"
              autofocus
              :tag="UIButton"
              :class="$style.symbolsNumber"
              :active-class="$style.active"
              :on-click="() => onInput(key)"
              @active="$emit('active')"
            >
              {{ key }}
            </NavigatableItem>
          </template>
          <template v-else-if="isSymbols">
            <NavigatableItem
              v-for="key in symbols"
              :key="key"
              variation="default"
              :tag="UIButton"
              :class="$style.symbolsLetter"
              :active-class="$style.active"
              :on-click="() => onInput(key)"
              @active="$emit('active')"
            >
              {{ key }}
            </NavigatableItem>
          </template>
          <template v-if="showUpper">
            <NavigatableItem
              variation="default"
              :tag="UIButton"
              :class="{
                [$style.symbolsUpper]: true,
                [$style.symbolsUpperActive]: transform === 'upper',
              }"
              :active-class="$style.active"
              :on-click="() => onUpperCase"
              @active="$emit('active')"
            >
              <IconArrowUpFilled :class="$style.controlArrowUp" />
            </NavigatableItem>
          </template>
        </div>
        <div :class="$style.control">
          <NavigatableItem
            v-if="showUppercase"
            variation="default"
            :tag="UIButton"
            :class="$style.controlKey"
            :active-class="$style.active"
            :on-click="() => onInput(' ')"
            @active="$emit('active')"
          >
            <IconSpace :class="$style.controlSpace" />
          </NavigatableItem>
          <NavigatableItem
            variation="default"
            :tag="UIButton"
            :class="$style.controlKey"
            :active-class="$style.active"
            :on-click="() => onBackspace()"
            @active="$emit('active')"
          >
            <IconBackspace :class="$style.controlBackspace" />
          </NavigatableItem>
          <NavigatableItem
            variation="default"
            :tag="UIButton"
            :class="$style.controlKey"
            :active-class="$style.active"
            :on-click="onClear"
            @active="$emit('active')"
          >
            {{ $t('clear') }}
          </NavigatableItem>
          <NavigatableItem
            v-if="canSwitchToNumber && !isControlHidden"
            variation="default"
            :tag="UIButton"
            :class="$style.controlKey"
            :active-class="$style.active"
            :on-click="() => onTypeChange('number')"
            @active="$emit('active')"
          >
            {{ $t('numbers') }}
          </NavigatableItem>
          <NavigatableItem
            v-if="canSwitchToLetters && !isControlHidden"
            variation="default"
            :tag="UIButton"
            :class="$style.controlKey"
            :active-class="$style.active"
            :on-click="() => onTypeChange('letter')"
            @active="$emit('active')"
          >
            {{ $t('letters') }}
          </NavigatableItem>
          <NavigatableItem
            v-if="canSwitchToSymbols && !isControlHidden"
            variation="default"
            :tag="UIButton"
            :active-class="$style.active"
            :class="$style.controlKey"
            :on-click="() => onTypeChange('symbols')"
            @active="$emit('active')"
          >
            {{ $t('symbols') }}
          </NavigatableItem>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import IconArrowUpFilled from '@SMART/assets/icons/33x33/arrow-up-filled.svg';
import IconBackspace from '@SMART/assets/icons/33x33/backspace.svg';
import IconSpace from '@SMART/assets/icons/33x33/space.svg';
import { computed, ref } from '@vue/composition-api';

import UIButton from '@/components/button/UIButton.vue';
import UIInput from '@/components/input/UIInput.vue';

export default {
  components: {
    IconArrowUpFilled,
    IconSpace,
    IconBackspace,
    UIInput,
  },
  props: {
    type: { type: String, default: 'letter' },
    lang: { type: String, default: 'en' },
    inputType: { type: String, default: 'text' },
    inputMask: { type: String, default: '' },
    isKeyboardHidden: { default: false },
    isControlHidden: { default: false },
    isActive: { default: false },
    placeholder: { type: String },
    input: { type: String },
    inputVariant: { type: String },
    maxLength: { type: Number },
    suggestions: { type: Array },
    onConcatSuggestion: { type: Function },
  },
  setup(props, { emit }) {
    const _lang = ref(props.lang);

    const transform = ref('');

    const text = computed({
      get: () => props.input,
      set: (value) => emit('update:input', value),
    });

    const _type = computed({
      get: () => props.type,
      set: (value) => emit('update:input', value),
    });

    const letterSymbols = computed(() => {
      const lettersMap = {
        ru: 'абвгдежзийклмнопрстуфхцчшщъыьэюя',
        en: 'abcdefghijklmnopqrstuvwxyz-._@',
      };
      let letters = lettersMap[_lang.value];

      if (transform.value === 'upper') {
        letters = letters.toUpperCase();
      }

      return letters.split('');
    });

    const isNumbersSchema = computed(() => _type.value === 'number');

    const isLetters = computed(() => _type.value === 'letter');

    const isSymbols = computed(() => _type.value === 'symbols');

    const showUpper = computed(() => _type.value === 'letter' && _lang.value === 'en');

    const showUppercase = computed(() => _type.value === 'letter' && _lang.value === 'ru');

    const canSwitchToNumber = computed(() => _type.value === 'symbols' || _type.value === 'letter');

    const canSwitchToLetters = computed(() => _type.value === 'symbols' || _type.value === 'number');

    const canSwitchToSymbols = computed(
      () => (_lang.value === 'en' && _type.value === 'letter') || _type.value === 'number',
    );

    const numbers = '1234567890'.split('');

    const symbols = '`~!@#$%^&*()-_=+[]{}\\|;:\'",.<>/?'.split('');

    const onTypeChange = (newType) => {
      emit('update:type', newType);
    };

    const onUpperCase = () => {
      if (!transform.value) {
        transform.value = 'upper';
      } else {
        transform.value = '';
      }
    };

    const onInput = (key) => {
      const value = props.input + key;

      emit('update:input', props.maxLength ? value.slice(0, props.maxLength) : value);
    };

    const onBackspace = () => {
      emit('update:input', props.input.slice(0, -1));
    };

    const onAppend = (value) => {
      const { onConcatSuggestion: concat } = props;

      const input = concat ? concat(props.input, value) : props.input + value;

      emit('update:input', input);
    };

    const onClear = () => {
      emit('clear', props.input);
      emit('update:input', '');
    };

    return {
      transform,
      text,
      letterSymbols,
      isNumbersSchema,
      isLetters,
      isSymbols,
      showUpper,
      showUppercase,
      canSwitchToNumber,
      canSwitchToLetters,
      canSwitchToSymbols,
      numbers,
      symbols,
      onTypeChange,
      onUpperCase,
      onInput,
      onBackspace,
      onAppend,
      onClear,
    };
  },
  data: () => ({ UIButton }),
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/colors';

@mixin item($width) {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: adjustPx(3px) !important;
  padding: adjustPx(5px) adjustPx(14px);
  min-width: adjustPx($width);
  max-width: adjustPx($width);
  min-height: adjustPx(68px);
  max-height: adjustPx(68px);
  border-radius: adjustPx(6px);
  border: adjustPx(3px) solid var(--color-bg-primary);
  background: var(--color-bg-primary);

  @include f-subtitle;
}

.keyboard {
  &Input {
    margin-bottom: adjustPx(26px) !important;
    width: adjustPx(830px);

    @include f-body;
  }

  .secondary {
    border-radius: adjustPx(10px);
    background: var(--color-stroke-field-border);
    padding-left: adjustPx(24px);
  }

  .password {
    letter-spacing: adjustPx(12px);
  }

  &Layout {
    display: flex;
    flex-flow: row;
    width: adjustPx(840px);
    height: adjustPx(290px);
  }
}

.symbols {
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  &Upper {
    @include item(145px);
  }

  &UpperActive {
    background-color: var(--color-bg-accent);
  }

  &Letter {
    @include item(69px);
  }

  &Number {
    @include item(194px);
  }

  &Number:last-child {
    @include item(597px);
  }

  .active,
  &Upper:not(:disabled):hover,
  &Upper:not(:disabled):active,
  &UpperActive:not(:disabled):hover,
  &UpperActive:not(:disabled):active,
  &Letter:not(:disabled):hover,
  &Letter:not(:disabled):active,
  &Number:not(:disabled):hover,
  &Number:not(:disabled):active {
    background-color: var(--color-bg-accent);
  }
}

.control {
  width: 222px;
  height: adjustPx(68px);

  &Key {
    @include item(222px); //ниже margin перебивается

    margin: adjustPx(6px) !important;
  }

  &Key:first-child {
    margin-top: adjustPx(3px) !important;
  }

  &Space {
    height: adjustPx(33px);
  }

  &Backspace {
    height: adjustPx(58px);
  }

  &ArrowUp {
    height: adjustPx(53px);
  }

  .active,
  &Key:not(:disabled):hover,
  &Key:not(:disabled):active,
  &Space:not(:disabled):hover,
  &Space:not(:disabled):active,
  &Backspace:not(:disabled):hover,
  &Backspace:not(:disabled):active,
  &ArrowUp:not(:disabled):hover,
  &ArrowUp:not(:disabled):active {
    background-color: var(--color-bg-accent);
  }
}

.suggestions {
  display: flex;

  &Item {
    @include item(222px);
  }

  &Item:last-child {
    @include item(145px);
  }

  .active,
  &Item:not(:disabled):hover,
  &Item:not(:disabled):active {
    background-color: var(--color-bg-accent);
  }
}
</style>
