export const onResetIdle = (listener: () => void) => {
  document.removeEventListener('scroll', listener, true);
  document.removeEventListener('keypress', listener, true);
  document.removeEventListener('wheel', listener, true);
  document.removeEventListener('mousemove', listener, true);
};

export const onIdleOnce = (callback: () => void, timeoutMs: number) => {
  let timer = window.setTimeout(execute, timeoutMs);

  function execute() {
    onResetIdle(resetTimer);
    timer = 0;
    callback();
  }

  function resetTimer() {
    if (timer) {
      window.clearTimeout(timer);
      timer = window.setTimeout(execute, timeoutMs);
    }
  }

  document.addEventListener('scroll', resetTimer, true);
  document.addEventListener('keydown', resetTimer, true);
  document.addEventListener('wheel', resetTimer, true);
  document.addEventListener('mousemove', resetTimer, true);

  return resetTimer;
};
