<template>
  <section :class="$style.filter">
    <NavigatableItem
      :tag="AppButton"
      :class="$style.filterNavItem"
      autofocus
      :active-class="$style.active"
      :text="$t('pages.catalog.filters')"
      :on-click="() => $emit('hide')"
      :prop-parent-focus-key="parentFocusKey"
    >
      <template v-slot:icon>
        <IconArrowLeft :class="$style.filterIcon" />
      </template>
    </NavigatableItem>

    <NavigatableItem
      :tag="AppButton"
      :class="$style.filterFilterItem"
      :active-class="$style.active"
      :text="$t('pages.catalog.periods')"
      :on-click="() => $emit('period')"
      :prop-parent-focus-key="parentFocusKey"
      @keyup.right="$emit('period')"
    >
      <template v-slot:icon>
        <IconArrowRight :class="$style.filterIcon" />
      </template>
    </NavigatableItem>

    <NavigatableItem
      :tag="AppButton"
      :class="$style.filterFilterItem"
      :active-class="$style.active"
      :text="$t('pages.catalog.genres')"
      :on-click="() => $emit('genre')"
      :prop-parent-focus-key="parentFocusKey"
      @keyup.right="$emit('genre')"
    >
      <template v-slot:icon>
        <IconArrowRight :class="$style.filterIcon" />
      </template>
    </NavigatableItem>

    <NavigatableItem
      :tag="AppButton"
      :class="$style.filterFilterItem"
      :active-class="$style.active"
      :text="$t('pages.catalog.country')"
      :on-click="() => $emit('country')"
      :prop-parent-focus-key="parentFocusKey"
      @keyup.right="$emit('country')"
    >
      <template v-slot:icon>
        <IconArrowRight :class="$style.filterIcon" />
      </template>
    </NavigatableItem>

    <NavigatableItem
      :tag="AppButton"
      :class="$style.filterClearButton"
      :active-class="$style.active"
      :text="$t('pages.catalog.clearFilters')"
      :on-click="() => $emit('clear')"
      :prop-parent-focus-key="parentFocusKey"
      @keyup.right="$emit('clear')"
    />
  </section>
</template>

<script>
import IconArrowLeft from '@SMART/assets/icons/51x51/arrow-left.svg';
import IconArrowRight from '@SMART/assets/icons/51x51/arrow-right.svg';

import AppButton from '@/components/app-button/AppButton.vue';

export default {
  props: {
    parentFocusKey: {
      type: String,
      required: false,
    },
  },
  components: {
    IconArrowLeft,
    IconArrowRight,
  },
  setup(props, { emit }) {
    return {
      AppButton,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/colors';

.filter {
  position: fixed;
  top: adjustPx(40px);
  bottom: adjustPx(40px);
  right: adjustPx(40px);
  display: grid;
  width: adjustPx(567px);
  border-radius: adjustPx(41px);
  background: var(--color-stroke-field-border);
  opacity: 0.98;
  grid-row-gap: adjustPx(16px);
  grid-column-gap: adjustPx(16px);
  grid-template-rows: repeat(9, 1fr);
  grid-template-columns: repeat(auto-fill, 1fr);

  &NavItem {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 adjustPx(16px);
    margin-top: adjustPx(16px);
    padding: adjustPx(55px) adjustPx(31px);
    width: adjustPx(540px);
    height: adjustPx(141px);
    min-height: adjustPx(141px);
    border-radius: adjustPx(24px);
    border-color: transparent;
    background-color: transparent;

    @include f-headline-2;
  }

  &FilterItem {
    display: flex;
    align-items: center;
    margin: 0 adjustPx(16px);
    width: adjustPx(540px);
    height: adjustPx(86px);
    border-radius: adjustPx(24px);
    border-color: transparent;
    background-color: transparent;
    justify-content: space-between;

    @include f-headline-2;
  }

  &ClearButton {
    position: absolute;
    bottom: adjustPx(57px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 adjustPx(16px);
    height: adjustPx(86px);
    border-radius: adjustPx(24px);
    border-color: transparent;
    background-color: transparent;

    @include f-headline-2;
  }

  &Icon {
    margin-bottom: adjustPx(6px);
    width: adjustPx(40px);
    height: adjustPx(40px);
  }

  &Title {
    @include f-headline-2;
  }

  &Text {
    @include f-subtitle;
  }

  .active {
    background-color: var(--color-bg-accent);
  }
}
</style>
