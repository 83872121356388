import { AppLanguage, AppVariation } from './../variation/variation';
import type { Doc } from './index';

export const privacyPolicyDoc: Doc = {
  [AppVariation.Ru]: {
    [AppLanguage.RU]: {
      title: 'Политика конфиденциальности персональной информации',
      texts: [
        'Настоящая Политика конфиденциальности персональной информации («Политика») дополняет условия <a href="/user-agreement/" style="color: #00C7B2" target="_blank">Пользовательского соглашения</a>, размещённого на Сервисе, и действует в отношении информации, которую Пользователь передает Компании в ходе использования Сервиса, а также при обращении к службе технической поддержки Сервиса.',
        'Все термины и определения, указанные с заглавной буквы в настоящем тексте, следует трактовать в значении, указанном в Пользовательском соглашении и в настоящей Политике.',
        '<b>Использование Сервиса означает безоговорочное согласие Пользователя с настоящей Политикой; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сервиса.</b>',
      ],
      points: [
        'Использование Сервиса не требует предоставления персональных данных (таких как: ФИО полностью, дата рождения, адрес проживания, электронная почта, содержащая в своем адресе персональные данные, и т.п.) в связи с чем при использовании Сервиса Пользователь обязуется не вводить никаких своих персональных данных. Однако в случае, если по какой-то причине Пользователь использует свои персональные данные для регистрации и/или авторизации и они были приняты Компанией, считается, что тем самым Пользователь предоставил Компании свое согласие на сбор, обработку и хранение своих персональных данных (включая право осуществить их передачу третьим лицам в соответствии с условиями настоящей Политики и Пользовательским соглашением).',
        'Настоящая Политика применима только к информации, обрабатываемой в ходе использования Пользователем Сервиса. Компания не контролирует и не несет ответственность за обработку информации платежными системами и аффилированными лицами, осуществляющими техническую реализацию и поддержку Сервиса.',
        'Пользователь согласен и признает, что в случае предоставления своих персональных данных, он предоставляет Компании свое согласие на их обработку. Указанное согласие действует до момента получения отзыва согласия Компанией.',
        `Цели сбора и условия обработки информации, полученной от Пользователей:<br>
      &mdash; Идентификация Пользователей в целях заключения и исполнения Пользовательского соглашения;<br>
      &mdash; Обработки запросов Пользователей, обращающихся за технической поддержкой Компании по электронному адресу <a href="mailto:help@viju.ru" target="_blank" style="color: #00c7b2">help@viju.ru</a>;<br>
      &mdash; В отдельных случаях контактная информация, предоставленная Пользователем (имя, компания, телефон, адрес электронной почты), может быть использована посредством направления сообщений в WhatsApp, коротких смс-сообщений или электронных писем для осуществления информационных сообщений (например, для восстановления доступа к Сервису) и информационных рассылок, для приглашения к участию в конкурсах, опросах, направленных на повышение качества работы Сервиса, а также для получения иной информации, в том числе рекламного характера.<br>
      &mdash; Осуществления возврата денежных средств на расчетный счет банка Пользователя, в случае необходимости;<br>
      &mdash; Анализа и исследования предпочтений Пользователя при использовании Сервиса для целей улучшения работы Сервиса;<br>
      &mdash; Проведение Компанией статистических или маркетинговых мероприятий на основе обезличенных данных;<br>
      &mdash; Улучшения качества и удобства использования Сервиса, повышение его эффективности.
    `,
        'Полученная информация от Пользователя обрабатывается в соответствии с положениями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», другими законодательными и нормативными правовыми актами, определяющими порядок работы с персональными данными и требования к обеспечению их безопасности.',
        'Компания не собирает намеренно данные лиц, не достигших 18 лет.',
        'Компания не собирает и не хранит данные о кредитных картах и другие детали оплаты.',
        'Компания собирает и хранит только те данные, которые были предоставлены Пользователем при авторизации (регистрации) и необходимы исключительно для предоставления доступа к Сервису (исполнения Пользовательского соглашения), а именно: контактный номер телефона Пользователя или адрес электронной почты, не содержащий персональные данные Пользователя.',
        'Пользователь вправе отказаться от получения информационной и рекламной информации от Компании в отношении Сервиса, посредством самостоятельного отключения в параметрах электронного письма с рассылкой функции получения рассылок посредством перехода по специальной ссылке, указанной в письме, или путем направления электронного письма на адрес электронной почты <a href="mailto:help@viju.ru" target="_blank" style="color: #00c7b2">help@viju.ru.</a>',
        'Компания принимает все необходимые и достаточные организационные и технические меры для защиты полученной информации от Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.',
        `Компания может также собирать и обрабатывать следующую информацию о Пользователе, а также о лице, не являющимся авторизированным пользователем (пользователь Сервиса, не прошедший регистрацию/не оформивший Подписку): электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, веб-маяки/пиксельные теги, данные об Интернет-браузере, информацию об аппаратном и программном обеспечении, данные сети wi-fi), техническая информация об устройствах Пользователя, с помощью которых осуществляется доступ к Сервису, информация об их местонахождении, а также информацию о дате и времени осуществления доступа к Сервису.<br>
      При этом, Пользователь понимает, что данная информация не нужна Компании для целей установления личности Пользователя, а служит только технической информацией для обеспечения доступа к Сервису Пользователю в соответствии с условиями Пользовательского соглашения, а также имеющимися соглашениями с третьими лицами для обеспечения технической работоспособности Сервиса.`,
        `Компания использует файлы cookie (файлы с данными о прошлых посещениях Сервиса) для персонализации Сервиса и удобства Пользователя. Пользователь может сохранить сохранение файлов cookie в настройках своего браузера.<br>
      Пользователь настоящим дает свое согласие на сбор, анализ, и использование файлов cookie в целях контроля использования Сервиса, формирования статистики, сохранения предпочтений и другой информации на устройстве Пользователя, а также для отображения содержания в ходе последующих посещений Пользователем Сервиса.`,
        `Изменение Политики конфиденциальности.<br>
      Компания оставляет за собой право вносить изменения в настоящую Политику конфиденциальности. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.`,
      ],
      edition: 'Редакция от 29 сентября 2023 г.',
    },
    [AppLanguage.EN]: null,
    [AppLanguage.AM]: null,
  },
  [AppVariation.Am]: {
    [AppLanguage.EN]: {
      title: 'Privacy Policy of Personal Information',
      texts: [
        'This Privacy Policy of Personal Information ("Policy") supplements the terms of the <a href="https://viju.am/user-agreement/" style="color: #00c7b2" target="_blank">User Agreement</a> posted on the Service and applies to information that the User provides to the Company in the course of using the Service, as well as when contacting the technical support department of the Service.',
        'All capitalized terms and definitions in this text shall be interpreted within the meaning set forth in the User Agreement and this Policy.',
        '<b>Service Use shall mean that the User unconditionally agrees with this Policy; in case of disagreement with these terms, the User should refrain from using the Service.</b>',
      ],
      points: [
        'The use of the Service shall not require provision of personal data (such as: full name, date of birth, residential address, email containing personal data in its address, etc.) and therefore, when using the Service, the User undertakes not to enter any of his or her personal data. However, if for any reason, the User provides his or her personal data and it is accepted by the Company, it shall be deemed that by doing so the User has given the Company his or her consent to process his or her personal data (including the right to transfer it to third parties in accordance with the terms of this Policy and the User Agreement).',
        'This Policy applies only to information processed in the course of the Service use. The Company does not control and shall not be liable for processing of information by payment systems and affiliated persons providing technical implementation and support of the Service.',
        'The User agrees and acknowledges that in case of providing his or her personal data, he or she provides the Company with his or her consent to process it. This consent shall be valid until the Company receives the consent withdrawal.',
        `Purposes of collection and conditions for processing of information received from the Users:<br>
      &mdash; Identification of the Users for the purposes of entry into and execution of the User Agreement.<br>
      &mdash; Processing requests from the Users applying for technical support from the Company via email address <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a>.<br>
      &mdash; In some cases, the contact information provided by the User (name, company, phone number, e-mail address) may be used by sending WhatsApp messages, short sms messages or e-mails to carry out informational messages (for example, to restore access to the Service) and newsletters, to invite to participate in contests, surveys aimed at improving the Service quality, as well as to receive other information, including advertising.<br>
      &mdash; Making refunds to the User's bank account, if necessary.<br>
      &mdash; Analyze and research the User's preferences when using the Service for the purpose of its improvement.<br>
      &mdash; The Company holds statistical or marketing activities based on anonymized data.<br>
      &mdash; Improving the quality and usability of the Service, increasing its efficiency.
    `,
        'The Company shall not knowingly collect data of minors under 18.',
        'The Company shall not collect or store credit card data or other payment details.',
        'The Company shall collect and store only those data that were provided by the User during authorization (registration) and are necessary solely to provide access to the Service (execution of the User Agreement), namely: the User&#700;s contact phone number or e-mail address that does not contain personal data of the User.',
        'The User shall be entitled to refuse to receive information and advertising information from the Company in relation to the Service by independently disabling the function of receiving newsletters by clicking on a special link specified in the email or by sending an email to the following address <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a>.',
        'The Company shall take all necessary and sufficient organizational and technical measures to protect the information received from the User from illegal or accidental access, destruction, modification, blocking, copying, distribution, as well as from other illegal actions by third parties.',
        `The Company may also collect and process the following information about the User, as well as about a person who is not an authorized user (a Service user, who has not registered/subscribed): electronic data (HTTP headers, IP address, cookies, web beacons/pixel tags, Internet browser data, hardware and software information, wi-fi network data), technical information about the User's devices to access the Service, information about their location, as well as information about the date and time of access to the Service.<br>
      At the same time, the User understands that the Company does not need this information for the purposes of identifying the User, and it serves only as technical information to provide the User with access to the Service in accordance with the terms of the User Agreement, as well as existing agreements with third parties to ensure the technical operability of the Service.
    `,
        `The Company shall use cookies (files with data on previous visits to the Service) for personalization of the Service and convenience of the User. The User can save cookies in the settings of his or her browser.<br>
      The User hereby consents to collection, analysis and use of cookies in order to monitor the Service use, generate statistics, store preferences and other information on the User's device, as well as to display content during subsequent visits to the Service.
    `,
        `Modification of Confidentiality Policy.<br>
      The Company reserves the right to make changes to this Confidentiality Policy. The new version of the Policy shall become effective from the moment of its posting, unless otherwise provided by the new revision of the Policy.
    `,
      ],
      edition: 'Revision dated September 29, 2023',
    },
    [AppLanguage.RU]: {
      title: 'Политика конфиденциальности персональной информации',
      texts: [
        'Настоящая Политика конфиденциальности персональной информации («Политика») дополняет условия <a href="/user-agreement/" style="color: #00C7B2" target="_blank">Пользовательского соглашения</a>, размещённого на Сервисе, и действует в отношении информации, которую Пользователь передает Компании в ходе использования Сервиса, а также при обращении к службе технической поддержки Сервиса.',
        'Настоящая Политика применима только к информации, обрабатываемой в ходе использования Сервиса. Компания не контролирует и не несет ответственность за обработку информации платежными системами и аффилированными лицами, осуществляющими техническую реализацию и поддержку Сервиса.',
      ],
      points: [
        '<b>Термины</b>',
        '<b>Обработка персональных данных</b> — независимо от формы и способа реализации (в том числе с применением или без применения каких-либо автоматизированных, технических мер) любое действие (операция) или совокупность действий (операций) включая сбор, фиксацию, ввод, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных или выполнением других действий.',
        'Все другие термины и определения, указанные с заглавной буквы в настоящем тексте, следует трактовать в значении, указанном в Пользовательском соглашении и в настоящей Политике.',
        '<b>Использование Сервиса означает безоговорочное согласие Пользователя с настоящей Политикой; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сервиса.</b>',
        '<b>Пользователь настоящим подтверждает, что:</b>',
        '1. Все указанные им данные, указанные при Регистрации на Сервисе, принадлежат лично ему, соответствуют действительности, не искажены.<br>',
        '2. Им внимательно и в полном объеме прочитана настоящая Политика и условия обработки его персональных данных. Текст Политики и условия обработки персональных данных ему понятны, и он с ними согласен.',
        '3. Выражает согласие с условиями обработки персональных данных без оговорок и ограничений.',
        '4. В соответствии с законом РА «О защите персональных данных» свободно, своей волей и в своем интересе выражает согласие на Обработку следующих персональных данных: персональная информация, предоставленная при авторизации (регистрации), а именно адрес электронной почты и телефон, при и для акцепта оферты, электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, веб-маяки/пиксельные теги, данные об Интернет-браузере, информацию об аппаратном и программном обеспечении, данные сети wi-fi), информация о геолокации, информация о персональных предпочтениях Пользователя, техническая информация об устройствах Пользователя, с помощью которых осуществляется доступ к Сервису, информация об их местонахождении, а также информацию о дате и времени осуществления доступа к Сервису.',
        '5. Дает согласие на Обработку персональных данных в следующих целях:',
        '5.1 Идентификация Пользователей в целях заключения и исполнения Пользовательского соглашения;',
        '5.2 Обработки запросов Пользователей, обращающихся за технической поддержкой Компании по электронному адресу help@viju.am;',
        '5.3 В отдельных случаях контактная информация, предоставленная Пользователем (имя, телефон, адрес электронной почты), может быть использована посредством направления сообщений в WhatsApp, коротких смс-сообщений или электронных писем для осуществления информационных сообщений (например, для восстановления доступа к Сервису) и информационных рассылок, для приглашения к участию в конкурсах, опросах, направленных на повышение качества Сервиса, а также для получения иной информации, в том числе рекламного характера. Порядок и условия рассылок установлены Правилами рассылок.',
        '5.4 Осуществления возврата денежных средств на расчетный счет банка Пользователя, в случае необходимости;',
        '5.5 Анализа и исследования предпочтений Пользователя при использовании Сервиса для целей его улучшения;',
        '5.6 Проведение Компанией статистических или маркетинговых мероприятий на основе обезличенных данных;',
        '5.7 Улучшения качества и удобства использования Сервиса, повышение его эффективности.',
        '6. Понимает, что Компания использует файлы cookie (файлы с данными о прошлых посещениях Сервиса) для персонализации Сервиса и удобства Пользователя. Пользователь может сохранить файлы cookie в настройках своего браузера, и дает свое согласие на сбор, анализ, и использование файлов cookie в целях контроля использования Сервиса, формирования статистики, сохранения предпочтений и другой информации на устройстве Пользователя, а также для отображения содержания в ходе последующих посещений Пользователем Сервиса.',
        '7. Понимает, что информация, предоставленная Компании, не нужна Компании только для целей установления личности Пользователя, а также служит технической информацией для обеспечения доступа к Сервису Пользователю в соответствии с условиями Пользовательского соглашения, а также имеющимися соглашениями с третьими лицами для обеспечения технической работоспособности Сервиса. ',
        '<b>Кому может быть передана персональная информация</b>',
        'Компания может передать персональную информацию также третьим лицам:',
        '1. Общество с ограниченной ответственностью «КЛАУДПЭЙМЕНТС» (ОГРН 1147746077159, адрес местонахождения: 115162, г. Москва, вн.тер.г. Муниципальный округ Донской, ул. Шухова, д. 14, стр. 11, эт. 3, помещ. 67) – для технического обеспечения осуществления оплаты за использование Сервиса,',
        '2. Общество с ограниченной ответственностью «Майндбокс» (ОГРН 1097746380380, адрес местонахождения: 125124, г. Москва, ул. Правды, д. 26, эт/пом 12/ХХХ) – для целей осуществления функции рассылок и push-уведомлений в адрес Пользователей,',
        '3. Общество с ограниченной ответственностью «Яндекс.Облако» (ОГРН 1187746678580, адрес местонахождения: 119021, г. Москва, ул. Льва Толстого, д. 16, помещ. 528) – для целей хранения данных, в том числе Пользовательских номеров телефонов и/или email адресов,',
        '4. Общества с ограниченной ответственностью «ЯНДЕКС» (ОГРН 1027700229193, адрес местонахождения: 119021, г. Москва, ул. Льва Толстого, д.16) – для аналитических целей через сервисы Яндекс.Метрика и AppMetrica на Сервисе.',
        'При этом, указанный список в любой момент может быть дополнен со стороны Компании в случае необходимости.',
        'Во всех случаях Обработка персональной информации будет осуществляться только для целей, указанных в пунктах 5 и 6 настоящей Политики и настоящего раздела.',
        '<b>Иные условия Обработки персональных данных</b>',
        '1. Компания не собирает намеренно данные лиц, не достигших 18 лет.',
        '2. Компания не собирает и не хранит данные о кредитных картах и другие детали оплаты.',
        '3. Пользователь вправе отказаться от получения информационной и рекламной информации от Компании в отношении Сервиса, посредством самостоятельного отключения в параметрах электронного письма с рассылкой функции получения рассылок посредством перехода по специальной ссылке, указанной в письме, или путем направления электронного письма на адрес электронной почты  <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a>',
        '4. Компания принимает все необходимые и достаточные организационные и технические меры для защиты полученной информации от Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.',
        '5. Согласие Пользователя на Обработку персональных данных является конкретным, информированным и сознательным и действует до момента получения отзыва согласия Компанией.',
        '6. Пользователь имеет право в любое время потребовать исправление, уничтожение, или выполнении других действий, связанных с Обработкой персональных данных путем отправки Пользователем сообщения на адрес электронной почты  <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a> с соответствующей пометкой.',
        '7. Отзыв согласия на обработку персональных данных может быть осуществлен путем отправки Пользователем сообщения на адрес электронной почты  <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a>  с пометкой «Отзыв согласия на обработку персональных данных».',
        '8. Пользователь понимает, что отзыв согласие на обработку персональных данных означает отказ Пользователя от использования Сервиса, и влечет за собой прекращение доступа к Сервису.',

        '<b>Изменение Политики конфиденциальности</b>',
        'Компания оставляет за собой право вносить изменения в настоящую Политику конфиденциальности, о чем Компания уведомляет Пользователя до размещения. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.',
      ],
      edition: 'Редакция от «07» августа 2024 г.',
    },
    [AppLanguage.AM]: {
      title: 'Գաղտնիության քաղաքականությանը',
      texts: [
        'Սույն Անձնական տեղեկատվության Գաղտնիության քաղաքականությունը («Քաղաքականություն») լրացնում է Սերվիսում տեղադրված Օգտատիրական Համաձայնագրի պայմանները և գործում է այն տեղեկատվության նկատմամբ, որն Օգտատերը փոխանցում է Ընկերությանը Սերվիսից օգտվելիս, ինչպես նաև ԾառայությանՍերվիսի տեխնիկական աջակցության ծառայությանը դիմելիս:',
        'Սույն Քաղաքականությունը կիրառելի է միայն Սերվիսի օգտագործման ընթացքում մշակված տեղեկատվության նկատմամբ: Ընկերությունը չի վերահսկում և պատասխանատվություն չի կրում  իրականացնող վճարային համակարգերի և փոխկապակցված անձանց կողմից տեղեկատվության մշակման համար:',
      ],
      points: [
        '<b>Տերմիններ</b>',
        '<b>Անձնական տվյալների մշակում</b> - անկախ իրականացման ձևից և եղանակից (ներառյալ որևէնց ավտոմատացված, տեխնիկական միջոցների կիրառմամբ կամ առանց կիրառման) ցանկացած գործողություն (գործառնություն) կամ գործողությունների (գործառնությունների) միագումար, ներառյալ անձնական տվյալների հավաքագրումը, հաստատումը, մուտքագրումը, գրանցումը, համակարգումը, կուտակումը, պահպանումը, ճշգրտումը (թարմացումը, փոփոխությունը), արդյունահանումը, օգտագործումը, փոխանցումը (բաշխումը, տրամադրումը, մուտքը), ապաանձնավորումը, արգելափակումը, հեռացումը, ոչնչացումը կամ այլ գործողություններ կատարելը:',
        'Սույն տեքստում մյուս բոլոր մեծատառով գրված տերմիններն ու սահմանումները պետք է մեկնաբանվեն Օգտատիրական համաձայնագրում և սույն Քաղաքականությունում նշված իմաստով:',
        '<b>Սերվիսի օգտագործում նշանակում է Օգտատիրոջ անվերապահ համաձայնությունը սույն քաղաքականությանը.: Սույն պայմանների հետ անհամաձայնության դեպքում Օգտատերը պետք է ձեռնպահ մնա Սերվիսից օգտվելուց: </b>',
        '<b>Օգտատերը սույնով հաստատում է, որ՝</b>',
        '1. Սերվիսում Գրանցվելիս իր կողմից նշված բոլոր տվյալները պատկանում են անձամբ իրեն, համապատասխանում են իրականությանը և խեղաթյուրված չեն:',
        '2. Իր կողմից ուշադիր և ամբողջ ծավալով ընփերցվել է սույն Քաղաքականությունը և իր անձնական տվյալների մշակման պայմանները: Քաղաքականության տեքստը և անձնական տվյալների մշակման պայմանները իր համար պարզ են, և ինքը համաձայն է դրանց հետ:',
        '3. Համաձայնություն է հայտնում անձնական տվյալների մշակման պայմանների հետ առանց վերապահումների և սահմանափակումների ։',
        '4.  «Անձնական տվյալների պաշտպանության մասին» ՀՀ օրենքին համապատասխան, ազատորեն, իր կամքով և իր շահերից ելնելով, իր համաձայնությունն է հայտնում հետևյալ անձնական տվյալների Մշակմանը՝ ավտորիզացման (գրանցման) ժամանակ տրամադրված անձնական տվյալներ, մասնավորապես էլեկտրոնային փոստի հասցեն և հեռախոսահամարը, օֆերտայի ակցեպտի ժամանակ  և համար, էլեկտրոնային տվյալներ (HTTP վերնագրեր, IP հասցե, քուքի ֆայլեր, վեբ փարոսներ/պիքսելային պիտակներ, տվյալներ ինտերնետ բրաուզերի մասին, տեղեկատվություն ապարատային և ծրագրային ապահովման մասին, wi-fi ցանցի տվյալներ), տեղեկատվություն գեոլոկացիայի մասին, տեղեկատվություն Օգտատիրոջ անձնական նախապատվությունների մասին, տեխնիկական տեղեկատվություն Օգտատիրոջ այն սարքերի մասին, որոնց միջոցով իրականացվում է Սերվիսի մուտքը, տեղեկատվություն նրանց գտնվելու վայրի մասին, ինչպես նաև տեղեկատվություն Սերվիսի մուտքի իրականացման ամսաթվի և ժամի մասին:',
        '5. Համաձայնություն է տալիս անձնական տվյալների Մշակմանը հետևյալ նպատակներով՝',
        '5.1 Օգտատերերի նույնականացում՝ Օգտատիրական Համաձայնագրի կնքման և կատարման նպատակով,</br>',
        '5.2 Ընկերության տեխնիկական աջակցությունը help@viju.am էլեկտրոնային հասցեով դիմող Օգտատերերի հարցումների Մշակման,</br>',
        '5.3 Առանձին դեպքերում Օգտատիրոջ կողմից տրամադրված կոնտակտային  տեղեկատվությունը (անունը, հեռախոսը, էլեկտրոնային փոստի հասցեն)',
        'կարող է օգտագործվել WhatsApp հաղորդագրությունների, կարճ SMS հաղորդագրությունների կամ տեղեկատվական հաղորդագրությունների ',
        'իրականացման համար էլեկտրոնային նամակների (օրինակ Սերվիսի մուտքը վերականգնելու համար) և Սերվիսի որակի բարձրացմանն ուղղված',
        'մրցույթներին, հարցումներին մասնակցելու հրավերքի համար, ինչպես նաև այլ տեղեկատվություն ստանալու համար, այդ թվում՝ գովազդային',
        'բնույթի, տեղեկատվական առաքուների միջոցով։ Առաքումների կարգը և պայմանները սահմանվում են Առաքումների կանոններով,',
        '5.4 Անհրաժեշտության դեպքում Օգտատիրոջ բանկի հաշվարկային հաշվին դրամական միջոցների վերադարձի իրականացման,</br>',
        '5.5 Սերվիսից օգտվելիս Օգտատիրոջ նախասիրությունների վերլուծության և հետազոտության այն բարելավելու նպատակներով,</br>',
        '5.6 Չանձնավորված տվյալների հիման վրա Ընկերության կողմից վիճակագրական կամ մարքեթինգային միջոցառումների անցկացման,</br>',
        '5.7 Սերվիսի որակի և օգտագործման հարմարավետության բարելավման, դրա արդյունավետության բարձրացման նպատակներով:</br>',
        '6. Հասկանում է, որ Ընկերությունն օգտագործում է քուքի ֆայլերը (Սերվիսի նախկին այցելությունների մասին տվյալներով ֆայլեր) Սերվիսի անհատականացման և Օգտատիրոջ հարմարավետության համար: Օգտատերը կարող է պահպանել քուքի ֆայլերը իր բրաուզերի կարգավորումներում և իր համաձայնությունն է տալիս քուքի ֆայլերի հավաքմանը, վերլուծությանը և օգտագործմանը՝ Սերվիսի օգտագործումը վերահսկելու, վիճակագրություն ձևավորելու, նախապատվությունները և այլ տեղեկություններ Օգտատիրոջ սարքի վրա պահպանելու նպատակներով, ինչպես նաև Օգտատիրոջ կողմից Սերվիսի հետագա այցելությունների ժամանակ բովանդակությունը արտացոլելու համար:',
        '7. Հասկանում է, որ Ընկերությանը տրամադրված տեղեկատվությունը Ընկերությանը պետք չէ միայն Օգտատիրոջ անձը պարզելու նպատակներով, ինչպես նաև ծառայում է որպես տեխնիկական տեղեկատվություն Օգտատիրոջը Սերվիսի մուտք ապահովելու համար՝ Օգտատիրական Համաձայնագրի պայմաններին, ինչպես նաև Սերվիսի տեխնիկական գործունակությունն ապահովելու համար երրորդ անձանց հետ գոյություն ունեցող համաձայնագրերին համապատասխան:',

        '<b>Ում կարող է փոխանցվել անձնական տեղեկատվությունը</b>',
        'Ընկերությունը կարող է անձնական տվյալները փոխանցել նաև երրորդ անձանց՝',
        '1. «ՔԼԱՈՒԴՓԵՅՄԵՆԹՍ» սահմանափակ պատասխանատվությամբ ընկերություն (ՀՊԳՀ՝ 1147746077159, գտնվելու վայրի հասցեն՝ 115162, ք. Մոսկվա, ներ. տար. քաղ. Դոնսկոյ մունիցիպալ օկրուգ, Շուխովի փող., շենք 14, շին. 11, հարկ 3, տար. 67) – Սերվիսի օգտագործման դիմաց վճարման իրականացման տեխնիկական ապահովման համար,',
        '2. «Մայնդբոքս» սահմանափակ պատասխանատվությամբ ընկերություն (ՀՊԳՀ՝ 1097746380380, գտնվելու վայրի հասցեն՝ 125124, ք. Մոսկվա, Պրավդի փող., շենք 26, հարկ/տար 12/ХХХ) - Օգտատերերի հասցեներին առաքումների և push ծանուցումների գործառույթն իրականացնելու նպատակով,',
        '3. «Յանդեքս Օբլակո» սահմանափակ պատասխանատվությամբ ընկերություն (ՀՊԳՀ՝  1187746678580, գտնվելու վայրի հասցեն՝ 119021, ք. Մոսկվա, Լև Տոլստոյի փող., շ. 16, տար. 528) - տվյալների, այդ թվում Օգտատիրական հեռախոսահամարների և/կամ email հասցեների պահման նպատակներով,',
        '4 «ՅԱՆԴԵՔՍ» սահմանափակ պատասխանատվությամբ ընկերություն (ՀՊԳՀ՝ 1027700229193, գտնվելու վայրի հասցեն՝ 119021, ք. Մոսկվա, Լև Տոլստոյի փող., շ. 16) - Սերվիսում Յանդեքս Մետրիկա և AppMetrica ծառայությունների միջոցով վերլուծական նպատակների համար:\n' +
          'Ընդ որում, անհրաժեշտության դեպքում նշված ցանկը կարող է ցանկացած պահի լրացվել Ընկերության կողմից:',
        'Բոլոր դեպքերում, անձնական տեղեկատվության մշակումը կիրականացվի միայն սույն Քաղաքականության 5 և 6 կետերում և սույն բաժնում նշված նպատակների համար:',

        '<b>Անձնական տվյալների Մշակման այլ պայմաններ</b>',
        '1. Ընկերությունը միտումնավոր կերպով չի հավաքում 18 տարեկանից ցածր անձանց տվյալները:',
        '2. Ընկերությունը չի հավաքում և չի պահպանում վարկային քարտերի տվյալները կամ վճարման այլ մանրամասները:',
        '3. Օգտատերն իրավունք ունի հրաժարվել Ընկերությունից ՍԵրվիսի վերաբերյալ տեղեկատվական և գովազդային տեղեկատվություն ստանալուց՝ ինքնուրույն անջատելով առաքումների ստացման գործառույթը առաքումների հետ էլեկտրոնային նամակի պարամետրերում՝ սեղմելով նամակում նշված հատուկ հղման վրա, կամ էլեկտրոնային նամակ ուղարկելով <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a> էլեկտրոնային հասցեին։',
        '4. Ընկերությունը ձեռնարկում է բոլոր անհրաժեշտ և բավարար կազմակերպչական և տեխնիկական միջոցները` պաշտպանելու Օգտատիրոջից ստացված տեղեկատվությունը չարտոնված կամ պատահական մուտքից, ոչնչացումից, փոփոխումից, արգելափակումից, պատճենումից, տարածումից, ինչպես նաև երրորդ անձանց այլ անօրինական գործողություններից:',
        '5. Օգտատիրոջ համաձայնությունը անձնական տվյալների Մշակմանը հանդիսանում է որոշակի, տեղեկացված և գիտակցված և գործում է մինչև Ընկերության կողմից համաձայնության հետկանչման ստացման պահը:',
        '6. Օգտատերը իրավունք ունի ցանկացած պահի պահանջել ուղղում, ոչնչացում կամ անձնական տվյալների Մշակման հետ կապված այլ գործողությունների կատարում Օգտատիրոջ կողմից  <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a>  էլեկտրոնային փոստի հասցեին համապատասխան նշումով հաղորդագրություն ուղարկելու միջոցով:',
        '7. Անձնական տվյալների մշակման համաձայնության հետկանչումը կարող է իրականացվել Օգտատիրոջ կողմից help@viju.am էլեկտրոնային փոստի հասցեին «Անձնական տվյալների մշակման համաձայնության հետկանչում» նշումով հաղորդագրություն ուղարկելու միջոցով:',
        '8. Օգտատերը հասկանում է, որ անձնական տվյալների մշակման համաձայնության հետկանչումը նշանակում է Օգտատիրոջ հրաժարումը Սերվիսի օգտագործումից և հանգեցնում է Սերվիսի մուտքի դադարեցմանը:',

        '<b>Գաղտնիության քաղաքականության փոփոխություն </b>',
        'Ընկերությունն իրեն իրավունք է վերապահում փոփոխություններ մտցնել սույն Գաղտնիության քաղաքականության մեջ, ինչի մասին Ընկերությունը ծանուցում է Օգտատիրոջը նախքան տեղադրելը: Քաղաքականության նոր խմբագրությունն ուժի մեջ է մտնում նրա տեղադրման պահից, եթե այլ բան նախատեսված չէ Քաղաքականության նոր խմբագրությամբ:',
      ],
      edition: 'Խմբագրությունը՝ «07» օգոստոսի 2024թ.',
    },
  },
};

export function getPrivacyPolicyInformation(appVariation: AppVariation, appLanguage: AppLanguage) {
  const variationDocs = privacyPolicyDoc[appVariation];

  if (!variationDocs) {
    return {};
  }

  return variationDocs[appLanguage] || {};
}
