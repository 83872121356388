import type { FeatureToggleValue } from '@package/sdk/src/api';

export enum FeatureToggle {
  SmartTvSpeechApiEnabled = 'smarttv_speech_api_enabled',
  ShakaPlayerApiEnabled = 'web_smarttv_shaka_player_api_enabled',
  MatchPercent = 'web_match_percent',
  ImdbRating = 'web_imdb_rating',

  ShowAdditionalTrialCancelSubsModal = 'cancel_of_subs',

  SmartCaptcha = 'web_smart_captcha',
  OAuthVK = 'web_oauth_vk',
  OAuthYandex = 'web_oauth_yandex',

  TvChannelOnMain = 'web_is_tv_channel_on_main',
  FlocktoryExchange = 'web_flocktory_exchange',
  IsCloudPaymentsEnabled = 'web_is_cloudpayments_enabled',

  ChromecastEnabled = 'web_chromecast_enabled',
  ButtonVersion = 'web_button_version',

  IsReferralProgramPopup = 'isReferralProgramPopup',

  ActionHeaderButton = 'new_button_main_page',

  AllPlatformTestAA = 'all_platform_test_AA',
}

export const defaultFeatureToggleValues: FeatureToggleValue[] = [
  { name: FeatureToggle.SmartTvSpeechApiEnabled, status: false },
  { name: FeatureToggle.ShowAdditionalTrialCancelSubsModal, status: false },
  { name: FeatureToggle.ShakaPlayerApiEnabled, status: false },
  { name: FeatureToggle.ChromecastEnabled, status: false },
  { name: FeatureToggle.ImdbRating, status: true },
  { name: FeatureToggle.SmartCaptcha, status: true },
  { name: FeatureToggle.OAuthVK, status: true },
  { name: FeatureToggle.OAuthYandex, status: true },
  { name: FeatureToggle.TvChannelOnMain, status: false },
  { name: FeatureToggle.FlocktoryExchange, status: true },
  { name: FeatureToggle.IsCloudPaymentsEnabled, status: false },
  { name: FeatureToggle.ButtonVersion, status: false },
  { name: FeatureToggle.IsReferralProgramPopup, status: false },
  { name: FeatureToggle.ActionHeaderButton, status: false },
  { name: FeatureToggle.AllPlatformTestAA, status: false },
];

export interface FeatureToggleVariantTypeMap {
  [FeatureToggle.ButtonVersion]: 'group_a' | 'group_b';
  [FeatureToggle.ShowAdditionalTrialCancelSubsModal]: 'group_a' | 'group_b';
  [FeatureToggle.ActionHeaderButton]: 'group_a' | 'group_b';
  [FeatureToggle.AllPlatformTestAA]:
    | 'all_platform_test_AA_A'
    | 'all_platform_test_AA_B'
    | 'all_platform_test_AA_C'
    | '';
  [key: string]: string;
}
