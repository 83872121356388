let intersectionObserver: IntersectionObserver | undefined;

if ('IntersectionObserver' in window) {
  intersectionObserver = new IntersectionObserver(onIntersection, {
    rootMargin: '0px 0px 256px 0px',
    threshold: 0.01,
  });
}

function onIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
  entries.forEach((entry: IntersectionObserverEntry) => {
    if (entry.intersectionRatio === 0) {
      return;
    }

    const { target } = entry;

    observer.unobserve(target);

    load(target);
  });
}

function load(target: Element) {
  let srcsetItems: Element[] = [];

  if (target.parentNode && (target.parentNode as HTMLElement).tagName.toLowerCase() === 'picture') {
    const sources: NodeListOf<HTMLSourceElement> = target.parentNode.querySelectorAll('source');

    srcsetItems = [...(sources as unknown as HTMLSourceElement[])];
  }

  srcsetItems.push(target);

  srcsetItems.forEach((item) => {
    if (item.hasAttribute('data-srcset')) {
      item.setAttribute('srcset', item.getAttribute('data-srcset') ?? '');
      item.removeAttribute('data-srcset');
    }
  });

  target.setAttribute('src', target.getAttribute('data-src') ?? '');
  target.removeAttribute('data-src');
}

function observeImageElements() {
  const elements = document.querySelectorAll('[loading="lazy"]');

  elements.forEach((element) => {
    if (intersectionObserver === undefined || (element as HTMLElement).dataset['data-src']) {
      return;
    }

    const observedElement = element.tagName.toLowerCase() === 'picture' ? element.querySelector('img') : element;

    if (!observedElement) {
      return;
    }

    intersectionObserver.observe(observedElement);
  });
}

export function observeImageElement(element: Element) {
  if (intersectionObserver === undefined || (element as HTMLElement).dataset['data-src']) {
    return;
  }

  intersectionObserver.observe(element);
}

export default observeImageElements;
