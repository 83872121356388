import { AppLanguage, AppVariation } from './../variation/variation';
import type { Doc } from './index';

export const mailingRulesDoc: Doc = {
  [AppVariation.Ru]: {
    [AppLanguage.RU]: {
      title: 'Правила рассылок',
      text: 'Эти правила рассылок (Правила) определяют условия рассылок Сообщений Пользователям, осуществляемые Провайдером и/или его Партнером.',
      points: [
        {
          title: '1. ТЕРМИНЫ',
          items: [
            '1.1. Провайдер – Общество с ограниченной ответственностью «Виасат Глобал» (ОГРН 5067746471766, адрес: 123242, г.Москва, вн.тер.г. Муниципальный округ Пресненский, ул Зоологическая, д. 1, стр. 1.).',
            '1.2. Сервис / Сервис «viju» – программное обеспечение, обозначенное средствами индивидуализации, принадлежащими Провайдеру, размещенное в сети Интернет на сайте viju.ru и доступное Пользователям посредством установки на различных электронных устройствах. Сервис имеет возрастное ограничение 18+.',
            '1.3. Пользователь – физическое лицо, использующее Сервис и обладающее необходимой дееспособностью для принятия условий Правил.',
            '1.4. Сообщения – информационные сообщения, в том числе новостного и/или рекламного характера, связанные с Сервисом и/или тематикой Сервиса, а также с товарами/услугами третьих лиц, в том числе Партнера, направляемые в том числе по электронной почте, в смс-сообщениях, пуш уведомлениях или посредством осуществления телефонных звонков. Настоящие Правила не применяются к рассылке Провайдером сервисных (технических) сообщений, информирующих Пользователя об использовании им Сервиса, в том числе, но не ограничиваясь: сообщений, содержащих логин и пароль для доступа к Сервису, содержащих код авторизации в Сервисе, и т.д. Указанные сообщения рассылаются Провайдером в силу регистрации Пользователя в Сервисе и отказ от рассылки таких сообщений возможен только путем удаления учетной записи в Сервисе.',
            '1.5. Подписка на рассылку – действия Пользователя при использовании Сервиса, направленные на систематическое получение Сообщений от Провайдера и Партнера.',
            '1.6. Партнер – ООО «Майндбокс» (ОГРН 1097746380380).',
          ],
        },
        {
          title: '2. ДЕЙСТВИЕ ПРАВИЛ',
          items: [
            '2.1. Правила являются публичной офертой в соответствии со ст. 437 Гражданского кодекса РФ. Подписка на рассылку означает полный и безоговорочный акцепт Правил Пользователем.',
            '2.2. В случае несогласия Пользователя с каким-либо из положений Правил, Пользователь обязан отказаться от Подписки на рассылку.',
            '2.3. Правила являются частью Пользовательского соглашения Сервиса «viju», Политики конфиденциальности Сервиса «viju».',
            '2.4. Правила и отношения Пользователя с Провайдером и Партнером, возникающие в связи с Подпиской на рассылку, регулируются законодательством РФ.',
            '2.5. Правила могут быть изменены Провайдером в одностороннем внесудебном порядке, путем внесения соответствующих изменений в настоящие Правила. Используя Сервис и/или продолжая получать Сообщения после изменения Правил, Пользователь принимает Правила полностью и безоговорочно в измененном виде. Пользователь самостоятельно осуществляет проверку изменений и/или дополнений в Правила.',
          ],
        },
        {
          title: '3. УСЛОВИЯ РАССЫЛКИ СООБЩЕНИЙ',
          items: [
            '3.1. Принимая условия Правил, Пользователь выражает свое согласие на получение Сообщений от Провайдера и Партнера по адресу электронной почты или номеру телефона, указанным Пользователем в Сервисе и/или при Подписке на рассылку.',
            '3.2. Провайдер исходит из того, что Подписка на рассылку осуществляется непосредственно Пользователем, а также что адрес электронной почты и номер телефона, указанные Пользователем при Подписке на рассылку и/или в Сервисе, являются корректными и принадлежат Пользователю. Ответственность за правомерность предоставления сведений при Подписке на рассылку и их достоверность несет исключительно Пользователь.',
            '3.3. Пользователь вправе в любое время отказаться от получения Сообщений путем перехода по соответствующей гиперссылке, содержащейся в Сообщении, либо путем направления заявления на электронный адрес службы поддержки Провайдера <a href="mailto:help@viju.ru" style="color: #00c7b2" target="_blank">help@viju.ru</a>.',
          ],
        },
      ],
      edition: 'Версия от 31 января 2024 года.',
    },
    [AppLanguage.EN]: null,
    [AppLanguage.AM]: null,
  },
  [AppVariation.Am]: {
    [AppLanguage.RU]: {
      title: 'Правила рассылок',
      text: 'Эти правила рассылок (Правила) определяют условия рассылок Сообщений Пользователям, осуществляемые Провайдером и/или его Партнером.',
      points: [
        {
          title: '1. ТЕРМИНЫ',
          items: [
            '1.1. Провайдер – ООО «Ви Бродкастинг», Республика Армения, г. Ереван, ул. Саят-Нова. 19/1, каб. 129.',
            '1.2. Сервис / Сервис «viju» – программное обеспечение, обозначенное средствами индивидуализации, принадлежащими Провайдеру, размещенное в сети Интернет на сайте viju.am и доступное Пользователям посредством установки на различных электронных устройствах. Сервис имеет возрастное ограничение 18+.',
            '1.3. Пользователь – физическое лицо, использующее Сервис и обладающее необходимой дееспособностью для принятия условий Правил.',
            '1.4. Сообщения – информационные сообщения, в том числе новостного и/или рекламного\n' +
              'характера, связанные с Сервисом и/или тематикой Сервиса, а также с товарами/услугами третьих лиц, в том числе Партнера, направляемые в том числе по электронной почте, в смс-сообщениях, пуш уведомлениях или посредством осуществления телефонных звонков. Настоящие Правила не применяются к рассылке Провайдером сервисных (технических) сообщений, информирующих Пользователя об использовании им Сервиса, в том числе, но не ограничиваясь: сообщений, содержащих логин и пароль для доступа к Сервису, содержащих код авторизации в Сервисе, и т.д. Указанные сообщения рассылаются Провайдером в силу регистрации Пользователя в Сервисе и отказ от рассылки таких сообщений возможен только путем удаления учетной записи в Сервисе.',
            '1.5. Подписка на рассылку – действия Пользователя при использовании Сервиса, направленные на систематическое получение Сообщений от Провайдера и Партнера.',
            '1.6. Партнер – ООО «Майндбокс» (ОГРН 1097746380380, адрес местонахождения: 125124, г. Москва, ул. Правды, д. 26, эт/пом 12/ХХХ).',
          ],
        },
        {
          title: '2. ДЕЙСТВИЕ ПРАВИЛ',
          items: [
            '2.1. Правила являются публичной офертой в соответствии со ст. 453 Гражданского кодекса РА. Подписка на рассылку означает полный и безоговорочный акцепт Правил Пользователем.',
            '2.2. В случае несогласия Пользователя с каким-либо из положений Правил, Пользователь обязан отказаться от Подписки на рассылку.',
            '2.3. Правила являются частью Пользовательского соглашения Сервиса «viju», Политики конфиденциальности Сервиса «viju».',
            '2.4. Правила и отношения Пользователя с Провайдером и Партнером, возникающие в связи с Подпиской на рассылку, регулируются законодательством Республики Армения.',
            '2.5. Правила могут быть изменены Провайдером в одностороннем внесудебном порядке, путем внесения соответствующих изменений в настоящие Правила. Используя Сервис и/или продолжая получать Сообщения после изменения Правил, Пользователь принимает Правила полностью и безоговорочно в измененном виде. Пользователь самостоятельно осуществляет проверку изменений и/или дополнений в Правила.',
          ],
        },
        {
          title: '3. УСЛОВИЯ РАССЫЛКИ СООБЩЕНИЙ',
          items: [
            '3.1. Принимая условия Правил, Пользователь выражает свое согласие на получение Сообщений от Провайдера и Партнера по адресу электронной почты или номеру телефона, указанным Пользователем в Сервисе и/или при Подписке на рассылку.',
            '3.2. Провайдер исходит из того, что Подписка на рассылку осуществляется непосредственно Пользователем, а также что адрес электронной почты и номер телефона, указанные Пользователем при Подписке на рассылку и/или в Сервисе, являются корректными и принадлежат Пользователю. Ответственность за правомерность предоставления сведений при Подписке на рассылку и их достоверность несет исключительно Пользователь.',
            '3.3. Пользователь вправе в любое время отказаться от получения Сообщений путем перехода по соответствующей гиперссылке, содержащейся в Сообщении, либо путем направления заявления на электронный адрес службы поддержки Провайдера <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a>.',
          ],
        },
      ],
      edition: 'Версия от «07» августа 2024 года.',
    },
    [AppLanguage.EN]: {
      title: 'Mailing Rules',
      text: 'These mailing rules (Rules) define the conditions of messaging to Users, carried out by the Provider and/or its Partner.',
      points: [
        {
          title: '1. TERMS',
          items: [
            '1.1 Provider - LLC "V Broadcasting", Republic of Armenia, Yerevan, Sayat-Nova str. 19/1, office 129.',
            '1.2 Service / viju Service - software designated by means of individualization held by the Provider, placed in the Internet on the website viju.am and available to Users by installing it on various electronic devices. The Service has 18+ age restriction.',
            '1.3. User – an individual who uses the Service and has required legal capacity to accept the terms of the Rules.',
            "1.4 Messages - information messages, including news and/or advertising messages related to the Service and/or the subject matter of the Service, as well as the goods/services of third parties, including the Partner, sent by e-mail, sms-messages, push notifications or phone calls. These Rules do not apply to sending by the Provider of service (technical) messages which notify the User of the Service use, including, but not limited to: messages containing login and password for access to the Service, containing the authorization code in the Service, etc. These messages shall be sent by the Provider by virtue of the User's registration in the Service and refusal to send such messages is possible only by deleting the account in the Service.",
            "1.5 Subscription to mailing list - the User's actions when using the Service aimed at systematic receipt of Messages from the Provider and the Partner.",
            '1.6. Partner - LLC "Mindbox" (PSRN 1097746380380, location address: 125124, Moscow, Pravdy street, 26, floor/premises 12/XXX).',
          ],
        },
        {
          title: '2.  OPERATION OF RULES',
          items: [
            '2.1 The Rules are a public offer in accordance with Article 453 of the Civil Code of the Republic of Armenia. Subscription to mailing list means full and unconditional acceptance of the Rules by the User.',
            '2.2. If the User does not agree with any provision of the Rules, the User shall unsubscribe from the Subscription to mailing list.',
            '2.3. The Rules form a part of the User Agreement of the viju Service, Confidentiality policy of viju Service.',
            '2.4. The rules and relations of the User with the Provider and the Partner arising in connection with the Subscription to mailing list shall be regulated by the legislation of the Republic of Armenia.',
            '2.5. The Rules may be changed by the Provider unilaterally out of court, by making corresponding amendments to these Rules. By using the Service and/or continuing to receive Messages after the Rules have been changed, the User accepts the Rules fully and unconditionally in the changed form. The User independently checks the changes and/or amendments to the Rules.',
          ],
        },
        {
          title: '3. TERMS OF SENDING MESSAGES',
          items: [
            '3.1. By accepting the terms and conditions of the Rules, the User expresses his/her consent to receive Messages from the Provider and the Partner at the e-mail address or phone number specified by the User in the Service and/or at Subscription to mailing list.',
            '3.2. The Provider assumes that the Subscription to mailing list is made directly by the User, and that the e-mail address and telephone number provided by the User when getting Subscription to mailing list and/or in the Service are correct and are held by the User. The User shall be solely responsible for the legality and accuracy of the information provided getting Subscription to mailing list.',
            '3.3. The User shall be entitled to refuse from receiving Messages at any time by clicking on the relevant hyperlink contained in the Message or by sending an application to the Provider\'s support e-mail address <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a>.',
          ],
        },
      ],
      edition: 'Version dated August 07, 2024',
    },
    [AppLanguage.AM]: {
      title: 'Առաքումների կանոններ',
      text: 'Այս առաքումների կանոնները (Կանոններ) սահմանում են Օգտատերերին Պրովայդերի և/կամ նրա գործընկերոջ կողմից իրականացվող Հաղորդագրությունների առաքումների պայմանները:',
      points: [
        {
          title: '1. ՏԵՐՄԻՆՆԵՐ',
          items: [
            '1.1. Պրովայդեր - «Վի Բրոդկաստինգ» ՍՊԸ, Հայաստանի Հանրապետություն, ք. Երևան, Սայաթ-Նովա փող. 19/1, սեն. 129։',
            '1.2. Ծառայություն/«viju» Ծառայություն  - ծրագրային ապահովում, որը նշված է Մատակարարին պատկանող անհատականացման միջոցներով, տեղադրված է Ինտերնետ ցանցում viju.am կայքում և հասանելի է Օգտատերերին տարբեր էլեկտրոնային սարքերի վրա տեղադրման միջոցով: Ծառայությունն ունի 18+ տարիքային սահմանափակում։',
            '1.3. Օգտատեր - ֆիզիկական անձ է, որն օգտագործում է Սերվիսը և ունի կանոնների պայմաններն ընդունելու համար անհրաժեշտ գործունակություն:',
            '1.4. Հաղորդագրություններ - տեղեկատվական հաղորդագրություններ, այդ թվում նորությունների և/կամ գովազդային բնույթի, կապված Սերվիսի և/կամ Սերվիսի թեմատիկայի հետ, ինչպես նաև երրորդ անձանց, այդ թվում՝ Գործընկերոջ ապրանքների/ծառայությունների հետ, որոնք ուղարկվում են ներառյալ էլեկտրոնային փոստով, sms հաղորդագրություններով, push ծանուցումներով կամ հեռախոսազանգեր կատարելու միջոցով։ Սույն Կանոնները չեն տարածվում Սերվիսի Պրովայդերի կողմից սերվիսային (տեխնիկական) հաղորդագրությունների վրա, որոնք ծանուցում են Օգտատիրոջը իր կողմից Սերվիսից օգտվելու մասին, ներառյալ, բայց չսահմանափակվելով՝ Սերվիսի մուտքի լոգին և գաղտնաբառ պարունակող, Սերվիսում ավտորիզացման ծածկագիր պարունակող հաղորդագրությունները և այլն: Նշված հաղորդագրություններն ուղարկվում են Պրովայդերի կողմից՝ Սերվիսում Օգտատիրոջ գրանցման համար, և նման հաղորդագրությունների առաքումից հրաժարվելը հնարավոր է միայն Սերվիսից հաշվի գրանցման հեռացման միջոցով:',
            '1.5. Առաքման բաժանորդագրություն - Օգտատիրոջ գործողությունները Սերվիսից օգտվելիս, որոնք ուղղված են Պրովայդերից և Գործընկերոջից Հաղորդագրությունների սիստեմատիկ ստացմանը:',
            '1.6. Գործընկեր - «Մայնդբոքս» ՍՊԸ (ՀՊԳՀ՝ 1097746380380, գտնվելու վայրի հասցեն՝ 125124, ք. Մոսկվա, Պրավդի փող., շ. 26, հարկ/տար 12/ХХХ):',
          ],
        },
        {
          title: '2. ԿԱՆՈՆՆԵՐԻ ԳՈՐԾՈՂՈՒԹՅՈՒՆԸ',
          items: [
            '2.1. Կանոնները հանդիսանում են հրապարակային օֆերտա՝ ՀՀ քաղաքացիական օրենսգրքի 453-րդ հոդվածին համապատասխան: Առաքման բաժանորդագություն նշանակում է Օգտատիրոջ կողմից Կանոնների լիակատար և անվերապահ ընդունում:',
            '2.2. Կանոնների դրույթներից որևէ մեկի հետ Օգտատիրոջ չհամաձայնելու դեպքում, Օգտատերը պարտավոր է հրաժարվել Առաքման բաժանորդագրությունից:',
            '2.3. Կանոնները հանդիսանում են «viju» Ծառայության Օգտատիրական համաձայնագրի և «viju» Ծառայության գաղտնիության քաղաքականության մաս:',
            '2.4. Առաքմանը բաժանորդագրության հետ կապված կանոնները և հարաբերությունները Օգտատիրոջ և Պրովայդերի ու Գործընկերոջ միջև կարգավորվում են Հայաստանի Հանրապետության օրենսդրությամբ:',
            '2.5. Կանոնները կարող են փոփոխվել Պրովայդերի կողմից միակողմանիորեն արտադատական ​​կարգով` սույն Կանոններում համապատասխան փոփոխություններ մտցնելու միջոցով: Օգտագործելով Սերվիսը և/կամ Կանոնների փոփոխումից հետո շարունակելով ստանալ հաղորդագրություններ՝ Օգտատերը ընդունում է Կանոնները ամբողջությամբ և անվերապահորեն փոփոխված ձևով: Օգտատերն ինքնուրույն է իրականացնում Կանոնների փոփոխությունների և/կամ լրացումների ստուգումը:',
          ],
        },
        {
          title: '3. ՀԱՂՈՐԴԱԳՐՈՒԹՅՈՒՆՆԵՐԻ ԱՌԱՔՄԱՆ ԿԱՆՈՆՆԵՐԸ',
          items: [
            '3.1. Ընդունելով Կանոնների պայմանները՝ Օգտատերը տալիս է իր համաձայնությունը՝ ստանալու հաղորդագրություններ Պրովայդերից և Գործընկերոջից Սերվիսում և/կամ Առաքման բաժանորդագրության ժամանակ Օգտատիրոջ կողմից նշված էլեկտրոնային փոստի հասցեով կամ հեռախոսահամարով:',
            '3.2. Պրովայդերը ելնում է այն հանգամանքից, որ Առաքման բաժանորդագրությունը իրականացվում է անմիջապես Օգտատիրոջ կողմից, և որ Օգտատիրոջ կողմից Առաքմանը բաժանորդագրվելիս և/կամ Սերվիսում նշված էլեկտրոնային փոստի հասցեն և հեռախոսահամարը ճշգրիտ են և պատկանում են Օգտատիրոջը: Առաքմանը բաժանորդագրվելիս տեղեկությունների տրամադրման իրավաչափությունը և դրանց ճշգրտհավաստիության պատասխանատվությունը կրում է բացառապես Օգտատերը:',
            '3.3. Օգտատերն իրավունք ունի ցանկացած պահի հրաժարվել Հաղորդագրություններ ստանալուց՝ Հաղորդագրության մեջ պարունակվող համապատասխան հիպերհղմանն անցման կամ Պրովայդերի աջակցման ծառայությանը <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a> էլեկտրոնային փոստի հասցեով դիմում ուղարկելու միջոցով:',
          ],
        },
      ],
      edition: 'Տարբերակը՝ «07» օգոստոսի 2024թ.',
    },
  },
};

export function getMailingRulesInformation(appVariation: AppVariation, appLanguage: AppLanguage) {
  const variationDocs = mailingRulesDoc[appVariation];

  if (!variationDocs) {
    return {};
  }

  return variationDocs[appLanguage] || {};
}
