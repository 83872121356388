<template>
  <div :class="wrapperClass">
    <IconPlay v-if="currentAction === 'play'" :class="$style.icon" />
    <IconPause v-if="currentAction === 'pause'" :class="$style.icon" />
  </div>
</template>

<script>
import IconPause from '@SMART/assets/icons/51x51/pause.svg';
import IconPlay from '@SMART/assets/icons/51x51/play.svg';

export default {
  components: {
    IconPlay,
    IconPause,
  },
  props: {
    currentAction: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHide: true,
      timeoutId: null,
    };
  },
  computed: {
    wrapperClass: function () {
      return {
        [this.$style.wrapper]: true,
        [this.$style.hide]: this.isHide,
      };
    },
  },
  watch: {
    currentAction: function () {
      this.isHide = false;

      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
      }

      this.timeoutId = window.setTimeout(() => {
        this.isHide = true;
      }, 400);
    },
  },
  beforeUnmount() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: adjustPx(48px);
  width: adjustPx(192px);
  height: adjustPx(192px);
  border-radius: 50%;
  background: var(--color-notheme-bg-hover-70);
  opacity: 0.9;
  overflow: hidden;
  transition: opacity ease-out 0.3s;
}

.hide {
  opacity: 0;
}

.icon {
  width: adjustPx(96px);
  height: adjustPx(96px);
}
</style>
