import { ProcessingMapper } from '../base/processing-mapper';
import type { ApiBonus } from './bonus';
import type { Bonus } from './promocode/bonus';

export class BonusMapper {
  static mapMany(bonuses: ApiBonus[]): Bonus[] {
    return ProcessingMapper.process(bonuses.map(BonusMapper.map));
  }

  static map({
    id,
    bonus_type,
    status,
    activated_at,
    access_before,
    starts_at,
    ends_at,
    metadata,
    subscription_id,
    discount,
    duration,
    duration_unit,
  }: ApiBonus): Bonus {
    return ProcessingMapper.process({
      id,
      bonusType: bonus_type,
      status,
      activatedAt: activated_at,
      accessBefore: access_before,
      startsAt: starts_at,
      endsAt: ends_at,
      metadata: {
        startsAt: metadata.starts_at,
        endsAt: metadata.ends_at,
        accessBefore: metadata.access_before,
        duration: metadata.duration,
        durationUnit: metadata.duration_unit,
      },
      subscriptionId: subscription_id,
      discount,
      duration,
      durationUnit: duration_unit,
    });
  }
}
