import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { Disposable, IDisposable, throttleWithImmediate, TvKeyCode } from '@package/sdk/src/core';
import { Direction, SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';

import { AppKeyboardEvent, keyboardEventHandler } from '../../navigation/keyboard-event-handler';
import { AppWheelEvent, mouseEventHandler } from '../../navigation/mouse-event-handler';
import { RouterService } from '../router/router-service';

export interface WheelActions {
  dec: Direction;
  inc: Direction;
}

const appNavigationThrottleTimeoutMs = ConstantsConfigInstanceSmartTV.getProperty('appNavigationThrottleTimeoutMs');
const initialWheelActions: WheelActions = { dec: 'up', inc: 'down' };

export class CustomEventsService extends Disposable {
  public disposes: Set<IDisposable> = new Set();

  // Backspace
  private onPressBackFn?: VoidFunction;

  public constructor(private routerService: RouterService) {
    super();
  }

  public init() {
    const mouseDispose = mouseEventHandler.on(
      'wheel',
      throttleWithImmediate(this.onWheel.bind(this), { timeout: appNavigationThrottleTimeoutMs }),
    );

    const backspaceDispose = keyboardEventHandler.on(TvKeyCode.RETURN, this.onBackspace.bind(this));

    this.disposes.add(mouseDispose);
    this.disposes.add(backspaceDispose);
  }

  public dispose() {
    this.disposes.forEach((d) => {
      d.dispose();
      this.disposes.delete(d);
    });
  }

  // Wheel & Scroll
  private wheelActions: WheelActions = { dec: 'up', inc: 'down' };

  public async onWheel(event: AppWheelEvent) {
    event.preventDefault();

    if (event.domEvent.deltaY < 0) {
      return SpatialNavigation.smartNavigate(this.wheelActions.dec, '', {});
    }

    if (event.domEvent.deltaY > 0) {
      return SpatialNavigation.smartNavigate(this.wheelActions.inc, '', {});
    }
  }

  public setWheelAction = (actions: WheelActions) => {
    this.wheelActions = actions || initialWheelActions;
  };

  public setOnPressBackCallback = (callback?: VoidFunction, once = false) => {
    const prevValue = this.onPressBackFn;

    if (callback && once) {
      this.onPressBackFn = () => {
        callback();
        this.onPressBackFn = prevValue;
      };
    } else {
      this.onPressBackFn = callback;
    }
  };

  public async onBackspace(event: AppKeyboardEvent) {
    event.preventDefault();

    if (this.onPressBackFn) {
      return this.onPressBackFn();
    }

    return this.routerService.onBackspace(event);
  }
}
