<script>
export default {
  props: {
    tag: {
      default: 'div',
    },
    x: {
      default: 0,
    },
    y: {
      default: 0,
    },
    /**
     * @type {vertical | 'horizontal' | undefined}
     */
    orientation: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    transform() {
      if (this.orientation) {
        return '';
      }

      let xVal = parseFloat(this.x && this.x.toString());
      let yVal = parseFloat(this.y && this.y.toString());

      xVal = !isNaN(xVal) ? xVal * -1 : 0;
      yVal = !isNaN(yVal) ? yVal * -1 : 0;

      return `transform: translate(${xVal}px, ${yVal}px)`;
    },
  },
  render(h) {
    let style = {
      transform: this.transform,
    };

    if (this.orientation) {
      style['overflow-x'] = this.orientation === 'horizontal' ? 'auto' : 'hidden';
      style['overflow-y'] = this.orientation === 'vertical' ? 'auto' : 'hidden';
    } else {
      style = this.transform;
    }

    const classes = {
      scrollViewport: true,
    };

    return h(
      this.tag,
      {
        class: classes,
        style,
      },
      this.$slots.default,
    );
  },
};
</script>

<style lang="scss">
.scrollViewport {
  &::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }
}
</style>
