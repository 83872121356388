<template>
  <UIModal>
    <section ref="el" :class="$style.help">
      <h1>{{ $t('pages.auth.qrLogin.help') }}</h1>
      <h1 :class="$style.helpPhone">{{ $t('pages.auth.qrLogin.phone') }}</h1>
      <NavigatableItem
        :tag="AppButton"
        autofocus
        :class="$style.helpCloseButton"
        :text="$t('close')"
        :prop-parent-focus-key="focusKey"
        :on-click="() => $emit('on-toggle-help', false)"
      />
    </section>
  </UIModal>
</template>

<script>
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { defineComponent, onMounted, provide } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import UIModal from '@/components/modal/UIModal.vue';

export default defineComponent({
  setup() {
    const { el, focusSelf, focusKey } = useNavigatable({
      focusKey: 'NEED_HELP_MODAL',
      isFocusBoundary: true,
      focusBoundaryDirections: [],
      trackChildren: true,
    });
    provide('parentFocusKey', focusKey.value);

    onMounted(() => focusSelf());

    return {
      AppButton,
      el,
      focusKey,
    };
  },
  components: { UIModal },
});
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.help {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background: var(--color-bg-secondary-60);
  flex-flow: column;
  justify-content: center;

  &Phone {
    margin-top: adjustPx(20px);
    color: var(--color-bg-accent);

    @include f-title-0;
  }

  &CloseButton {
    margin-top: adjustPx(162px);
  }
}
</style>
