<template>
  <Transition>
    <section :class="$style.toolbar">
      <NavigatableItem
        :tag="AppButton"
        :class="{
          [$style.button]: true,
          [$style.toolbarButton]: true,
          [$style.selected]: isCurrentVideoLiked,
        }"
        :active-class="$style.active"
        :on-click="onLike"
        @active="$emit('active')"
      >
        <template v-slot:icon>
          <ThumbUpIcon :class="$style.icon" />
        </template>
      </NavigatableItem>
      <NavigatableItem
        :tag="AppButton"
        :class="{
          [$style.button]: true,
          [$style.toolbarButton]: true,
          [$style.selected]: isCurrentVideoDisliked,
        }"
        :active-class="$style.active"
        :on-click="onDislike"
        @active="$emit('active')"
      >
        <template v-slot:icon>
          <ThumbDownIcon :class="$style.icon" />
        </template>
      </NavigatableItem>
      <NavigatableItem
        :tag="AppButton"
        :class="{
          [$style.button]: true,
          [$style.bookmarkButton]: true,
          [$style.bookmarkSelected]: isCurrentVideoSaved,
        }"
        :active-class="$style.active"
        :on-click="onSave"
        @active="$emit('active')"
      >
        <template v-slot:icon>
          <BookmarkFillIcon v-if="isCurrentVideoSaved" :class="$style.bookMarkIcon" />
          <BookmarkIcon v-else :class="$style.bookMarkIcon" />
        </template>
      </NavigatableItem>
    </section>
  </Transition>
</template>

<script>
import { LikeState } from '@package/sdk/src/api';
import { throttleWithImmediate } from '@package/sdk/src/core';
import BookmarkIcon from '@SMART/assets/icons/51x51/bookmark.svg';
import BookmarkFillIcon from '@SMART/assets/icons/51x51/save.svg';
import ThumbDownIcon from '@SMART/assets/icons/51x51/thumbs-down.svg';
import ThumbUpIcon from '@SMART/assets/icons/51x51/thumbs-up.svg';
import { collectionService } from '@SMART/index';
import { computed } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

const CLICK_TIMEOUT_MS = 1_500;

export default {
  components: {
    BookmarkIcon,
    BookmarkFillIcon,
    ThumbDownIcon,
    ThumbUpIcon,
    NavigatableItem,
  },
  props: {
    moment: Object,
  },
  setup(props, { emit }) {
    const onLike = throttleWithImmediate(() => emit('like', LikeState.Like), {
      timeout: CLICK_TIMEOUT_MS,
      immediate: true,
    });
    const onDislike = throttleWithImmediate(() => emit('like', LikeState.Dislike), {
      timeout: CLICK_TIMEOUT_MS,
      immediate: true,
    });
    const onSave = throttleWithImmediate(() => emit('save'), { timeout: CLICK_TIMEOUT_MS, immediate: true });

    const currentLikeState = computed(() => props.moment?.likeState);
    const isCurrentVideoLiked = computed(() => currentLikeState.value === LikeState.Like ?? false);
    const isCurrentVideoDisliked = computed(() => currentLikeState.value === LikeState.Dislike ?? false);
    const isCurrentVideoSaved = computed(
      () => props.moment?.inUserCollection || collectionService.savedMomentsItems?.includes(props.moment?.id),
    );
    return {
      onLike,
      onDislike,
      onSave,
      isCurrentVideoLiked,
      isCurrentVideoDisliked,
      isCurrentVideoSaved,
      currentLikeState,
      AppButton,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.toolbar {
  position: fixed;
  bottom: adjustPx(48px);
  right: adjustPx(48px);
  display: flex;
  flex-flow: row;
}

.button {
  display: flex;
  align-items: center;
  padding: 0 adjustPx(24px);
  width: adjustPx(96px);
  height: adjustPx(96px);
  border-radius: adjustPx(24px);
  border: none;
  border-color: transparent;
  background-color: var(--color-bg-ghost);
  justify-content: center;

  &:hover {
    background: var(--color-bg-accent);
    color: var(--color-notheme-text-accent);
    .bookMarkIcon {
      width: adjustPx(51px);
      height: adjustPx(51px);
      fill: var(--color-notheme-text-accent);
    }
  }
}

.icon {
  width: adjustPx(51px);
  height: adjustPx(51px);
}

.toolbarButton {
  margin-right: adjustPx(16px) !important;
}

.active {
  background-color: var(--color-bg-accent);

  .icon {
    color: var(--color-notheme-text-accent);
    fill: var(--color-bg-accent);
  }

  .bookMarkIcon {
    width: adjustPx(51px);
    height: adjustPx(51px);
    fill: var(--color-notheme-text-accent);
  }
}

.selected {
  .icon {
    color: var(--color-states-bg-secondary-disabled);
    fill: var(--color-text-primary);
  }

  &:hover {
    background-color: var(--color-bg-accent);
    .icon {
      color: var(--color-bg-accent);
      fill: var(--color-text-primary);
    }
  }
  &.active {
    .icon {
      color: var(--color-bg-accent);
      fill: var(--color-notheme-text-accent);
    }

    .bookMarkSavedIcon {
      path {
        fill: var(--color-notheme-text-accent);
        stroke: var(--color-notheme-text-accent);
      }
    }
  }
}
.bookMarkIcon {
  width: adjustPx(51px);
  height: adjustPx(51px);
  fill: var(--color-text-primary);
}
</style>
