<template>
  <div :class="$style.build">
    {{ appVersion }}
  </div>
</template>

<script>
import { environmentService } from '@SMART/index';

export default {
  data: () => ({
    appVersion: '',
  }),
  mounted() {
    this.appVersion = environmentService.getVariable('appVersion')();
  },
};
</script>

<style lang="scss" module>
@import '@/styles/colors';

.build {
  position: absolute;
  right: 10px;
  z-index: 10000;
  font-family: monospace;
  font-size: 10px;
  color: var(--color-text-primary);
  pointer-events: none;
}
</style>
