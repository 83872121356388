import useLogger from '@package/logger/src/use-logger';
import {
  ApiEpisode,
  ApiMoment,
  ApiYear,
  EpisodeMapper,
  MediaContentType,
  MomentMapper,
  YearMapper,
} from '@package/sdk/src/api';
import { Compilation } from '@package/sdk/src/api/compilations/compilation';
import { CompilationMapper } from '@package/sdk/src/api/compilations/compilation-mapper';
import { ApiCompilation } from '@package/sdk/src/api/compilations/compilation-types';
import { ApiCountry } from '@package/sdk/src/api/content/content-types/country';
import { ApiGenre } from '@package/sdk/src/api/content/content-types/genre';
import { ApiMedia } from '@package/sdk/src/api/content/content-types/media';
import { ApiMovie } from '@package/sdk/src/api/content/content-types/movie';
import { ApiSerial } from '@package/sdk/src/api/content/content-types/serial';
import { ApiStreamManifest } from '@package/sdk/src/api/content/content-types/stream-manifest-types';
import { CountryMapper } from '@package/sdk/src/api/content/country';
import { GenreMapper } from '@package/sdk/src/api/content/genre';
import { MediaMapper } from '@package/sdk/src/api/content/media';
import { SerialMapper } from '@package/sdk/src/api/content/serial';
import { StreamManifestMapper } from '@package/sdk/src/api/content/stream-manifest';
import { Country } from '@package/sdk/src/api/content/types/country';
import { Genre } from '@package/sdk/src/api/content/types/genre';
import { Media } from '@package/sdk/src/api/content/types/media';
import { Moment } from '@package/sdk/src/api/content/types/moment';
import { Movie } from '@package/sdk/src/api/content/types/movie';
import { Serial } from '@package/sdk/src/api/content/types/serial';
import { StreamManifest } from '@package/sdk/src/api/content/types/stream-manifest';
import { Year } from '@package/sdk/src/api/content/types/year';
import { ENDPOINTS } from '@package/sdk/src/api/endpoints';
import { MovieMapper } from '@package/sdk/src/api/movie/movie';
import { RecommendationsRandomMapper } from '@package/sdk/src/api/recommendations/random-content-recommendation-mapper';
import {
  ApiContentRecommendationsRandom,
  ContentRecommendationsRandom,
} from '@package/sdk/src/api/recommendations/recommendation-types';
import { LikeState } from '@package/sdk/src/api/user-collection/like-state';
import { WatchingItem } from '@package/sdk/src/api/watching-items/types/watching-item';
import { WatchingItemMapper } from '@package/sdk/src/api/watching-items/watching-item';
import { ApiWatchingItem } from '@package/sdk/src/api/watching-items/watching-item-types';
import { coalesce, UnexpectedComponentStateError } from '@package/sdk/src/core';

import { MOMENT_DISLIKED_STORAGE_KEY, MOMENT_LIKED_STORAGE_KEY, MOMENTS_SAVED_STORAGE_KEY } from '../collection/common';
import type { DeviceService } from '../device/device-service';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';
import type { IStorageService } from '../storage/storage-service';
import type { ContentFetchWatchingParams, ContentParams, ContentUpdateWatchParams } from './catalog-types';

const logger = useLogger('catalog-service', 'smarttv');

export class CatalogService {
  constructor(
    private readonly requestService: RequestService,
    private readonly storageService: IStorageService,
    private readonly deviceService: DeviceService,
  ) {}

  public get likedItems() {
    return (this.storageService.getItem<string[]>(MOMENT_LIKED_STORAGE_KEY) || []) as string[];
  }

  public get dislikedItems() {
    return (this.storageService.getItem<string[]>(MOMENT_DISLIKED_STORAGE_KEY) || []) as string[];
  }

  public get savedMomentsItems() {
    return (this.storageService.getItem(MOMENTS_SAVED_STORAGE_KEY) || []) as string[];
  }

  public abort(message = 'Cancelled by user'): void {
    this.requestService.abort(message);
  }

  public async fetchCountries(): Promise<Country[]> {
    const { data } = await this.requestService.request<ApiCountry[]>({
      method: HTTPRequestMethod.Get,
      url: ENDPOINTS.COUNTRIES,
    });

    return CountryMapper.mapMany(data);
  }

  public async fetchGenres(): Promise<Genre[]> {
    const { data } = await this.requestService.request<ApiGenre[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.GENRES,
      },
      { canAbort: false },
    );

    return GenreMapper.mapMany(data);
  }

  public async fetchPeriods(): Promise<Year[]> {
    const { data } = await this.requestService.request<ApiYear[]>({
      method: HTTPRequestMethod.Get,
      url: ENDPOINTS.YEARS,
    });

    return YearMapper.mapMany(data);
  }

  public async fetchColdRecommendations(): Promise<Media[]> {
    const visitorId = this.deviceService.getVisitorId();

    const { data } = await this.requestService.request<ApiMedia[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.CONTENTS_COLD_RECOMMENDATIONS,
        query: {
          visitor_id: visitorId,
        },
      },
      { canAbort: false },
    );

    return this.mapMedia(data);
  }

  public async fetchPersonalRecommendations(): Promise<Media[]> {
    const { data } = await this.requestService.request<ApiMedia[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.CONTENTS_PERSONAL_RECOMMENDATIONS,
      },
      { withToken: true },
    );

    return this.mapMedia(data);
  }

  public async fetchMoments(contentId: string): Promise<Moment[]> {
    const { data } = await this.requestService.request<ApiMoment[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.CONTENTS_MOMENTS,
        params: {
          id: contentId,
        },
      },
      { withToken: true, skipTokenValidation: true },
    );

    const saved = {
      likedItems: this.likedItems,
      dislikedItems: this.dislikedItems,
      savedMomentsItems: this.savedMomentsItems,
      removedMomentsItems: [],
    };

    const moments = MomentMapper.mapMany(data);

    return moments.map((moment) => {
      let likeState = moment.likeState;

      if (saved.likedItems?.find((id) => id === moment.id)) {
        likeState = LikeState.Like;
      }

      if (saved.dislikedItems?.find((id) => id === moment.id)) {
        likeState = LikeState.Dislike;
      }

      let saveState = moment.inUserCollection;

      if (saved.savedMomentsItems?.find((id) => id === moment.id)) {
        saveState = true;
      }

      if (saved.removedMomentsItems?.find((id) => id === moment.id)) {
        saveState = false;
      }

      return { ...moment, likeState, inUserCollection: saveState };
    });
  }

  public async fetchContinueWatchItemsV2(): Promise<Media[]> {
    try {
      const { data } = await this.requestService.request<ApiMedia[]>(
        {
          method: HTTPRequestMethod.Get,
          url: ENDPOINTS.CONTENT_WATCHING_ITEMS_CONTINUE,
        },
        { withToken: true },
      );

      return this.mapMedia(data);
    } catch (error) {
      // expected for unauthorized
      return [];
    }
  }

  private mapMedia(data: ApiMedia[]): Media[] {
    return data.map((item) => {
      const contentType = item.content_type;

      if (contentType === MediaContentType.Movie) {
        return MovieMapper.map(item as ApiMovie);
      }

      if (contentType === MediaContentType.Serial) {
        return SerialMapper.map(item as ApiSerial);
      }

      if (contentType === MediaContentType.Episode) {
        return EpisodeMapper.map(item as ApiEpisode);
      }

      return MediaMapper.map(item);
    });
  }

  public async getWatchingItemByContentId(params: ContentFetchWatchingParams): Promise<WatchingItem | undefined> {
    const { profileId, contentId } = params;

    if (!profileId) {
      throw new UnexpectedComponentStateError('profileId');
    }

    const { data } = await this.requestService.request<ApiWatchingItem[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.PROFILES_WATCHING_ITEM_CONTENTS,
        params: {
          profile_id: profileId,
          content_id: contentId,
        },
      },
      {
        withToken: true,
      },
    );

    if (data[0]) {
      return WatchingItemMapper.map(data[0]);
    }

    return undefined;
  }

  public async updateWatchItem(params: ContentUpdateWatchParams): Promise<void> {
    const { offset, duration, sign, id, profileId } = params;

    try {
      await this.requestService.request(
        {
          method: HTTPRequestMethod.Put,
          url: ENDPOINTS.PROFILES_WATCHING_ITEM_CONTENTS,
          headers: {
            HMAC: sign,
          },
          params: {
            profile_id: profileId,
            content_id: id,
          },
          data: {
            offset: Math.floor(offset),
            duration,
          },
        },
        { withToken: true },
      );
    } catch (error) {
      logger.error(error);
    }
  }

  public async hideWatchItem(profileId: string, contentId: string): Promise<void> {
    await this.requestService.request(
      {
        method: HTTPRequestMethod.Delete,
        url: ENDPOINTS.PROFILES_WATCHING_ITEM_CONTENTS,
        params: { profile_id: profileId, content_id: contentId },
      },
      { withToken: true },
    );
  }

  public async fetchSerialManifest(
    id: string,
    headers: Record<string, any> = {},
    query: Record<string, any> = {},
  ): Promise<StreamManifest> {
    const { data } = await this.requestService.request<ApiStreamManifest>(
      {
        method: HTTPRequestMethod.Get,
        headers,
        query,
        params: {
          id,
        },
        url: ENDPOINTS.CONTENT_SERIAL_MANIFEST,
      },
      { withToken: true },
    );

    return StreamManifestMapper.map(data);
  }

  public async fetchMovieManifest(
    id: string,
    headers: Record<string, any> = {},
    query: Record<string, any> = {},
  ): Promise<StreamManifest> {
    const { data } = await this.requestService.request<ApiStreamManifest>(
      {
        method: HTTPRequestMethod.Get,
        headers,
        query,
        params: {
          id,
        },
        url: ENDPOINTS.CONTENT_MOVIE_MANIFEST,
      },
      { withToken: true },
    );

    return StreamManifestMapper.map(data);
  }

  public async fetchMovie(
    id: string,
    headers: Record<string, any> = {},
    query: Record<string, any> = { with_content_match_percent_by_profile: true, with_logo: true },
  ): Promise<Movie> {
    const { data } = await this.requestService.request<ApiMovie>(
      {
        method: HTTPRequestMethod.Get,
        headers,
        query,
        params: {
          id,
        },
        url: ENDPOINTS.CONTENT_MOVIES_ID,
      },
      { withToken: true, skipTokenValidation: true },
    );

    return MovieMapper.map(data);
  }

  public async fetchMovies(params: ContentParams, headers: Record<string, any> = {}): Promise<Movie[]> {
    const { size, page, periods = [], countries = [], genres = [] } = params;

    const { data } = await this.requestService.request<ApiMovie[], 'CONTENT_MOVIES'>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.CONTENT_MOVIES,
        headers,
        query: {
          page,
          per_page: size as number,
          years: periods?.map((year: Year) => ({ from: year.from, to: year.to })),
          genres_slugs: coalesce(genres?.map((genre) => genre.slug?.replaceAll('_', '-'))),
          countries_slugs: countries?.map((country) => country.slug?.replaceAll('_', '-')),
          with_seasons: false,
          with_locked: false,
        },
      },
      { withToken: true, skipTokenValidation: true },
    );

    return MovieMapper.mapMany(data);
  }

  public async fetchSerial(
    id: string,
    headers: Record<string, any> = {},
    query: Record<string, any> = { with_content_match_percent_by_profile: true, with_logo: true },
  ): Promise<Serial> {
    const { data } = await this.requestService.request<ApiSerial>(
      {
        method: HTTPRequestMethod.Get,
        query,
        params: {
          id,
        },
        url: ENDPOINTS.CONTENT_SERIALS_SLUG,
        headers,
      },
      { withToken: true, skipTokenValidation: true },
    );

    return SerialMapper.map(data);
  }

  public async fetchSerials(params: ContentParams): Promise<Serial[]> {
    const { size, page, periods = [], countries = [], genres = [] } = params;

    const { data } = await this.requestService.request<ApiSerial[], 'CONTENT_SERIALS'>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.CONTENT_SERIALS,
        query: {
          page,
          per_page: size,
          years: periods?.map((year: Year) => ({ from: year.from, to: year.to })),
          genres_slugs: genres?.map((genre) => genre.slug?.replaceAll('_', '-')),
          countries_slugs: countries?.map((country) => country.slug?.replaceAll('_', '-')),
          with_seasons: false,
          with_locked: false,
        },
      },
      { withToken: true, skipTokenValidation: true },
    );

    return SerialMapper.mapMany(data);
  }

  public async fetchAll(params: ContentParams): Promise<(Movie | Serial)[]> {
    const { size, page, periods = [], countries = [], genres = [] } = params;

    const { data } = await this.requestService.request<(ApiSerial | ApiMovie)[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.CONTENT_ALL,
        query: {
          page,
          per_page: size,
          years: periods?.map((year: Year) => ({ from: year.from, to: year.to })),
          genres_slugs: genres?.map((genre) => genre.slug?.replaceAll('_', '-')),
          countries_slugs: countries?.map((country) => country.slug?.replaceAll('_', '-')),
          with_seasons: false,
          with_locked: false,
        },
      },
      { withToken: true, skipTokenValidation: true },
    );

    return data.map((item) => {
      if (item.content_type === 'movie') {
        return MovieMapper.map(item as ApiMovie);
      } else {
        return SerialMapper.map(item as ApiSerial);
      }
    });
  }

  public async fetchSimilar(id: string): Promise<Media[]> {
    const { data } = await this.requestService.request<ApiMedia[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.CONTENTS_SIMILAR,
        params: {
          id,
        },
      },
      { withToken: true, skipTokenValidation: true },
    );

    return MediaMapper.mapMany(data);
  }

  public async fetchCollections(id: string, options?: { withContent: boolean }): Promise<Compilation[]> {
    const { data } = await this.requestService.request<ApiCompilation[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.CONTENTS_COMPILATIONS,
        params: {
          id,
        },
      },
      { withToken: true, skipTokenValidation: true },
    );

    if (options?.withContent) {
      await Promise.all(
        data.map(async (collection) => {
          const { data: items } = await this.requestService.request<ApiCompilation>(
            {
              method: HTTPRequestMethod.Get,
              params: {
                idOrSlug: collection.id,
              },
              url: ENDPOINTS.COMPILATION_GET_BY_SLUG,
            },
            { withToken: true, skipTokenValidation: true },
          );

          collection.contents = items.contents;
        }),
      ).catch(console.error);
    }

    return CompilationMapper.mapMany(data);
  }

  public async fetchCollectionsInfo(id: string): Promise<Compilation> {
    const { data } = await this.requestService.request<ApiCompilation>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.COMPILATION_GET_BY_SLUG,
        params: {
          idOrSlug: id,
        },
      },
      { withToken: true, skipTokenValidation: true },
    );

    return CompilationMapper.map(data);
  }

  public async fetchContentRecommendationsRandom(
    contentType: MediaContentType = MediaContentType.Movie,
  ): Promise<ContentRecommendationsRandom> {
    const { data } = await this.requestService.request<ApiContentRecommendationsRandom>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.CONTENT_RECOMMENDATIONS_RANDOM,
        query: {
          content_type: contentType,
        },
      },
      { withToken: true, skipTokenValidation: true },
    );

    return RecommendationsRandomMapper.map(data);
  }
}
