<template>
  <div ref="el" :class="$style.page">
    <section :class="$style.search">
      <span ref="anchorRef"></span>
      <VirtualKeyboard
        ref="keyboardRef"
        lang="ru"
        :placeholder="$t('pages.search.placeholder')"
        input-type="text"
        input-variant="secondary"
        :is-keyboard-hidden="isKeyboardHidden"
        :input="input"
        :type="type"
        @update:input="input = $event"
        @update:type="type = $event"
        @active="onActivateKeyboard"
      />

      <section v-show="!isKeyboardHidden" :class="$style.history">
        <NavigatableItem
          v-for="item in history"
          :key="item.createdAt"
          :tag="AppButton"
          :class="$style.historyButton"
          :active-class="$style.active"
          :text-class-name="$style.text"
          :text="item.query"
          :on-click="() => onHistorySelect(item.query)"
          @active="onActivateHistory"
        />
      </section>
    </section>

    <section
      :class="{
        [$style.content]: true,
      }"
    >
      <SkeletonButtons v-show="shouldShowSkeleton" />
      <h3 v-if="shouldShowSuggestion" :class="$style.title">
        {{ $t('pages.search.suggestTitle') }}
      </h3>
      <h3 v-else-if="shouldShowNotFound" :class="$style.title">
        {{ $t('pages.search.notFoundTitle') }}
      </h3>
      <h3 v-else-if="shouldShowFound" :class="$style.title">
        {{ $t('pages.search.foundTitle', { input }) }}
      </h3>

      <ScrollViewport ref="scrollerEl" orientation="horizontal" tag="div" :class="$style.posters">
        <UIPoster
          v-for="(item, index) in items"
          :key="item.id"
          :title="item.title"
          :src="item.poster"
          tabindex="0"
          scroll-block="nearest"
          :on-click="() => onSelectPoster(item, item.id, index)"
          @active="(el) => onActivatePoster(item.id, item, el)"
        />
      </ScrollViewport>
    </section>
  </div>
</template>

<script>
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { ItemPageFrom } from '@package/sdk/src/analytics';
import { debounce, DisposableStore } from '@package/sdk/src/core';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  catalogService,
  customEventsService,
  scrollToElement,
  searchService,
  storeToRefs,
  useCatalogStore,
  useMediaContentActions,
  useSessionStore,
} from '@SMART/index';
import { computed, onBeforeUnmount, onDeactivated, onMounted, provide, ref, watch } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import UIPoster from '@/components/poster/UIPoster.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import SkeletonButtons from '@/components/skeletons/SkeletonButtons.vue';
import VirtualKeyboard from '@/components/virtual-keyboard/VirtualKeyboard.vue';

export default {
  components: {
    UIPoster,
    SkeletonButtons,
    VirtualKeyboard,
    ScrollViewport,
  },
  setup() {
    const catalogStore = useCatalogStore();
    const { isAuth } = storeToRefs(useSessionStore());

    const { el, focusKey, focusSelf } = useNavigatable({ focusKey: 'SEARCH_PAGE' });
    provide('parentFocusKey', focusKey.value);

    const anchorRef = ref();
    const input = ref('');
    /**
     *
     * @type {Ref<UnwrapRef<Media[]>>}
     */
    const searchContentItems = ref([]);
    /**
     *
     * @type {Ref<UnwrapRef<Media[]>>}
     */
    const movieItems = ref([]);
    const history = ref([]);
    const type = ref('letter');

    const items = computed(() => (!input.value.length ? movieItems.value : searchContentItems.value));

    /**
     *
     * @type {Ref<HTMLElement>}
     */
    const scrollerEl = ref();

    const isKeyboardHidden = ref(false);
    const activeItemId = ref(0);

    const disposableStore = new DisposableStore();

    const { openContentPage } = useMediaContentActions();

    const fetchMovies = async () => {
      movieItems.value = await catalogService.fetchMovies({ page: 1, size: 15 });
    };

    const onActivateKeyboard = () => {
      isKeyboardHidden.value = false;
      customEventsService.setWheelAction({ dec: 'up', inc: 'down' });

      window.requestAnimationFrame(() => {
        anchorRef.value?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      });
    };

    const onActivateHistory = () => {
      isKeyboardHidden.value = false;
      customEventsService.setWheelAction({ dec: 'up', inc: 'down' });
    };

    /**
     *
     * @param id
     * @param contentItem
     * @param element {HTMLElement}
     */
    const onActivatePoster = (id, contentItem, element) => {
      activeItemId.value = id;
      isKeyboardHidden.value = true;

      scrollToElement(scrollerEl.value.$el, { left: element.offsetLeft });
      catalogStore.updateSelectedItem({ value: contentItem, rowId: id });
      customEventsService.setWheelAction({ dec: 'left', inc: 'right' });
    };

    const onHistorySelect = (query) => (input.value = query);

    const onSelectPoster = async (item, id, index) => {
      catalogStore.updateSelectedItem({ value: item, rowId: id });

      if (isAuth.value && input.value && input.value?.length >= 3 && searchContentItems.value.length) {
        const query = input.value;
        const preparedQuery = query.replace(' ', '');
        const historyQueries = history.value.map(({ query }) => query.replace(' ', ''));

        if (!historyQueries.filter((value) => value.includes(preparedQuery) || preparedQuery.includes(value)).length) {
          await searchService.setSearchHistory({ query: input.value, resultCount: searchContentItems.value.length });
        }
      }

      return openContentPage({
        title: item.title,
        contentType: item.contentType,
        from: ItemPageFrom.Search,
        searchText: input.value,
        numberInResults: index,
        id: item.id,
      });
    };

    const shouldUpdateContent = ref(true);

    const isSearchActive = ref(true);
    const isContentFound = ref(true);
    const shouldShowSuggestion = ref(false);
    const shouldShowSkeleton = computed(() => isSearchActive.value);

    const shouldShowNotFound = computed(() => !shouldShowSkeleton.value && !isContentFound.value);

    const shouldShowFound = computed(
      () => input.value && !shouldShowSuggestion.value && !shouldShowSkeleton.value && isContentFound.value,
    );

    const searchContent = debounce(async (text) => {
      searchContentItems.value = (await searchService.searchByText({ text })) || [];

      isSearchActive.value = false;
    }, ConstantsConfigInstanceSmartTV.getProperty('debounceTimeoutLegacyMs'));

    let isMounted = false;

    const onReloadContent = async () => {
      try {
        history.value = await searchService.getSearchHistory({ page: 1, perPage: 4 });
      } finally {
        isMounted = true;

        isSearchActive.value = false;
        shouldShowSuggestion.value = true;
      }
    };

    onDeactivated(() => {
      customEventsService.setWheelAction({ dec: 'up', inc: 'down' });
    });

    onMounted(async () => {
      catalogStore.updateSelectedItem(null);

      fetchMovies();

      focusSelf();

      if (isAuth.value) {
        history.value = await searchService.getSearchHistory({ page: 1, perPage: 4 });
      }

      await onReloadContent();
    });

    onBeforeUnmount(() => {
      disposableStore.dispose();
    });

    watch(input, async () => {
      if (!input.value?.length) {
        isSearchActive.value = false;
        searchContentItems.value = [];
        await onReloadContent();
        return;
      }

      isSearchActive.value = true;
      shouldShowSuggestion.value = false;
      searchContent(input.value);
    });

    return {
      el,
      items,
      AppButton,
      scrollerEl,
      isKeyboardHidden,
      input,
      type,
      searchContentItems,
      onActivateKeyboard,
      onActivateHistory,
      onHistorySelect,
      history,
      shouldShowSkeleton,
      shouldShowNotFound,
      shouldShowFound,
      shouldShowSuggestion,
      activeItemId,
      shouldUpdateContent,
      onActivatePoster,
      onSelectPoster,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.page {
  display: flex;
  flex-direction: column;
  margin-left: adjustPx(140px);
  padding-top: adjustPx(60px);
}

.title {
  @include f-subtitle-2;
}

.search {
  display: flex;
  flex-flow: row;
  margin-top: adjustPx(20px);
  margin-bottom: adjustPx(40px);
  margin-left: adjustPx(45px);
}

.content {
  margin-left: adjustPx(45px);
  position: relative;
}

.types {
  display: flex;
  flex-flow: row;
  margin-left: adjustPx(45px);

  &Button {
    margin: adjustPx(16px);
  }
}

.history {
  display: flex;
  flex-flow: column;
  max-height: adjustPx(300px);

  margin-top: adjustPx(110px);
  margin-left: adjustPx(20px);
  overflow: hidden;

  &Button {
    display: flex;
    margin: adjustPx(3px) !important;
    padding: adjustPx(18px) adjustPx(24px);
    width: adjustPx(521px);
    max-height: adjustPx(68px);
    border-radius: adjustPx(16px);
    border: none;
    background-color: transparent;
    justify-content: flex-start;
    min-height: adjustPx(68px);
  }

  .active {
    background-color: var(--color-bg-accent);
  }

  .text {
    display: block;
    width: 100%;
    text-align: start;

    @include f-caption;
  }
}

.posters {
  display: flex;
  margin-top: adjustPx(36px);
}
</style>
