<template>
  <section>
    <div v-if="shouldShowPlaylists" :class="[$style.playlists, $style.fade]"></div>
    <div v-if="shouldShowMoments" :class="[$style.moments, $style.fade]"></div>
    <template v-if="shouldShowPoster">
      <div :class="[$style.poster, $style.fade]"></div>
      <div :class="[$style.title, $style.fade]"></div>
      <div :class="[$style.subtitle, $style.fade]"></div>
      <div :class="[$style.play, $style.fade]"></div>
      <div :class="[$style.about, $style.fade]"></div>
    </template>
  </section>
</template>

<script>
export default {
  props: {
    shouldShowPlaylists: Boolean,
    shouldShowMoments: Boolean,
    shouldShowPoster: Boolean,
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.fade {
  opacity: 0;
  animation-name: pulse;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@mixin skeleton {
  z-index: map-get($map: $layers, $key: --z-index-wrapper);
  border-radius: adjustPx(24px);
  background: var(--color-notheme-bg-secondary-80);
}

.playlists {
  position: fixed;
  top: adjustPx(48px);
  right: adjustPx(160px);
  width: adjustPx(604px);
  height: adjustPx(96px);

  @include skeleton;
}

.moments {
  position: fixed;
  top: adjustPx(48px);
  right: adjustPx(48px);
  width: adjustPx(96px);
  height: adjustPx(96px);

  @include skeleton;
}

.poster {
  position: fixed;
  bottom: adjustPx(48px);
  left: adjustPx(161px);
  width: adjustPx(160px);
  height: adjustPx(240px);
  @include skeleton;
}

.title {
  position: fixed;
  bottom: adjustPx(244px);
  left: adjustPx(353px);
  width: adjustPx(240px);
  height: adjustPx(32px);
  @include skeleton;
}

.subtitle {
  position: fixed;
  bottom: adjustPx(196px);
  left: adjustPx(353px);
  width: adjustPx(141px);
  height: adjustPx(24px);
  @include skeleton;
}

.play {
  position: fixed;
  bottom: adjustPx(48px);
  left: adjustPx(353px);
  width: adjustPx(252px);
  height: adjustPx(80px);
  @include skeleton;
}

.about {
  position: fixed;
  bottom: adjustPx(48px);
  left: adjustPx(630px);
  width: adjustPx(254px);
  height: adjustPx(80px);
  @include skeleton;
}
</style>
