<template>
  <div :class="$style.tabs">
    <div
      role="tablist"
      :class="{
        [$style.tabsList]: true,
        [$style.tabsSecondary]: variant === 'secondary',
      }"
    >
      <slot :tab-index="tabIndex" :on-tab-changed="onTabChanged" name="tabs" />
    </div>

    <div role="tabpanel" :class="$style.tabsPanel">
      <slot name="items" :tab-index="tabIndex"></slot>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';

export default {
  props: {
    tab: { default: 0 },
    variant: { type: String, default: 'primary' },
  },
  setup(props, { emit }) {
    const index = computed({
      get: () => props.tab,
      set: (value) => emit('update:tab', value),
    });

    const tabIndex = ref(props.tab ?? 0);

    const onTabChanged = (value) => {
      tabIndex.value = value;
      index.value = value;
      emit('update:tab', value);
    };
    return { index, tabIndex, onTabChanged };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.tabs {
  display: flex;
  flex-direction: column;
  height: fit-content;

  &List {
    display: flex;
    align-items: center;
    padding: adjustPx(6px) !important;
    width: max-content;
    height: adjustPx(71px) !important;
    border-radius: adjustPx(28px) !important;
    border: adjustPx(3px) solid var(--color-stroke-field-border) !important;
    color: var(--color-text-primary) !important;
    justify-content: center;
    cursor: pointer;

    @include f-subtitle-2;
  }

  &Secondary {
    padding: 0 !important;
    border: none !important;
  }

  &Panel {
    display: flex !important;
    color: var(--color-text-primary) !important;

    @include f-subtitle-2;
  }
}
</style>
