import { ProcessingMapper } from '../base/processing-mapper';
import { BonusMapper } from '../bonus-programs/bonus-mapper';
import { PaymentMethodMapper } from '../payments/payment-method';
import { ParentControlMapper } from '../profiles/parent-control';
import { ProfilesMapper } from '../profiles/profiles';
import { DeviceInfoMapper } from '../session/device-info';
import { SubscriptionMapper } from '../subscriptions/subscription';
import type { User } from './types/session-info';
import type { ApiUser } from './user-types';

export class UserMapper {
  static map({
    auth_providers,
    payment_methods,
    phone_number,
    unconfirmed_email,
    unconfirmed_phone_number,
    current_profile_id,
    user_type,
    subscription,
    parental_control,
    allowed_delete_account,
    allowed_manage_subscription,
    profiles,
    bonuses,
    current_device,
    ...rest
  }: ApiUser): User {
    return ProcessingMapper.process({
      currentDevice: DeviceInfoMapper.map(current_device),
      allowedDeleteAccount: allowed_delete_account,
      userType: user_type,
      phoneNumber: phone_number,
      unconfirmedEmail: unconfirmed_email,
      unconfirmedPhoneNumber: unconfirmed_phone_number,
      authProviders: auth_providers,
      paymentMethods: PaymentMethodMapper.mapMany(payment_methods || []),
      currentProfileId: current_profile_id,
      subscription: subscription ? SubscriptionMapper.map(subscription) : undefined,
      parentalControl: parental_control ? ParentControlMapper.map(parental_control) : undefined,
      profiles: ProfilesMapper.mapMany(profiles || []),
      bonuses: BonusMapper.mapMany(bonuses || []),
      allowedManageSubscription: allowed_manage_subscription,
      ...rest,
    });
  }
}
