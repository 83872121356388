type PerformanceFeatureItemDetails = { fromVersion: number };

export type PerformanceFeatureItem = PerformanceFeatureItemDetails | null;

export type PerformanceFeatureType =
  | 'hasShakaApiEnabled'
  | 'speechRecognitionApi'
  | 'backdropFilter'
  | 'yandexMetrica'
  | 'transitionComponent'
  | 'backgroundPlayerOnContentPage'
  | 'backgroundPlayerOnMainPage';

export class PerformanceFeatureMap {
  public get features(): Record<PerformanceFeatureType, PerformanceFeatureItem> {
    return {
      speechRecognitionApi: this.speechRecognitionApi(),
      backdropFilter: this.backdropFilter(),
      yandexMetrica: this.yandexMetrica(),
      transitionComponent: this.transitionComponent(),
      backgroundPlayerOnMainPage: this.backgroundPlayerOnMainPage(),
      backgroundPlayerOnContentPage: this.backgroundPlayerOnContentPage(),
      hasShakaApiEnabled: this.hasShakaApiEnabled(),
    };
  }

  public backgroundPlayerOnContentPage(): PerformanceFeatureItem {
    return {
      fromVersion: 49,
    };
  }

  public backgroundPlayerOnMainPage(): PerformanceFeatureItem {
    return null;
  }

  public speechRecognitionApi(): PerformanceFeatureItem {
    return {
      fromVersion: 67,
    };
  }

  public hasShakaApiEnabled(): PerformanceFeatureItem {
    return {
      fromVersion: 76,
    };
  }

  public transitionComponent(): PerformanceFeatureItem {
    return null;
  }

  public backdropFilter(): PerformanceFeatureItem {
    return {
      fromVersion: 76,
    };
  }

  public yandexMetrica(): PerformanceFeatureItem {
    return {
      fromVersion: 76,
    };
  }
}

export class TizenPerformanceFeaturesMap extends PerformanceFeatureMap {
  public backdropFilter(): PerformanceFeatureItem {
    return null;
  }

  public speechRecognitionApi(): PerformanceFeatureItem {
    return null;
  }
}

export class WebosFeaturesMap extends PerformanceFeatureMap {
  public backdropFilter(): PerformanceFeatureItem {
    return null;
  }

  public backgroundPlayerOnContentPage(): PerformanceFeatureItem {
    return null;
  }

  public speechRecognitionApi(): PerformanceFeatureItem {
    return {
      fromVersion: 78,
    };
  }
}

export class VidaaFeatureMap extends PerformanceFeatureMap {
  public backdropFilter(): PerformanceFeatureItem {
    return null;
  }
}
