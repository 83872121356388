<template>
  <img v-if="!shouldShowFallback" ref="imageEl" v-bind="imgProps" />
  <IconPhoto v-else :class="$style.fallback" />
</template>

<script>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { observeImageElement } from '@package/smarttv-base/src/utils/lazy-loading-images';
import IconPhoto from '@SMART/assets/icons/51x51/photo.svg?inline';

export default {
  components: {
    IconPhoto,
  },
  props: {
    width: {
      type: Number,
      default: 200,
    },
    src: {
      type: String,
    },
    alt: {
      type: String,
    },
    loading: {
      type: String,
      validator: function (value) {
        return value === 'lazy';
      },
    },
    timeoutLoadingMs: {
      type: Number,
      default: 500,
      required: false,
    },
  },
  data: () => ({
    /**
     * @type {boolean}
     */
    hasError: false,
  }),
  computed: {
    shouldShowFallback() {
      return !this.src || this.hasError;
    },
    normalizedSrc() {
      const { src, width } = this;

      if (!this.width) {
        return src;
      }

      return `${src}?w=${width}`;
    },
    isLazyLoading() {
      return this.loading === 'lazy';
    },
    imgProps() {
      const props = { alt: this.alt };

      if (this.isLazyLoading) {
        props.loading = this.loading;
        props['data-src'] = this.normalizedSrc;
      } else {
        props.src = this.normalizedSrc;
      }

      return props;
    },
  },
  watch: {
    src() {
      this.hasError = false;
    },
  },
  methods: {
    handleError() {
      this.hasError = true;
    },
  },
  async mounted() {
    await this.$nextTick();

    const imageEl = this.$refs.imageEl;

    if (!imageEl) {
      return console.error(new UnexpectedComponentStateError('imageEl'));
    }

    if (this.isLazyLoading) {
      observeImageElement(imageEl);
    }

    imageEl.addEventListener('error', this.handleError);
  },
  beforeUnmount() {
    const imageEl = this.$refs.imagelEl;

    if (!imageEl) {
      return console.error(new UnexpectedComponentStateError('imageEl'));
    }

    imageEl.removeEventListener('error', this.handleError);
  },
};
</script>

<style lang="scss" module>
.fallback {
  transform: none !important;
  width: 53px !important;
  height: 45px !important;
  border-radius: 0;
  opacity: 0.3;
  align-self: center;
}
</style>
