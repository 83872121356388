<template>
  <div :class="$style.wrapper">
    <article ref="el" :class="$style.settings">
      <section :class="$style.navigation">
        <section>
          <NavigatableItem
            v-if="isMainProfile"
            :tag="AppButton"
            :class="$style.navigationButton"
            :text="$t('pages.settings.subscribeButton')"
            :active-class="$style.active"
            :on-click="() => onSelectSection(RouterPage.Subscriptions)"
          />
          <NavigatableItem
            :tag="AppButton"
            :class="$style.navigationButton"
            :active-class="$style.active"
            focus-key="PERSONAL_DATA"
            :text="$t('pages.settings.personDataButton')"
            :on-click="() => onSelectSection(RouterPage.PersonalData)"
          />
          <NavigatableItem
            :tag="AppButton"
            :class="$style.navigationButton"
            :active-class="$style.active"
            :text="$t('pages.settings.contactsButton')"
            :on-click="() => onSelectSection(RouterPage.HelpContacts)"
          />
          <NavigatableItem
            :tag="AppButton"
            :text="$t('pages.settings.parentControlButton')"
            :class="$style.navigationButton"
            :active-class="$style.active"
            :on-click="() => onSelectSection(RouterPage.ParentalControl)"
          />
        </section>
        <NavigatableItem
          :tag="AppButton"
          :text="$t('pages.settings.logoutButton')"
          :class="$style.navigationButton"
          :active-class="$style.active"
          :on-click="() => onLogout()"
        />
      </section>

      <section :class="$style.content">
        <router-view />
      </section>
    </article>
  </div>
</template>

<script>
import { ProfileType } from '@package/sdk/src/api';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { authService, RouterPage, routerService, storeToRefs, useSessionStore } from '@SMART/index';
import { computed, defineComponent, onMounted, provide } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';

export default defineComponent({
  setup() {
    const sessionStore = useSessionStore();
    const { profile } = storeToRefs(sessionStore);
    const isMainProfile = computed(() => profile.value?.kind === ProfileType.MAIN);

    const { el, focusSelf, focusKey } = useNavigatable({
      focusKey: 'SETTINGS_PAGE',
      forceFocus: true,
      trackChildren: true,
    });
    provide('parentFocusKey', focusKey.value);

    const onSelectSection = (name) => routerService.push({ name });

    const onLogout = async () => {
      await authService.signOut();

      return routerService.push({ name: RouterPage.MainPage });
    };

    onMounted(async () => {
      focusSelf();

      await sessionStore.fetchUser({ forceFetchUser: true });
    });

    return {
      el,
      onSelectSection,
      onLogout,
      AppButton,
      RouterPage,
      isMainProfile,
    };
  },
});
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: adjustPx(140px);
}

.settings {
  display: flex;
  height: 100%;
}

.navigation {
  display: flex;
  align-items: flex-start;
  padding: adjustPx(60px) 0 adjustPx(68px) 0;
  width: adjustPx(544px);
  height: 100%;
  background: var(--color-states-bg-secondary-pressed);
  flex-flow: column;
  justify-content: space-between;

  &Button {
    justify-content: flex-start;
    margin: adjustPx(5px) adjustPx(40px) !important;
    padding: adjustPx(18px) adjustPx(22px);
    width: adjustPx(466px);
    border-radius: adjustPx(24px);
    border: adjustPx(4px) solid;
    border-color: transparent;
    background-color: transparent;
    min-height: adjustPx(83px);
    text-align: start;

    @include f-body;

    &:not(:disabled):hover,
    &:not(:disabled):active {
      border-color: var(--color-bg-accent);
    }
  }
}

.content {
  display: flex;
  flex-flow: column;
  padding: adjustPx(90px);
}

.active {
  border-color: var(--color-bg-accent);
  background-color: transparent;
}
</style>
