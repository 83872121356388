<template>
  <div ref="el" :class="$style.container">
    <h1>{{ $t(`${translationKeys}.header`, { login: maskedLogin }) }}</h1>
    <div :class="$style.containerInput">
      <EmailInput
        :step="step"
        :login="login"
        :show-password="showPassword"
        :is-loading="isLoading"
        @step:back="onPrevStep()"
        @step:up="onInput"
        @finish="onFinish"
      />

      <EmailTerms v-if="showEmailTerms" />
    </div>
    <section :class="$style.steps">
      <NavigatableItem
        v-if="showChangeLoginMethod"
        :tag="AppButton"
        :class="$style.stepsButton"
        :text="$t(`${translationKeys}.changeLoginMethodButton`)"
        :on-click="onChangeLoginMethod"
      />
      <NavigatableItem
        v-if="step === Steps.SignInLogin"
        :tag="AppButton"
        :class="$style.stepsButton"
        :text="$t(`${translationKeys}.signUpButton`)"
        :on-click="() => onNextStep(Steps.SingUpLogin)"
      />
      <NavigatableItem
        v-if="step === Steps.SignInPassword"
        :tag="AppButton"
        :class="$style.stepsButton"
        :text="$t(`${translationKeys}.forgetPasswordButton`)"
        :on-click="onForgetPassword"
      />
      <NavigatableItem
        v-if="showChangeInputType"
        :tag="AppButton"
        :class="$style.stepsButton"
        :text="$t(`${translationKeys}.showPasswordButton`)"
        :on-click="onTogglePassword"
      />
      <NavigatableItem
        v-if="step === Steps.RecoverCode"
        :tag="AppButton"
        :class="$style.stepsButton"
        :text="
          codeResendTime
            ? $t(`${translationKeys}.resendOtpButton`, { codeResendTime })
            : $t(`${translationKeys}.sendPasswordButton`)
        "
        :disabled="!!codeResendTime"
        :on-click="onResendOtp"
      />
    </section>
  </div>
</template>

<script>
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  AlertMessageTypes,
  alertService,
  authService,
  getPhoneMask,
  inputMask,
  isEmailLogin,
  isPhoneLogin,
  RouterPage,
  routerService,
  storeToRefs,
  translate,
  useAuthActions,
  userService,
  useSessionStore,
} from '@SMART/index';
import { computed, onMounted, onUnmounted, provide, ref, watch } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';

import EmailInput from './EmailInput.vue';
import EmailTerms from './EmailTerms.vue';
import { Steps } from './EmailTypes';

export default {
  components: {
    EmailInput,
    EmailTerms,
  },
  setup(props, { emit, root: { $route: route } }) {
    const params = route.params;

    const userStore = useSessionStore();
    const { _user } = storeToRefs(useSessionStore());

    const { getRedirectRouterAfterAuthorization, redirectUserWithBackUrl } = useAuthActions();

    const { el, focusKey, focusSelf } = useNavigatable({
      focusKey: 'EMAIL_CONTAINER',
      forceFocus: true,
      saveLastFocusedChild: true,
      autoRestoreFocus: true,
    });
    provide('parentFocusKey', focusKey.value);

    onMounted(() => focusSelf());

    const isPinRecover = computed(() => params.variant === 'pin_recover');
    const login = ref('');
    const input = ref('');
    const error = ref('');

    /**
     * @type {Ref<UnwrapRef<Steps[]>>}
     */
    const steps = ref([isPinRecover.value ? Steps.SignInPassword : Steps.SignInLogin]);

    const isLoading = ref(false);

    /**
     *
     * @type {ComputedRef<Steps>}
     */
    const step = computed(() => steps.value[steps.value.length - 1]);

    watch(step, () => focusSelf());

    const showEmailTerms = computed(() => [Steps.SingUpLogin, Steps.SignUpPassword].includes(step.value));
    const showChangeLoginMethod = computed(() => [Steps.SingUpLogin, Steps.SignInLogin].includes(step.value));

    const showChangeInputType = computed(() =>
      [Steps.SignInPassword, Steps.SignUpPassword, Steps.RecoverPassword].includes(step.value),
    );

    const translationKeys = computed(() => `pages.auth.${step.value}`);

    const showPassword = ref(false);

    const onTogglePassword = () => {
      showPassword.value = !showPassword.value;
    };

    let otp = '';

    const redirect = async () => {
      await userStore.fetchUser();

      const route = getRedirectRouterAfterAuthorization();

      return redirectUserWithBackUrl(route);
    };

    const isLoginValid = computed(() => {
      if (!isNaN(parseInt(login.value))) {
        return isPhoneLogin(login.value);
      }

      return isEmailLogin(login.value);
    });

    const checkIsUserPresence = async () => {
      isLoading.value = true;

      const isEmail = isEmailLogin(login.value);

      const userExist = await userService.fetchUserPresence({
        ...(!isEmail && { phoneNumber: login.value }),
        ...(isEmail && { email: login.value }),
      });

      isLoading.value = false;

      return userExist;
    };

    const onNextStep = async (next) => {
      try {
        let nextState;

        error.value = '';

        if (next) {
          nextState = next;
          input.value = '';

          if (next === Steps.SingUpLogin && login.value && isLoginValid.value) {
            steps.value.push(Steps.SingUpLogin);
            input.value = login.value;
            await onNextStep();

            return;
          }

          if (nextState) {
            steps.value.push(nextState);
          }
          return;
        }

        const signInLogin = async () => {
          login.value = input.value;

          const isUserExist = await checkIsUserPresence();

          if (!isUserExist) {
            const isEmail = isEmailLogin(login.value);

            const message = translate(`pages.auth.errorMessage.${isEmail ? 'email' : 'phone'}NotRegistered`);

            alertService.addAlert({ message, type: AlertMessageTypes.Error });

            login.value = '';
            return Steps.SignInLogin;
          }

          input.value = '';
          return Steps.SignInPassword;
        };

        const doSignUpLogin = async () => {
          login.value = input.value;

          const isUserExist = await checkIsUserPresence();

          if (isUserExist) {
            const isEmail = isEmailLogin(login.value);

            const message = translate(`pages.auth.errorMessage.${isEmail ? 'email' : 'phone'}Registered`);

            alertService.addAlert({ message, type: AlertMessageTypes.Error });

            login.value = '';

            return Steps.SignInLogin;
          }

          login.value = input.value;
          input.value = '';

          return Steps.SignUpPassword;
        };

        const doSignUpPassword = async () => {
          await authService.signUp({
            userLogin: login.value,
            password: input.value,
          });
          return Steps.SignUpPassword;
        };

        const requestRecoverCode = async () => {
          if (!login.value) {
            console.error(new UnexpectedComponentStateError('login'));
            return Steps.RecoverCode;
          }

          otp = input.value;

          await authService.signIn({
            userLogin: login.value,
            otp: input.value,
          });
          return Steps.RecoverPassword;
        };

        const isEmail = isEmailLogin(login.value);
        const userLogin = isPinRecover.value ? _user?.value?.email || _user?.value?.phoneNumber : login.value;

        switch (step.value) {
          case Steps.SignInLogin:
            nextState = await signInLogin();
            break;
          case Steps.SingUpLogin:
            nextState = await doSignUpLogin();
            break;
          case Steps.SignUpPassword:
            nextState = await doSignUpPassword();

            return redirect();
          case Steps.SignInPassword:
            await authService.signIn({
              userLogin,
              password: input.value,
            });

            if (isPinRecover.value) {
              return routerService.push({ name: RouterPage.ParentalCodePage, params: { variant: 'creation' } });
            }

            return redirect();
          case Steps.RecoverCode:
            nextState = await requestRecoverCode();
            break;
          case Steps.RecoverPassword:
            await userService.updateUser({
              otp,
              password: input.value,
              ...(!isEmail && { phoneNumber: login.value }),
              ...(isEmail && { email: login.value }),
            });
            return redirect();
          default:
            nextState = Steps.SignInLogin;
            break;
        }

        if (nextState) {
          steps.value.push(nextState);
        }
      } catch (error) {
        alertService.addAlert({ message: error.message ?? error, type: AlertMessageTypes.Error });
      }
    };

    const codeResendTimeSecond = ConstantsConfigInstanceSmartTV.getProperty('resendOtpCodeTimeoutSeconds');
    const codeResendTime = ref(codeResendTimeSecond);

    let codeResendInterval;

    const startResendInterval = () => {
      if (codeResendInterval) {
        window.clearInterval(codeResendInterval);
      }

      codeResendTime.value = codeResendTimeSecond;

      codeResendInterval = window.setInterval(() => {
        if (codeResendTime.value) {
          codeResendTime.value -= 1;
        } else if (codeResendInterval) {
          window.clearInterval(codeResendInterval);
        }
      }, ConstantsConfigInstanceSmartTV.getProperty('resendPasswordTimeoutMs'));
    };

    const onResendOtp = async () => {
      try {
        startResendInterval();
        await authService.sendOtp(login.value);
      } catch (e) {
        alertService.addAlert({ message: e.message ?? e, type: AlertMessageTypes.Error });
      }
    };

    const onForgetPassword = async () => {
      startResendInterval();

      input.value = '';

      await onNextStep(Steps.RecoverCode);

      await onResendOtp();
    };

    const onInput = async (value) => {
      input.value = value;
      await onNextStep();
    };

    const onPrevStep = async () => {
      if (step.value !== Steps.RecoverCode) {
        input.value = '';
        login.value = '';
      }

      if (steps.value.length > 1) {
        const currentStep = steps.value.pop();

        if (currentStep === Steps.RecoverPassword) {
          await onResendOtp();
        }
      }
    };

    const onChangeLoginMethod = () => {
      switch (step.value) {
        case Steps.SingUpLogin:
        case Steps.SignUpPassword:
          onNextStep(Steps.SignInLogin);
          break;
        case Steps.SignInLogin:
        case Steps.SignInPassword:
          emit('update:type', 'qr');
          break;
        default:
          break;
      }
    };

    const maskedLogin = computed(() =>
      isNaN(parseInt(login.value)) ? login.value : inputMask(getPhoneMask(login.value), login.value),
    );

    const onFinish = () => {
      emit('update:type', 'qr');
    };

    onUnmounted(() => {
      if (codeResendInterval) {
        window.clearInterval(codeResendInterval);
      }

      userService.abort();
      authService.abort();
      alertService.closeAlert(0);
    });

    return {
      onChangeLoginMethod,
      maskedLogin,
      onPrevStep,
      onInput,
      onForgetPassword,
      onResendOtp,
      startResendInterval,
      codeResendTime,
      onNextStep,
      redirect,
      onTogglePassword,
      showPassword,
      translationKeys,
      showChangeInputType,
      showChangeLoginMethod,
      showEmailTerms,
      step,
      isLoading,
      login,
      el,
      AppButton,
      Steps,
      onFinish,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';

.container {
  display: flex;
  flex-flow: column;
  height: adjustPx(1080px) - adjustPx(100px);

  &Input {
    display: flex;
    min-height: adjustPx(500px);
    margin-top: adjustPx(24px);
  }
}

.steps {
  margin-top: adjustPx(144px);

  &Button {
    display: inline-flex;
    margin-right: adjustPx(24px);
    margin-left: adjustPx(6px);
  }
}
</style>
