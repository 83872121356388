export enum AnalyticPayloadLabelName {
  Mode = 'mode',
  ContainerSize = 'c_size',
  MaxQuality = 'max_quality',
  UserCap = 'usercap',
  Timeshift = 'timeshift',
  CurrentLang = 'current_lang',
  SelectedLang = 'selected_lang',
  DislikeType = 'dislike_type',
  ShareType = 'share_type',
  State = 'state',
  Position = 'position',
  Feed = 'feed',
  Button = 'button',
  ItemType = 'item_type',
  RandomItem = 'random_item',
  ItemTitle = 'item_title',
  Reasons = 'reasons',
  TitlesSelectionName = 'titles_selection_name',
  TitlesSelectionPosition = 'titles_selection_position',
  CompilationName = 'compilation_name',
  CompilationPosition = 'compilation_position',
  ItemCompilationPosition = 'item_compilation_position',
  Direction = 'direction',
  Duration = 'duration',
  StartTime = 'start_time',
  FinishTime = 'finish_time',
  KidsAccess = 'kids_access',
  AgeLimit = 'age_limit',
  ViewingTime = 'viewing_time',
  Offer = 'offer',
  OfferType = 'offer_type',
  Language = 'language',
  Auth = 'auth',
  UnsubscribeStage = 'stage',
  Device = 'device',
  Phone = 'phone',
  Email = 'email',
  Login = 'login',
  ListItemId = 'list_item_id',
  Request = 'request',
  SocialMedia = 'social_media',
  Promocode = 'promocode',
  Content = 'content',
  KinomTitle = 'kinom_title',
  ContentType = 'content_type',
  TimeStart = 'time_start',
  TimeDuration = 'time_duration',
  TimeEnd = 'time_end',
  PlaylistTitle = 'playlist_title',
  Quality = 'quality',
  Speed = 'speed',
  Tool = 'tool', // chromecast / airplay
  Season = 'season',
  Episode = 'episode',
  Volume = 'volume',
  LicenseType = 'license_type', // svod / stream
  TvStreamType = 'tv_stream_type', // live / timeshift
  WatchTime = 'watch_time',
  Genre = 'genre',
  PromoTitle = 'promo_title',
  DeviceTitle = 'device_title',
  NewValue = 'new_value',
  ChannelTitle = 'channel_title',
  Query = 'query',
  From = 'from',
  NumberInSearchResults = 'number_in_search_results',
  NumberInResults = 'number_in_results',
  Filter = 'filter',
  ItemId = 'item_id', // [] массив айдишников
  CurrentItemTitle = 'current_item_title',
  CurrentItemType = 'current_item_type',
}
