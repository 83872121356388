<template>
  <section :class="$style.skeleton">
    <div
      v-for="item in items"
      :key="item"
      :class="{
        [$style.poster]: true,
        [$style.sizeMedium]: size === 'medium',
        [$style.sizeSmall]: size === 'small',
        [$style.sizeKinom]: size === 'kinom',
      }"
    ></div>
  </section>
</template>

<script>
export default {
  props: {
    variant: {
      default: 'column',
    },
    size: {
      default: 'medium',
    },
    items: {
      default: 3,
    },
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

$border-width: adjustPx(7px);
$skeleton-background-size: adjustPx(300px);

.skeleton {
  display: flex;
  flex-flow: row;
  height: adjustPx(560px);
  margin-bottom: adjustPx(16px);

  &Item {
    margin-right: adjustPx(16px);
  }
}

.poster {
  display: flex;
  align-items: center;
  border-radius: adjustPx(40px);

  background: var(--color-bg-modal);
  flex-flow: column;
  justify-content: center;
  margin-right: adjustPx(16px);
  outline: none;
  cursor: pointer;
  animation-name: skeleton-animation;
  animation-duration: 1.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &Kinom {
    height: adjustPx(310px);
  }
}

.size {
  &Medium {
    width: adjustPx(413px);
    max-width: adjustPx(413px);
    height: adjustPx(560px);
  }

  &Small {
    width: adjustPx(240px);
    max-width: adjustPx(240px);
    height: adjustPx(336px);
  }

  &Kinom {
    width: adjustPx(413px);
    max-width: adjustPx(413px);
    height: adjustPx(310px);
    margin-right: adjustPx(16px);
    overflow: hidden;
  }
}

.variant {
  &Row {
    position: absolute;
    left: adjustPx(240px);
    overflow: visible;
  }

  &Column {
    position: absolute;
    max-width: adjustPx(413px);
    height: max-content;
    margin-top: adjustPx(-70px);
    overflow: hidden;
  }
}

@keyframes skeleton-animation {
  0% {
    background-position: -$skeleton-background-size;
  }
  100% {
    background-position: $skeleton-background-size;
  }
}
</style>
