var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:( _obj = {}, _obj[_vm.$style.appButton] = true, _obj[_vm.$style.appButtonNoTransition] = _vm.platform === 'legacy-smart-tv', _obj[_vm.$style.smartButton] = _vm.variation === 'smart-button-secondary' ||
      _vm.variation === 'smart-button-primary' ||
      _vm.variation === 'smart-player-button-primary', _obj[_vm.$style.button] = _vm.variation === 'button-primary' ||
      _vm.variation === 'button-secondary' ||
      _vm.variation === 'button-ghost' ||
      _vm.variation === 'player-button-primary' ||
      _vm.variation === 'smart-player-button-primary' ||
      _vm.variation === 'smart-button-secondary' ||
      _vm.variation === 'smart-button-primary', _obj[_vm.$style.link] = _vm.variation === 'link-primary' || _vm.variation === 'link-secondary' || _vm.variation === 'link-alert', _obj[_vm.$style.smartButtonPrimary] = _vm.variation === 'smart-player-button-primary' || _vm.variation === 'smart-button-primary', _obj[_vm.$style.smartButtonSecondary] = _vm.variation === 'smart-button-secondary', _obj[_vm.$style.playerButtonPrimary] = _vm.variation === 'player-button-primary', _obj[_vm.$style.primaryButton] = _vm.variation === 'button-primary', _obj[_vm.$style.secondaryButton] = _vm.variation === 'button-secondary', _obj[_vm.$style.primaryLink] = _vm.variation === 'link-primary', _obj[_vm.$style.secondaryLink] = _vm.variation === 'link-secondary', _obj[_vm.$style.alertLink] = _vm.variation === 'link-alert', _obj[_vm.$style.small] = _vm.size === 'small', _obj[_vm.$style.medium] = _vm.size === 'medium', _obj[_vm.$style.large] = _vm.size === 'large', _obj[_vm.$style.extraLarge] = _vm.size === 'extra-large', _obj[_vm.$style.appButtonFocused] = _vm.isFocused, _obj ),style:({
    transition: _vm.buttonTransitionStyle,
  }),attrs:{"tabindex":"0","title":_vm.title,"aria-label":_vm.ariaLabel,"type":_vm.type,"disabled":_vm.isDisabled}},[_c('transition',{attrs:{"name":"bounce"}},[_vm._t("loader")],2),(!_vm.isLoading)?_c('p',{class:_vm.$style.textWrapper,style:({ 'justify-content': _vm.textAlign })},[_vm._t("icon"),_c('span',{class:[_vm.textClassName, ( _obj$1 = {}, _obj$1[_vm.$style.text] = true, _obj$1 )]},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_vm._t("icon-right")],2):_c('p',{class:_vm.$style.textWrapper,style:({ 'justify-content': _vm.textAlign })},[_vm._t("icon"),_c('span',{class:[_vm.textClassName, ( _obj$2 = {}, _obj$2[_vm.$style.hidden] = true, _obj$2 )]},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_vm._t("icon-right")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }