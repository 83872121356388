<template>
  <div class="page-container">
    <QrLogin v-if="type === 'qr'" @update:type="onTypeChange" />
    <EmailContainer v-if="type === 'email'" @update:type="onTypeChange" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

import EmailContainer from './components/EmailContainer.vue';
import QrLogin from './components/QrLogin.vue';

export default {
  components: {
    EmailContainer,
    QrLogin,
  },
  setup(props, { root: { $route: route } }) {
    const params = route.params;

    const type = ref(params.type || 'qr');

    const onTypeChange = (value) => {
      type.value = value;
    };

    return {
      type,
      onTypeChange,
    };
  },
};
</script>
