import { ProcessingMapper } from '../base/processing-mapper';
import type { ApiMedia } from '../content/content-types/media';
import { MediaMapper } from '../content/media';
import { MomentMapper } from '../moments/moment';
import type { ApiMoment } from '../moments/moment-types/moment';
import type { ApiCollectionItem } from '../user-collection/collection';
import { CollectionContentType } from '../user-collection/collection-content-type';
import type { CollectionItem } from '../user-collection/collection-item';

export class CollectionItemMapper {
  static mapMany(items: ApiCollectionItem[]): CollectionItem[] {
    return ProcessingMapper.process(items.map(CollectionItemMapper.map), { freezeData: false });
  }

  static map({ id, content, content_type }: ApiCollectionItem): CollectionItem {
    const isMediaItem = content_type === CollectionContentType.Content;

    return ProcessingMapper.process(
      {
        id,
        contentType: content_type,
        content: isMediaItem ? MediaMapper.map(content as ApiMedia) : MomentMapper.map(content as ApiMoment),
      },
      { freezeData: false },
    );
  }
}
