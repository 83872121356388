<template>
  <section :class="$style.scroller">
    <div ref="scrollerRef" :class="$style.scrollerText">
      <slot></slot>
    </div>
    <div :class="$style.scrollerButtons">
      <NavigatableItem
        :prop-parent-focus-key="parentFocusKey"
        :tag="UIButton"
        :class="$style.scrollerButton"
        :on-click="() => onScroll(-50)"
      >
        <template v-slot:icon><ArrowUpIcon /></template>
      </NavigatableItem>
      <NavigatableItem
        :prop-parent-focus-key="parentFocusKey"
        :tag="UIButton"
        :class="$style.scrollerButton"
        :on-click="() => onScroll(+50)"
      >
        <template v-slot:icon><AlertDownIcon /></template>
      </NavigatableItem>
    </div>
  </section>
</template>

<script>
import AlertDownIcon from '@SMART/assets/icons/51x51/arrow-down.svg';
import ArrowUpIcon from '@SMART/assets/icons/51x51/arrow-up.svg';
import { ref } from '@vue/composition-api';

import UIButton from '@/components/button/UIButton.vue';

export default {
  components: { AlertDownIcon, ArrowUpIcon },
  props: {
    parentFocusKey: { type: String, required: false },
  },
  setup() {
    const scrollerRef = ref();

    const onScroll = (px) => {
      if (scrollerRef.value) {
        window.requestAnimationFrame(() => {
          if (!scrollerRef.value) {
            return;
          }

          scrollerRef.value.scrollTop = scrollerRef.value.scrollTop + px;
        });
      }
    };

    return { scrollerRef, onScroll, UIButton };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';

.scroller {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &Buttons {
    display: flex;
    flex-flow: column;
    margin-left: adjustPx(61px);
  }

  &Button {
    display: flex;
    align-items: center;
    padding: 0;
    width: adjustPx(100px);
    height: adjustPx(100px);
    border-radius: adjustPx(50px);
    justify-content: center;
    min-width: adjustPx(100px);
    min-height: adjustPx(100px);
    margin-top: adjustPx(23px);
  }

  &Text {
    max-width: adjustPx(1500px);
    max-height: adjustPx(800px);
    overflow: auto;
  }
}
</style>
