<template>
  <section :class="$style.input">
    <section :class="$style.inputButtons">
      <NavigatableItem
        :tag="AppButton"
        :class="$style.inputButton"
        :disabled="!canGoNext"
        :text="$t(`${translationKeys}.nextButton`)"
        :on-click="onNextStep"
      />
      <NavigatableItem
        v-if="step !== Steps.SignInLogin"
        :tag="AppButton"
        :class="$style.inputButton"
        :text="$t(`${translationKeys}.backButton`)"
        :on-click="onPrevStep"
      />
    </section>
    <VirtualKeyboard
      ref="keyboardRef"
      lang="en"
      :placeholder="placeholder ? $t(placeholder) : ''"
      :suggestions="suggestions"
      :on-concat-suggestion="onConcatSuggestion"
      :input-type="inputType"
      :input-mask="mask"
      :input="input"
      :type="type"
      @update:type="type = $event"
      @update:input="input = $event"
    >
      <template v-if="step === Steps.RecoverCode" v-slot:input="{ input }">
        <CodeInput :input="input" :class="$style.inputCode" disabled />
      </template>
    </VirtualKeyboard>
    <h3 v-show="login && step !== Steps.RecoverCode" :class="$style.inputLogin">
      {{ maskedLogin }}
    </h3>
  </section>
</template>

<script>
import {
  customEventsService,
  getEmailSuggestions,
  getPhoneMask,
  inputMask,
  isEmailLogin,
  isPhoneLogin,
} from '@SMART/index';
import { computed, onMounted, ref, watch } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import CodeInput from '@/components/code-input/CodeInput.vue';
import VirtualKeyboard from '@/components/virtual-keyboard/VirtualKeyboard.vue';

import { Steps } from './EmailTypes';

const EMAIL_SUGGESTIONS = getEmailSuggestions();

export default {
  components: {
    CodeInput,
    VirtualKeyboard,
  },
  props: {
    step: {
      type: String,
    },
    login: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
    showPassword: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const input = ref('');

    const type = ref(props.step === Steps.RecoverCode ? 'number' : 'letter');
    const loginSteps = [Steps.SignInLogin, Steps.SingUpLogin];

    watch(
      () => props.step,
      (step) => {
        type.value = 'letter';

        if (step === Steps.RecoverCode) {
          type.value = 'number';
          input.value = '';
        }

        if (step === Steps.SignUpPassword) {
          input.value = '';
        }

        if (loginSteps.includes(step) && isPhoneLogin(input.value)) {
          type.value = 'number';
        }

        if (loginSteps.includes(step) && isEmailLogin(input.value)) {
          type.value = 'letter';
        }
      },
      { immediate: true },
    );

    const mask = computed(() =>
      [Steps.SignInLogin, Steps.SingUpLogin].includes(props.step) &&
      type.value === 'number' &&
      input.value?.length &&
      !isNaN(input.value)
        ? getPhoneMask(input.value)
        : '',
    );

    const maskedLogin = computed(() =>
      isEmailLogin(props.login) ? props.login : inputMask(getPhoneMask(props.login), props.login),
    );

    const translationKeys = computed(() => `pages.auth.${props.step}`);

    const placeholder = computed(() => {
      if (props.isLoading) {
        return '';
      }

      let key = 'emailPlaceholder';

      if ([Steps.SignInPassword, Steps.SignUpPassword, Steps.RecoverPassword].includes(props.step)) {
        key = 'passwordPlaceholder';
      } else if (props.step === Steps.RecoverCode) {
        key = 'codePlaceholder';
      }

      return `${translationKeys.value}.${key}`;
    });

    const suggestions = computed(() => {
      if (input.value?.includes('@')) {
        return EMAIL_SUGGESTIONS;
      }

      return [];
    });

    const inputType = computed(() => {
      if (props.showPassword) {
        return 'text';
      } else if ([Steps.SignInPassword, Steps.SignUpPassword, Steps.RecoverPassword].includes(props.step)) {
        return 'password';
      }

      return 'text';
    });

    const isLoginValid = computed(() => {
      if (type.value === 'number') {
        return isPhoneLogin(input.value);
      }

      return isEmailLogin(input.value);
    });

    const canGoNext = computed(() => {
      if (!input.value) {
        return false;
      }

      if ([Steps.SignInLogin, Steps.SingUpLogin].includes(props.step)) {
        return isLoginValid.value;
      }

      return true;
    });

    const onConcatSuggestion = (input, suggestion) => {
      if (suggestion === '.com') {
        return input + suggestion;
      }

      const atIndex = input.lastIndexOf('@');

      return input.substring(0, atIndex) + suggestion;
    };

    const onNextStep = () => {
      emit('step:up', input.value);
      input.value = '';
    };

    const onPrevStep = () => {
      input.value = '';

      if (props.step !== Steps.RecoverCode) {
        input.value = props.login;
      }

      emit('step:back');
    };

    const onBackPress = () => {
      if (props.step === Steps.SignInLogin) {
        return emit('finish');
      }

      return onPrevStep();
    };

    onMounted(async () => {
      customEventsService.setOnPressBackCallback(onBackPress, true);
    });

    return {
      input,
      type,
      mask,
      maskedLogin,
      translationKeys,
      placeholder,
      suggestions,
      inputType,
      canGoNext,
      onConcatSuggestion,
      onNextStep,
      onPrevStep,
      onBackPress,
      AppButton,
      Steps,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';

.input {
  display: flex;
  flex-flow: column-reverse;

  &Login {
    margin-top: adjustPx(24px);
    margin-bottom: adjustPx(42px);
  }

  &Buttons {
    display: inline-flex;
    margin-top: adjustPx(60px);
    margin-left: adjustPx(6px);
  }

  &Button {
    margin-right: adjustPx(19px);
  }

  &Code {
    margin-bottom: adjustPx(42px);
  }
}
</style>
