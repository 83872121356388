import { ProcessingMapper } from '../base/processing-mapper';
import type { ApiOffer } from './offer-types';
import type { Offer } from './types/offer';

export class OfferMapper {
  static mapMany(offers: ApiOffer[]): Offer[] {
    return ProcessingMapper.process(offers.map(OfferMapper.map));
  }

  static map(api: ApiOffer): Offer {
    return ProcessingMapper.process({
      id: api.id,
      alternativeOffers: api.alternative_offers,
      badge: api.badge,
      bonusProgramHorizontalImage: api.bonus_program_horizontal_image,
      bonusProgramVerticalImage: api.bonus_program_vertical_image,
      cardBenefits: api.web_benefits || [],
      cardPrice: api.web_price,
      cardTitle: api.web_title,
      description: api.description,
      duration: api.duration,
      durationUnit: api.duration_unit,
      images: {
        lkSubscriptionBanner: api.images.lk_subscription_banner,
        lkSubscriptionBannerVertical: api.images.lk_subscription_banner_vertical,
        mainPageOfferHorizontal: api.images.main_page_offer_horizontal,
        mainPageOfferVertical: api.images.main_page_offer_vertical,
      },
      lkAutoRenewDate: api.web_lk_autorenew_date,
      lkDiscontinueDate: api.web_lk_discontinue_date,
      lkSubscriptionTitle: api.web_lk_subscription_title,
      markedBest: api.marked_best,
      platform: api.platform,
      platformSubscriptionId: api.platform_subscription_id,
      price: api.price,
      priceCurrency: api.price_currency,
      promoOfferTitle: api.promo_offer_title,
      promoOfferSubtitle: api.promo_offer_subtitle,
      texts: {
        mainPageBannerButton: api.texts.main_page_banner_button,
      },
      title: api.title,
      trialAvailable: api.trial_available,
      trialDuration: api.trial_duration,
      trialDurationUnit: api.trial_duration_unit,
    });
  }
}
