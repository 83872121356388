<template>
  <div v-if="creator" :class="$style.wrapper">{{ $t('pages.mediaCard.creator') }} {{ creator }}</div>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  props: {
    directors: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const creator = computed(() => {
      if (!props.directors?.length) {
        return '';
      }

      const director = props.directors[0];

      if (!director) {
        return '';
      }

      return director.name;
    });

    return {
      creator,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/colors';

.wrapper {
  color: var(--color-text-secondary);
}
</style>
