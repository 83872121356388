import type { ApiSerial } from './content-types/serial';
import { MediaMapper } from './media';
import { SeasonMapper } from './season';
import type { Serial } from './types/serial';

export class SerialMapper {
  static map(api: ApiSerial): Serial {
    return {
      ...MediaMapper.map(api),
      seasons: api.seasons?.map(SeasonMapper.map) || [],
      seasonsCount: api.seasons_count,
    };
  }

  static mapMany(api: ApiSerial[]): Serial[] {
    return api.map(SerialMapper.map);
  }
}
