import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticEventSender } from '../index';

export enum OfferPageFrom {
  WatchButton = 'watch_button',
  AfterFreeWatch = 'after_free_watch',
  ActionButton = 'action_button',
}

interface GotoOfferPagePayload {
  page: AnalyticPageName;
  from?: OfferPageFrom;
  itemId?: string;
  episodeId?: string;
  kinomId?: string;
}

export function useSubscribeAnalytics(sender: AnalyticEventSender) {
  const onShowSubscribeOfferPage = (offerType: string) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowSubscribeOfferPage,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.OfferType,
          value: offerType,
        },
      ],
    });
  };

  const onClickSubscribeOfferChoose = (offerId: string) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeOfferChoose,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.Offer,
          value: offerId,
        },
      ],
    });
  };

  const onGotoOfferPage = (payload: GotoOfferPagePayload) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoOfferPage,
      page: payload.page,
      values: [
        {
          label: AnalyticPayloadLabelName.From,
          value: payload.from,
        },
      ],
    });
  };

  return {
    onShowSubscribeOfferPage,
    onClickSubscribeOfferChoose,
    onGotoOfferPage,
  };
}
